import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
    displayScanNavigation,
    setGeneralDataLoaded,
} from '../../features/studio/Home/homeActions'
import { sandboxScopes } from '../../features/studio/Home/HomeComponents/sandboxModal/sandboxModalConstants'
import { setSelectedRoute } from '../../features/common/PanelScafolding/scafoldingActions'
import { sessionSelectors } from '../../features/common/session'
import { deleteSession } from '../../features/common/session/sessionActions'
import { getLicenseType } from '../../features/common/session/sessionSelectors'
import { LinkModel } from '../../interfaces/interfaces'
import { userHasScopes } from '../../utils/permissionsUtil'
import LightButton from '../atoms/buttons/LightButton/LightButton'
import LightPanel from '../atoms/panels/LightPanel/LightPanel'
import ExpandableMenu from '../molecules/expandableMenu/ExpandableMenu'
import CardListSkeleton from '../molecules/skeletons/cardListSkeleton/CardListSkeleton'
import styles from './rightNav.module.scss'
import { licensesSelectors } from '../../features/common/licenses'
import {
    TooltipArrowPosition,
    TooltipPosition,
    noPaymentMethod,
    onPremise,
} from '../../data/globalVar'
import { brandColors } from '../../assets/globalConstants'
import { getOrg } from '../../features/common/organization/organizationSelectors'
import GaHoverTooltip from '../molecules/tooltips/GaHoverTooltip/GaHoverTooltip'
import SwitchButton from '../atoms/buttons/SwitchButton/SwicthButton'
import {
    scafoldingActions,
    scafoldingSelectors,
} from '../../features/common/PanelScafolding'
import {
    incompleteSwitchSectionButtonOptions,
    switchSectionButtonOptions,
    trialingSwitchSectionButtonOptions,
} from '../../features/common/PanelScafolding/private/panelScafoldingPrivateConstants'
import { getPreselectedLicense } from '../../features/common/licenses/licensesSelectors'
import { getLimitsValues } from '../../utils/licensesUtil'

type IRightNavProps = {
    open: Boolean
    menuOptions: LinkModel[]
    menuExtraMobileOptions?: LinkModel[]
    expanded?: string[]
    selected: string
    close: () => void
    setExpanded: (x) => void
}

const RightNav: React.FC<IRightNavProps> = (props) => {
    const {
        open,
        menuOptions,
        menuExtraMobileOptions,
        expanded,
        selected,
        close,
        setExpanded,
    } = props

    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const allowedScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const uRole = useSelector(sessionSelectors.getUserRole_s)
    const userLicense = useSelector(sessionSelectors.getLicenses)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const hasSandboxPermissions =
        availableScopes && userHasScopes(sandboxScopes, availableScopes)
    const subscriptionInfo = useSelector(licensesSelectors.getSubscriptionInfo)
    const organizationId = useSelector(getOrg)?.id
    const orgId = useSelector(sessionSelectors.getUserOrganization)
    const isSettings = useSelector(scafoldingSelectors.showSettings)
    const isWalletActive = useSelector(scafoldingSelectors.isWalletActive)
    const tokenOrg = useSelector(sessionSelectors.getUserOrganization)
    const preselectLicense = useSelector(getPreselectedLicense)
    const subscriptionInfoIsLoading = useSelector(
        licensesSelectors.getSubscriptionInfoIsLoading
    )
    const currentLicense = useSelector(licensesSelectors.getCurrentProduct)
    const licenseCustomLimits = useSelector(
        sessionSelectors.getLicenseCustomLimits
    )
    const showSwitchButton = !isSettings && !onPremise // TODO: manage permissions by scope

    const [switchSectionValue, setSectionChecked] = useState(
        !isWalletActive ? 'home' : 'wallet'
    )
    const [showSandboxPanel, setShowSandboxPanel] = useState(true)

    const userPaymnetByStripe =
        subscriptionInfo?.currentTierId &&
        !subscriptionInfo?.paymentOutsideStripe
    const currentLicenseType: string = useSelector(getLicenseType)
    const thereIsDue = subscriptionInfo?.subscriptionStatus === 'past_due'
    const isTrialing =
        !subscriptionInfoIsLoading &&
        !!subscriptionInfo?.subscriptionStatus &&
        (subscriptionInfo?.subscriptionStatus === 'trial' ||
            subscriptionInfo?.subscriptionStatus === 'trialing')
    const trialLicenseIncomplete =
        (!subscriptionInfoIsLoading ||
            !!subscriptionInfo?.subscriptionStatus) &&
        !tokenOrg?.length &&
        preselectLicense === 'Starter'
    const canceledLicense =
        subscriptionInfo?.subscriptionStatus === 'unpaid' ||
        subscriptionInfo?.subscriptionStatus === 'canceled'
    const canAccessTeam =
        (currentLicenseType !== 'Starter' &&
            currentLicenseType !== 'Essential') ||
        onPremise
    const userIsOrgOwner = uRole === 'OrganizationOwner'
    const isOnPremiseOrWallet = onPremise || isWalletActive
    const canAccessSandbox = !isOnPremiseOrWallet && hasSandboxPermissions
    const licenseIsIncomplete =
        subscriptionInfo?.subscriptionStatus === 'incomplete'

    const unableToSeeEWCreds = !getLimitsValues(
        currentLicense,
        licenseCustomLimits
    )?.ewCredentials

    const unableToSeeEWConnections = !getLimitsValues(
        currentLicense,
        licenseCustomLimits
    )?.ewConnections

    const unableToSeeEWValidatedUsers = !getLimitsValues(
        currentLicense,
        licenseCustomLimits
    )?.ewUsers

    const unableToNavigateToEW =
        unableToSeeEWCreds ||
        unableToSeeEWConnections ||
        unableToSeeEWValidatedUsers

    useEffect(() => {}, [allowedScopes])

    useEffect(() => {
        setSectionChecked(!isWalletActive ? 'home' : 'wallet')
    }, [isWalletActive])

    useEffect(() => {
        dispatch(
            scafoldingActions.setWalletActive(switchSectionValue === 'wallet')
        )
    }, [switchSectionValue])

    function navigate(e, url: string) {
        setActiveRoute(url), e.stopPropagation()
        history.push(url)
    }

    const setActiveRoute = (route: string) => {
        dispatch(setGeneralDataLoaded(false))
        dispatch(setSelectedRoute(route))
    }

    const unableToSeeVouchClients = !getLimitsValues(
        currentLicense,
        licenseCustomLimits
    )?.vouchClientsIds
    const unableToSeeVouchUsers = !getLimitsValues(
        currentLicense,
        licenseCustomLimits
    )?.vouchValidatedUsers

    const unableToNavigateToVouch =
        unableToSeeVouchClients || unableToSeeVouchUsers

    // allowedScopes
    const getIsActive = (option: LinkModel) => {
        let isActive = false
        const routeIsTeamWithAccess =
            canAccessTeam && !thereIsDue && !licenseIsIncomplete
        const routeIsBillingWithAccess =
            !noPaymentMethod &&
            userIsOrgOwner &&
            userPaymnetByStripe &&
            !!allowedScopes?.includes('manageSubscriptions')
        const routeIsAppIntWithAccess =
            !unableToNavigateToVouch || subscriptionInfoIsLoading

        if (
            !canceledLicense &&
            (option?.route !== 'team' || routeIsTeamWithAccess) &&
            (option?.route !== 'billing' || routeIsBillingWithAccess) &&
            !(option?.route === 'settings' && thereIsDue) &&
            (option?.route !== 'app-integrations' || routeIsAppIntWithAccess) &&
            (option?.route !== 'subscription' ||
                ((!!organizationId || !!orgId) && !licenseIsIncomplete))
        ) {
            if (option.scopes?.length) {
                if (allowedScopes?.length) {
                    isActive =
                        (userHasScopes(option.scopes, allowedScopes) &&
                            !thereIsDue) ||
                        option?.route === 'subscription' ||
                        (option?.route === 'billing' &&
                            !!routeIsBillingWithAccess)
                }
            } else {
                isActive = true
            }
        }
        return isActive
    }

    const getHoverTooltipInfo = (route) => {
        return route === 'team' && !canAccessTeam
            ? t('public.upgradeSubscriptionToAccess')
            : route === 'app-integrations' && unableToNavigateToVouch
            ? t('public.upgradeSubscriptionToAccessFromTrial')
            : route === t('billing')
            ? t('public.billingSupportNeeded')
            : t('public.notNecessaryPermission')
    }

    const userNeedToUpgrade = (route) => {
        return (
            (route === 'team' && !canAccessTeam) ||
            (route === 'app-integrations' &&
                unableToNavigateToVouch &&
                !licenseIsIncomplete &&
                !!tokenOrg?.length)
        )
    }

    const displayScanModal = () => dispatch(displayScanNavigation())

    const displayScanModalAndClose = () => {
        close()
        displayScanModal()
    }

    return (
        <>
            <div
                className={
                    (open ? styles.navOpen : styles.navClosed) +
                    ' ' +
                    styles.sideNav
                }
            >
                {showSwitchButton ? (
                    <SwitchButton
                        checkedValue={switchSectionValue || ''}
                        options={
                            !tokenOrg?.length ||
                            licenseIsIncomplete ||
                            trialLicenseIncomplete
                                ? incompleteSwitchSectionButtonOptions
                                : unableToNavigateToEW &&
                                  !subscriptionInfoIsLoading
                                ? trialingSwitchSectionButtonOptions
                                : switchSectionButtonOptions
                        }
                        onChangeSwitchSelect={setSectionChecked}
                        className={styles.switchButton}
                    />
                ) : null}
                <ul
                    id={'rightNavList'}
                    className={cx(
                        styles.sideNav__list,
                        showSwitchButton && styles.shortSideNav,
                        canAccessSandbox && styles.showSandbox
                    )}
                >
                    {menuOptions.map((e, index) => {
                        return allowedScopes ? (
                            e.subRoutes ? (
                                <li
                                    key={'subRoute' + e?.route + index}
                                    className={`${cx(
                                        !getIsActive(e)
                                            ? styles.sideNav__link__disabled
                                            : null
                                    )} ${styles.sideNav__link} ${
                                        e.subRoutes ? 'column' : 'row'
                                    }`}
                                >
                                    <ExpandableMenu
                                        item={e}
                                        expanded={expanded}
                                        selected={selected}
                                        setExpanded={setExpanded}
                                        setSelected={setActiveRoute}
                                    />
                                </li>
                            ) : (
                                <li
                                    key={'menuOptions' + index}
                                    onClick={(event) => {
                                        getIsActive(e)
                                            ? e.id && e.id === 'logout'
                                                ? dispatch(deleteSession())
                                                : !e.route?.includes('no') &&
                                                  navigate(event, e.route)
                                            : null
                                    }}
                                    className={`${cx(
                                        !getIsActive(e)
                                            ? styles.sideNav__link__disabled
                                            : null
                                    )} ${styles.sideNav__link} ${
                                        e.subRoutes ? 'column' : 'row'
                                    }`}
                                >
                                    <div
                                        className={`${
                                            selected === e.route ||
                                            (selected === 'profile' &&
                                                e.route === 'settings')
                                                ? styles.selected
                                                : ''
                                        }`}
                                    >
                                        <div>
                                            {!getIsActive(e) ? (
                                                <>
                                                    <GaHoverTooltip
                                                        position={
                                                            TooltipPosition.bottom
                                                        }
                                                        positionArrowDirection={
                                                            TooltipArrowPosition.left
                                                        }
                                                        warning={
                                                            !userNeedToUpgrade(
                                                                e.route
                                                            )
                                                        }
                                                        upgradeNeeded={
                                                            !!userNeedToUpgrade(
                                                                e.route
                                                            )
                                                        }
                                                        label={
                                                            <>
                                                                {e.iconComponent ? (
                                                                    <e.iconComponent size="16" />
                                                                ) : null}
                                                                <span
                                                                    className={`${cx(
                                                                        'buttonSM'
                                                                    )}`}
                                                                >
                                                                    {t(e.label)}
                                                                </span>
                                                            </>
                                                        }
                                                        info={getHoverTooltipInfo(
                                                            e.route
                                                        )}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    {e.iconComponent ? (
                                                        <e.iconComponent
                                                            size="16"
                                                            style={
                                                                selected ===
                                                                    e.route &&
                                                                'filled'
                                                            }
                                                            color={
                                                                selected ===
                                                                e.route
                                                                    ? brandColors?.primaryColor
                                                                    : '#1E1E20'
                                                            }
                                                        />
                                                    ) : null}
                                                    <span
                                                        className={`${cx(
                                                            'buttonSM'
                                                        )}`}
                                                    >
                                                        {t(e.label)}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            )
                        ) : (
                            <CardListSkeleton
                                key={'menuOptions__nav__skeleton' + index}
                                className={styles.optionsSkeleton}
                                cardsNumber={2}
                            />
                        )
                    })}
                </ul>

                <ul
                    className={
                        styles.extraMobileOptions + ' ' + styles.sideNav__list
                    }
                >
                    {menuExtraMobileOptions
                        ? menuExtraMobileOptions.map((e, index) => {
                              return allowedScopes ? (
                                  e.subRoutes ? (
                                      <li
                                          key={
                                              'extraMenu__nav__subRoute' +
                                              e?.route +
                                              index
                                          }
                                          className={`${cx(
                                              !getIsActive(e)
                                                  ? styles.sideNav__link__disabled
                                                  : null
                                          )} ${styles.sideNav__link} ${
                                              e.subRoutes ? 'column' : 'row'
                                          }`}
                                      >
                                          <ExpandableMenu
                                              item={e}
                                              expanded={expanded}
                                              selected={selected}
                                              setExpanded={setExpanded}
                                              setShowSandboxPanel={
                                                  setShowSandboxPanel
                                              }
                                              setSelected={setActiveRoute}
                                          />
                                      </li>
                                  ) : (
                                      <li
                                          key={'extraMenu__nav__option' + index}
                                          onClick={(event) => {
                                              getIsActive(e)
                                                  ? e.id && e.id === 'logout'
                                                      ? dispatch(
                                                            deleteSession()
                                                        )
                                                      : !e.route?.includes(
                                                            'no'
                                                        ) &&
                                                        navigate(event, e.route)
                                                  : null
                                          }}
                                          className={`${cx(
                                              !getIsActive(e)
                                                  ? styles.sideNav__link__disabled
                                                  : null
                                          )} ${styles.sideNav__link} ${
                                              e.subRoutes ? 'column' : 'row'
                                          }`}
                                      >
                                          <div
                                              className={`${
                                                  selected === e.route ||
                                                  (selected === 'profile' &&
                                                      e.route === 'settings')
                                                      ? styles.selected
                                                      : ''
                                              }`}
                                          >
                                              <div>
                                                  {!getIsActive(e) ? (
                                                      <>
                                                          <GaHoverTooltip
                                                              position={
                                                                  TooltipPosition.bottom
                                                              }
                                                              positionArrowDirection={
                                                                  TooltipArrowPosition.left
                                                              }
                                                              warning={
                                                                  !userNeedToUpgrade(
                                                                      e.route
                                                                  )
                                                              }
                                                              upgradeNeeded={
                                                                  !!userNeedToUpgrade(
                                                                      e.route
                                                                  )
                                                              }
                                                              label={
                                                                  <>
                                                                      {e.iconComponent ? (
                                                                          <e.iconComponent
                                                                              size="16"
                                                                              style={
                                                                                  selected ===
                                                                                      e.route &&
                                                                                  'filled'
                                                                              }
                                                                          />
                                                                      ) : null}
                                                                      <span
                                                                          className={`${cx(
                                                                              'buttonSM'
                                                                          )}`}
                                                                      >
                                                                          {t(
                                                                              e.label
                                                                          )}
                                                                      </span>
                                                                  </>
                                                              }
                                                              info={getHoverTooltipInfo(
                                                                  e.route
                                                              )}
                                                          />
                                                      </>
                                                  ) : (
                                                      <>
                                                          {e.iconComponent ? (
                                                              <e.iconComponent
                                                                  size="16"
                                                                  style={
                                                                      selected ===
                                                                          e.route &&
                                                                      'filled'
                                                                  }
                                                              />
                                                          ) : null}
                                                          <span
                                                              className={`${cx(
                                                                  'buttonSM'
                                                              )}`}
                                                          >
                                                              {t(e.label)}
                                                          </span>
                                                      </>
                                                  )}
                                              </div>
                                          </div>
                                      </li>
                                  )
                              ) : (
                                  <CardListSkeleton
                                      key={'extraMenu__nav__skeleton' + index}
                                      className={styles.optionsSkeleton}
                                      cardsNumber={2}
                                  />
                              )
                          })
                        : null}
                </ul>

                {canAccessSandbox ? (
                    <LightPanel className={styles.sandboxPanel}>
                        <p className={cx(styles.sandboxPanel__title)}>
                            {t('navigation.sandboxPanel.title')}
                        </p>

                        <p className={styles.sandboxPanel__description}>
                            {t('navigation.sandboxPanel.description')}
                        </p>

                        <LightButton
                            className={styles.sandboxPanel__button}
                            text={'navigation.sandboxPanel.button'}
                            disabled={false}
                            functionality={displayScanModal}
                        />
                    </LightPanel>
                ) : null}

                {canAccessSandbox && showSandboxPanel ? (
                    <div onClick={() => displayScanModalAndClose()}>
                        <LightPanel className={styles.sandboxPanelSmart}>
                            <p className={styles.sandboxPanelSmart__title}>
                                {t('navigation.sandboxPanel.title')}
                            </p>
                        </LightPanel>
                    </div>
                ) : null}
            </div>
        </>
    )
}

export default RightNav
