import {
    ILicenseCustomLimits,
    ILicenseItem,
    ILimitsModel,
    IPresentationRequestModel,
    LicenseUsage,
    OrganizationModel,
} from '../../../interfaces/interfaces'

export interface ISetSession {
    type: SessionActionTypes.SET_SESSION
    signUp?: boolean
}

export interface IReadSession {
    type: SessionActionTypes.READ_SESSION
}
export interface IReadSessionSuccess {
    type: SessionActionTypes.READ_SESSION_SUCCESS
    tokenExpiration: number
    currentTenant: string
    scopesDetected: string[]
    licenses?: any[]
    dids?: string[]
    apiKeys?: string[]
    tenant?: string
    ssiOperations?: string[]
    allSsiOperationsAllowed?: boolean
    allDidsAllowed?: boolean
    allApiKeysAllowed?: boolean
    allTenantsAllowed?: boolean
    email?: string
    providerOrg?: string
    orgId?: string
    role?: string
}

export interface IReadSessionFailed {
    type: SessionActionTypes.READ_SESSION_FAILED
    error: string
}

export interface IGetUser {
    type: SessionActionTypes.GET_USER
    email: string
}

export interface ISetUser {
    type: SessionActionTypes.SET_USER
    user: string
}

export interface IGetLicenses {
    type: SessionActionTypes.GET_LICENSES
}

export interface ILoadSessionData {
    type: SessionActionTypes.LOAD_DATA
}

export interface IGetLicensesSuccess {
    type: SessionActionTypes.GET_LICENSES_SUCCESS
    payload: {
        licenses: ILicenseItem[]
    }
}

export interface IGetLicensesFailed {
    type: SessionActionTypes.GET_LICENSES_FAILED
    error: string
}

export interface ISetUserOrg {
    type: SessionActionTypes.SET_USER_ORGANIZATION
    payload: {
        org: OrganizationModel
    }
}

export interface IDeleteSession {
    type: SessionActionTypes.DELETE_SESSION
}

export interface ISetSessionApiKeys {
    type: SessionActionTypes.SET_SESSION_APIKEYS
    payload: {
        api_keys: string[]
    }
}

export interface ISetSessionDids {
    type: SessionActionTypes.SET_SESSION_DIDS
    payload: {
        dids: Record<string, string>
    }
}

export interface ISetSessionSSIOperations {
    type: SessionActionTypes.SET_SESSION_SSI_OPERATIONS
    payload: {
        ssiOperations: IPresentationRequestModel[]
        type: string
    }
}

export interface IShowTenantModal {
    type: SessionActionTypes.SHOW_TENANT_MODAL
}

export interface IHideTenantModal {
    type: SessionActionTypes.HIDE_TENANT_MODAL
}

export type SessionAction =
    | ISetSession
    | IDeleteSession
    | IGetUser
    | ISetUser
    | IGetLicenses
    | IGetLicensesSuccess
    | IGetLicensesFailed
    | ISetUserOrg
    | ILoadSessionData
    | ISetSessionApiKeys
    | ISetSessionDids
    | ISetSessionSSIOperations
    | IReadSession
    | IReadSessionSuccess
    | IReadSessionFailed
    | IShowTenantModal
    | IHideTenantModal

export interface sessionTokenData {
    tokenExpiration: number
    currentTenant: string
    organizationId?: string
    userSsiOperations?: string[]
    allowedScopes?: string[]
    apiKeys?: string[]
    providerOrg?: string
    userDids?: string[]
}

export interface SessionState {
    tokenData?: sessionTokenData
    loading: boolean
    sandboxId?: string
    user: string
    tenants: string[]
    licenses?: ILicenseItem[]
    isOnPremise?: boolean
    ssiOperations?: Record<string, IPresentationRequestModel[]>
    dids?: Record<string, string>
    error: string
    tenant?: string
    signUp: boolean
    role?: string
    image?: string
    allTenantsAllowed?: boolean
    allSsiOperationsAllowed?: boolean
    allDidsAllowed?: boolean
    allApiKeysAllowed?: boolean
    usage?: LicenseUsage
    customLimits?: ILicenseCustomLimits
    licensesLoading: boolean
    showTenantModal: boolean
    sessionRead?: boolean
}

export enum SessionActionTypes {
    DELETE_SESSION = 'session/delete',
    SET_SESSION = 'session/set',
    READ_SESSION = 'session/read',
    READ_SESSION_SUCCESS = 'session/read_success',
    READ_SESSION_FAILED = 'session/read_failed',
    GET_USER = 'user/get',
    SET_USER = 'user/set',
    LOAD_DATA = 'session/load_data',
    GET_LICENSES = 'session/get_licenses',
    GET_LICENSES_SUCCESS = 'session/get_licenses_success',
    GET_LICENSES_FAILED = 'session/get_licenses_failed',
    SET_USER_ORGANIZATION = 'session/set_user_organization',
    SET_SESSION_APIKEYS = 'session/api_keys',
    SET_SESSION_DIDS = 'session/dids',
    SET_SESSION_SSI_OPERATIONS = 'session/ssi_operations',
    SHOW_TENANT_MODAL = 'session/show_tenant_modal',
    HIDE_TENANT_MODAL = 'session/hide_tenant_modal',
}
