import React from 'react'
import cx from 'classnames'
import SubcategoryCell from '../elements/cells/subcategoryCell/SubcategoryCell'
import IconDataCell from '../elements/cells/iconDataCell/IconDataCell'
import CategoryCell from '../elements/cells/category/CategoryCell'
import styles from './gatacaLicensesTable.module.scss'
import { brandLabels } from '../../../../../../../../assets/globalConstants'
import { InfoTogglesPricingModel } from '../../../../../../../../interfaces/interfaces'
import Tag from '../../../../../../../../Components/atoms/tag/Tag'
import { t } from 'i18next'
import { getPrice } from '../../../../../../../../utils/licensesUtil'

type IGatacaLicensesTableProps = {
    tiers?: any[]
    switchPeriodValue: string
    infoToggles: InfoTogglesPricingModel
    setPeriodChecked: (x) => void
}

const GatacaLicensesTable: React.FC<IGatacaLicensesTableProps> = (props) => {
    const { tiers, switchPeriodValue, infoToggles, setPeriodChecked } = props

    return (
        <>
            <div className={styles?.tableContainer}>
                <table className={styles.licensesTable}>
                    <thead className={styles?.table__header__container}>
                        <tr
                            className={styles?.table__header}
                            id={'featuresTableDesk'}
                        >
                            <th colSpan={2}>
                                <div className={styles.periodToggle}>
                                    <input
                                        checked={switchPeriodValue === 'year'}
                                        className={styles.periodCheckbox}
                                        type="checkbox"
                                        id="switch"
                                    />
                                    <label
                                        onClick={(e) =>
                                            setPeriodChecked(
                                                switchPeriodValue === 'month'
                                                    ? 'year'
                                                    : 'month'
                                            )
                                        }
                                        className={styles.periodCheckboxLabel}
                                    ></label>
                                    <span className={cx('buttonMD primary700')}>
                                        {t('subscription.payYearly')}
                                    </span>
                                </div>
                            </th>

                            {tiers?.map((license) => {
                                return (
                                    <th
                                        key={'headerT__' + license?.type}
                                        className={styles?.table__header__cell}
                                    >
                                        <div
                                            className={
                                                styles?.table__header__cell__content
                                            }
                                        >
                                            <div
                                                className={
                                                    styles?.license__header
                                                }
                                            >
                                                <p
                                                    className={`${
                                                        styles?.licenseName
                                                    } ${cx('bodyBoldMD')}`}
                                                >
                                                    {license?.type}
                                                </p>
                                                {license?.type ===
                                                    'Professional' && (
                                                    <Tag
                                                        text={'Popular'}
                                                        color={'#00999E'}
                                                        className={
                                                            styles?.license__header__popularTag
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <div>
                                                {!license?.name
                                                    ?.toLowerCase()
                                                    ?.includes('enterprise') ? (
                                                    <>
                                                        <p
                                                            className={`${
                                                                styles?.licensePrice
                                                            } ${
                                                                switchPeriodValue !==
                                                                'year'
                                                                    ? styles?.licensePriceLarge
                                                                    : ''
                                                            } ${cx(
                                                                'heading4'
                                                            )}`}
                                                        >
                                                            <span>
                                                                {getPrice(
                                                                    license?.prices,
                                                                    switchPeriodValue
                                                                )}
                                                                €
                                                            </span>
                                                            {' /'}
                                                            &nbsp;
                                                            {t(
                                                                'subscription.' +
                                                                    switchPeriodValue
                                                            )}
                                                        </p>
                                                        {switchPeriodValue ===
                                                        'month'
                                                            ? license?.subPriceMonthLabel && (
                                                                  <span
                                                                      className={`${cx(
                                                                          'neutral700 bodyRegularXS marginTop2'
                                                                      )} ${
                                                                          styles?.licenseSave
                                                                      }`}
                                                                  >
                                                                      {t(
                                                                          license?.subPriceMonthLabel
                                                                      )}
                                                                  </span>
                                                              )
                                                            : license?.subPriceYearLabel && (
                                                                  <span
                                                                      className={`${cx(
                                                                          'neutral700 bodyRegularXS marginTop2'
                                                                      )} ${
                                                                          styles?.licenseSave
                                                                      }`}
                                                                  >
                                                                      {t(
                                                                          license?.subPriceYearLabel
                                                                      )}
                                                                  </span>
                                                              )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <p
                                                            className={`${
                                                                styles?.licenseCustomPrice
                                                            } ${cx(
                                                                'heading5'
                                                            )}`}
                                                        >
                                                            {t(
                                                                'subscription.customPricing'
                                                            )}
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody className={styles?.table__body}>
                        <tr key={1} className={styles?.table__body__row}>
                            <CategoryCell
                                category={'subscription.verificableCreds'}
                                rowsPan={9}
                            />
                            <SubcategoryCell
                                subcategory={
                                    'subscription.verificationTemplates'
                                }
                                information={t('subscription.verTemplatesInfo')}
                            />

                            {tiers?.map((license) => {
                                return (
                                    <>
                                        <td
                                            key={'PSPT__' + license?.type}
                                            className={`${cx('bodyBoldSM')}`}
                                        >
                                            {
                                                license?.features
                                                    ?.verificationTemplates
                                            }
                                        </td>
                                    </>
                                )
                            })}
                        </tr>
                        <tr key={2} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.issuanceTemplates'}
                                information={t(
                                    'subscription.issuanceTemplatesInfo'
                                )}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <>
                                        <td
                                            key={'PSPT__' + license?.type}
                                            className={`${cx('bodyBoldSM')}`}
                                        >
                                            {
                                                license?.features
                                                    ?.issuanceTemplates
                                            }
                                        </td>
                                    </>
                                )
                            })}
                        </tr>
                        <tr key={3} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.activeUsers'}
                                information={t('subscription.activeUsersInfo')}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <>
                                        <td
                                            key={'PSPT__' + license?.type}
                                            className={`${cx(
                                                'bodyBoldSM',
                                                styles.paragraphCell
                                            )}`}
                                        >
                                            {license?.features?.activeUsers}
                                        </td>
                                    </>
                                )
                            })}
                        </tr>

                        <tr key={4} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.issuedCredentials'}
                                information={t('subscription.issuedCredsInfo')}
                                extraTranslationParams={{
                                    brand: brandLabels?.brand,
                                }}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <>
                                        <td
                                            key={'PSPT__' + license?.type}
                                            className={`${cx(
                                                'bodyBoldSM',
                                                styles.paragraphCell
                                            )}`}
                                        >
                                            {
                                                license?.features
                                                    ?.issuedCredentials
                                            }
                                        </td>
                                    </>
                                )
                            })}
                        </tr>
                        <tr key={5} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'ew.connections.dataAgreements'}
                                information={'subscription.dataAgreementsInfo'}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={license?.features?.dataAgreements}
                                    />
                                )
                            })}
                        </tr>
                        <tr key={6} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.verifiableIds'}
                                information={t(
                                    'subscription.verifiableIdsInfo'
                                )}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={license?.features?.verifiableIds}
                                    />
                                )
                            })}
                        </tr>
                        <tr key={7} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.customSchemas'}
                                information={t(
                                    'subscription.customSchemasInfo'
                                )}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={license?.features?.customSchemas}
                                    />
                                )
                            })}
                        </tr>
                        <tr key={8} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.sandboxTest'}
                                information={t('subscription.sandboxInfo')}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={license?.features?.sandboxTesting}
                                    />
                                )
                            })}
                        </tr>
                        <tr key={9} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.extraCreds'}
                                information={t('subscription.extraCredsInfo')}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={
                                            license?.features?.extraCredentials
                                        }
                                    />
                                )
                            })}
                        </tr>

                        <tr key={10} className={styles?.table__body__row}>
                            <CategoryCell
                                category={'subscription.singleSignOn'}
                                rowsPan={2}
                            />
                            <SubcategoryCell
                                subcategory={'subscription.ageVerification'}
                                information={t('subscription.ageVerInfo')}
                            />

                            {tiers?.map((license, index) => {
                                return index === 0 ? (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={
                                            license?.features?.ageVerification
                                        }
                                    />
                                ) : (
                                    <td
                                        key={'PSPT__' + license?.type}
                                        className={`${cx(
                                            'bodyBoldSM',
                                            styles.paragraphCell
                                        )}`}
                                    >
                                        {license?.features?.ageVerification}
                                    </td>
                                )
                            })}
                        </tr>
                        <tr key={11} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.appInt'}
                            />
                            {tiers?.map((license, index) => {
                                return index === 0 ? (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={
                                            license?.features?.appIntegrations
                                        }
                                    />
                                ) : (
                                    <td
                                        key={'PSPT__' + license?.type}
                                        className={`${cx(
                                            'bodyBoldSM',
                                            styles.paragraphCell
                                        )}`}
                                    >
                                        {license?.features?.appIntegrations}
                                    </td>
                                )
                            })}
                        </tr>
                        <tr key={12} className={styles?.table__body__row}>
                            <CategoryCell
                                category={'subscription.enterprise'}
                                rowsPan={4}
                            />
                            <SubcategoryCell
                                subcategory={'subscription.didMethods'}
                            />
                            {tiers?.map((license, index) => {
                                return (
                                    <td
                                        key={'PSPT__' + license?.type}
                                        className={`${cx('bodyBoldSM')}`}
                                    >
                                        {license?.features?.didMethods}
                                    </td>
                                )
                            })}
                        </tr>
                        <tr key={13} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.trustRegistries'}
                                information={t(infoToggles?.ebsiIntegration)}
                            />
                            {tiers?.map((license, index) => {
                                return (
                                    <td
                                        key={'PSPT__' + license?.type}
                                        className={`${cx('bodyBoldSM')}`}
                                    >
                                        {license?.features?.trustRegistries}
                                    </td>
                                )
                            })}
                        </tr>
                        <tr key={14} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.qualifiedCerts'}
                                information={t(
                                    'subscription.qualifiedCertsInfo'
                                )}
                            />

                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={
                                            license?.features
                                                ?.qualifiedCertificates
                                        }
                                    />
                                )
                            })}
                        </tr>
                        <tr key={15} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.entWallet'}
                                information={t('subscription.entWalletInfo')}
                            />

                            {tiers?.map((license, index) => {
                                return index === 0 ? (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={
                                            license?.features?.enterpriseWallet
                                        }
                                    />
                                ) : (
                                    <td
                                        key={'PSPT__' + license?.type}
                                        className={`${cx(
                                            'bodyBoldSM',
                                            styles.paragraphCell
                                        )}`}
                                    >
                                        {license?.features?.enterpriseWallet}
                                    </td>
                                )
                            })}
                        </tr>
                        <tr key={16} className={styles?.table__body__row}>
                            <CategoryCell
                                category={'subscription.administration'}
                                rowsPan={3}
                            />
                            <SubcategoryCell
                                subcategory={'subscription.stats'}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={license?.features?.statistics}
                                    />
                                )
                            })}
                        </tr>
                        <tr key={17} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.roleBasedAccess'}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={license?.features?.roleAccess}
                                    />
                                )
                            })}
                        </tr>
                        <tr key={18} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.customRoles'}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={license?.features?.customRoles}
                                    />
                                )
                            })}
                        </tr>
                        <tr key={19} className={styles?.table__body__row}>
                            <CategoryCell
                                category={'subscription.supportServices'}
                                rowsPan={6}
                            />
                            <SubcategoryCell
                                subcategory={'subscription.ticketingSystem'}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={license?.features?.emailSupport}
                                    />
                                )
                            })}
                        </tr>
                        <tr key={20} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.slackSupport'}
                            />

                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={license?.features?.slackSupport}
                                    />
                                )
                            })}
                        </tr>
                        <tr key={21} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.accountTeam'}
                            />

                            {tiers?.map((license, index) => {
                                return index === 0 ? (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={license?.features?.accountTeam}
                                    />
                                ) : (
                                    <td
                                        key={'PSPT__' + license?.type}
                                        className={`${cx(
                                            'bodyBoldSM',
                                            styles.paragraphCell
                                        )}`}
                                    >
                                        {license?.features?.accountTeam}
                                    </td>
                                )
                            })}
                        </tr>
                        <tr key={22} className={styles?.table__body__row}>
                            <SubcategoryCell subcategory={'24x7'} />
                            {tiers?.map((license, index) => {
                                return index === 0 ? (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={license?.features?.available24x7}
                                    />
                                ) : (
                                    <td
                                        key={'PSPT__' + license?.type}
                                        className={`${cx(
                                            'bodyBoldSM',
                                            styles.paragraphCell
                                        )}`}
                                    >
                                        {license?.features?.available24x7}
                                    </td>
                                )
                            })}
                        </tr>
                        <tr key={23} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.slas'}
                            />
                            {tiers?.map((license, index) => {
                                return index === 0 ? (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={license?.features?.slas}
                                    />
                                ) : (
                                    <td
                                        key={'PSPT__' + license?.type}
                                        className={`${cx(
                                            'bodyBoldSM',
                                            styles.paragraphCell
                                        )}`}
                                    >
                                        {license?.features?.slas}
                                    </td>
                                )
                            })}
                        </tr>
                        <tr key={24} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={
                                    'subscription.onboardingAndTraining'
                                }
                            />
                            {tiers?.map((license, index) => {
                                return index === 0 ? (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={
                                            license?.features
                                                ?.onboardAndTraining
                                        }
                                    />
                                ) : (
                                    <td
                                        key={'PSPT__' + license?.type}
                                        className={`${cx(
                                            'bodyBoldSM',
                                            styles.paragraphCell
                                        )}`}
                                    >
                                        {license?.features?.onboardAndTraining}
                                    </td>
                                )
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default GatacaLicensesTable
