import { Component, FunctionComponent, SVGProps } from 'react'
import {
    DataAgreementPurpose,
    LegalBasis,
    MethodOfUse,
    PrivateKeyTypes,
} from '../assets/globalConstants'
import { ReduxActions } from '../features/common/Login/loginTypes'
import { VerifiableCredential } from '../features/wallet/requestCredentials/data/requestCredentials.interfaces'

type supportedLanguage = 'en' | 'es' | 'ca' | 'eu' | 'gl' | 'pt'

export interface ImageModel {
    src: string
    alt: string
}
export interface LinkModel {
    id?: string
    label: string
    route: string
    outLink?: string
    action?: (...args: any[]) => ReduxActions
    icon?: ImageModel
    iconComponent?: any
    status?: string
    subRoutes?: LinkModel[]
    selected?: boolean
    scopes?: string[]
}
export interface ctaLinkModel extends LinkModel {
    image?: ImageModel
}
export interface ButtonModel {
    label: string
    image?: ImageModel
    disabledIcon?: ImageModel
    disabledTooltip?: boolean
    hideIconTooltip?: boolean
    disabledTooltipLabel?: string
    svgIcon?: FunctionComponent<SVGProps<SVGSVGElement>>
    disabled?: boolean
    function?: ((x?: any) => void) | any | Function
}

export interface INewButtonModel {
    label: string
    icon?: string
    IconComponent?: any
    outlined?: boolean
    className?: any
    url?: string
    fileName?: string
    action: (x?: any) => void
}

export interface SwitchButtonModel {
    options: OptionModel[]
    function?: Function | void
}

export interface OptionModel {
    text: string
    value: string
    disabled?: boolean
    info?: string
    Icon?: any
}
export interface StepModel {
    id: number
    label: string
    order: number
    description?: string
    route?: string
    component?: Component | FunctionComponent | any
    componentCredentialPreview?: Component | FunctionComponent | any
    disabledInfo?: string
}

export interface StepModalModel {
    id: number
    title?: string
    order: number
    description?: string
    content?: Component | FunctionComponent | any
    buttons?: Component | FunctionComponent | any
    alert?: {
        type: 'info' | 'warning'
        text: string
        icon?: Component | FunctionComponent | any
    }
    isStateStep?: 'loading' | 'result'
    progressIndicatorOptions?: number
    progressIndicatorCurrentOption?: number
}

export interface EndsStepsModel {
    previousStep: StepModel
    nextStep: StepModel
}
export interface BlockModel {
    title: string
    subtitle?: string
    description?: string
    description1?: string
    boldDetail?: string
    description2?: string
    icon?: ImageModel
    rightImage?: ImageModel
    rightText?: string
    type?: string
    route?: string
    action?: () => void
}
export interface NotificationModel {
    // TODO: complete type when it has been defined
}

export interface PendingActionModel {
    // TODO: complete type when it has been defined
}

export interface TranslationModel {
    en?: string
    es?: string
}

export interface IssuerModel {
    id: string
    name?: TranslationModel
    trustLevel?: number
}

export interface IVerifierConfigModel extends IPresentationRequestModel {
    qrVerifier?: string
    service?: string
    dataAgreementTemplate?: DataAgreementTemplate
    advancedDefinition?: any
    ignoreDataAgreement?: boolean
}

export interface DataAgreementTemplate {
    '@context': string
    template_id: string
    template_version: string
    data_receiver: {
        id: string
        name: string
        url?: string
        service?: string
        consent_duration?: number
        form_of_consent: 'explicit' | 'implicit'
    }
    purposes: DA_Purpose[]
    personal_data: DA_PersonalData[]
}

export interface DA_Purpose {
    id: string
    purpose_description: string
    purpose_category: DataAgreementPurpose
    legal_basis: LegalBasis
    method_of_use?: MethodOfUse
    data_policy?: DA_Policy
    name?: string
}

export interface PurposeTypesList {
    id: string
    value: string
    text: string
}

export interface DA_Policy {
    policy_URL: string
    jurisdictions: string[]
    industry_scope: string
    data_retention_period: number
    geographic_restriction?: string
    storage_location: string
}

export interface DA_PersonalData {
    attribute_id?: string
    attribute_name: string
    attribute_sensitive: boolean
    purposes: string[]
}
export interface VerifierConfigurationModel {
    tenantID: string
    requesterDid: string
    serviceDescription: string
}

export interface VerifierConsentModel {
    templateID: string
    consentDuration: number
    receiverName: string
    purposeDescription: string
}
export interface VerifierModel
    extends VerifierConfigurationModel,
        VerifierConsentModel {
    id: string
    qrVerifier: string
    credentials: CredentialModel[]
}
export interface BasicCredentialModel {
    id: string
    name: TranslationModel
}
export interface CredentialModel {
    id: string
    name: TranslationModel
    issuerList?: IssuerModel[]
    credentials?: BasicCredentialModel[] | null
}

export interface ICredentialRequestedModel extends CredentialModel {
    groupList?: string[]
    group?: {
        name: TranslationModel
    }
    type?: string
    purpose?: string
    mandatory?: boolean
    trustLevel?: number
    vc?: {
        credentialSubject: any
    }
}

export interface IScopesRequestedModel {
    groupId?: string
    name: string
    key: string
    value?: string
}

export interface OptionsModel {
    id: string
    name: TranslationModel | string
    icon?: string
}
export interface CredentialsByTypeModel {
    // TODO: adapt model when it is defined
    regular: ICredentialRequestedModel[]
    sbx: ICredentialRequestedModel[]
}

export interface IssuerConfigurationModel {
    issuanceID: string
    requesterDid: string
    domain: string
}

export interface IssuanceModel extends IssuerConfigurationModel {
    id: string
    appID: string
    credentials?: CredentialModel[]
}

export interface IPresentationRequestModel {
    id: string
    vmethodId?: string
    callback?: string
    sessionTTL?: number
    domain?: string
    credentials?: ICredentialRequestedModel[]
    security?: ISecurityConfig[]
}

export interface ISecurityConfig {
    type: string
    accepted: string[]
}

export interface IIssuanceConfigItemModel {
    id: string
    credentialGroup?: string
    credentialTypes?: string[]
    pollingFrequency?: number
    pollingMaxTime?: number
    feedbackMessage?: string
    feedbackHook?: string
    validationHook?: string
    eidasSignature?: boolean
    prConfig?: IPresentationRequestModel
}

export interface IPresentationRequestModel {
    id: string
    vmethodId?: string
    domain?: string
    credentials?: ICredentialRequestedModel[]
    security?: ISecurityConfigModel[]
    certificateId?: string
}

export interface ISecurityConfigModel {
    type: string
    accepted: string[]
}

export interface IUserItem {
    id?: string
    email: string
    password?: string
    status?: string
    role?: string
    tenants?: string[]
    createdAt?: string
    updatedAt?: string
    provider: string
    dids: string[]
    ssi_operations: string[]
    api_keys: string[]
    org_ID: string
}

export interface IRouterModel {
    path: string
    component: any
    exact: boolean
}

export interface IApiKeyModel {
    id: string
    alias: string
    did: string
    tenant?: string
    password?: string
    ssi_operations?: string[]
    role?: string
    status?: string
    tenants?: string[]
    createdAt?: string
    updatedAt?: string
}

export interface ISimplifiedApiKeyModel {
    id?: string
    did: string
    tenant?: string
    ssi_operations?: string[]
}

export interface ICreateDidModel {
    didMethod?: string
    alias?: string
    ebsiToken?: string
}

export interface IDIDModel {
    DidId?: string
    did: string
    ledger: string
    publicKey: string
    privateKeyData: string
    privateKeyType?: PrivateKeyTypes
    sessionTimeout: number
    tokenByCookie: boolean
    secret: string
    sandbox: boolean
    alias?: string
    default?: boolean
    updatedAt?: string
    type?: {
        text: string
        value: string
    }
    ebsiToken?: string
}

export interface IConnectLoginConfigModel {
    connectClient: string
    connectSecret: string
    connectTenant: string
    connectHost: string
}

export interface IDidsRespondeModel {
    didConfigs: IDIDModel[] | undefined
    defaultDid?: string
    connectLoginConfig: IConnectLoginConfigModel
}
export interface ILabelValue {
    label: string
    value: string
}

export interface ITenantConfigModel {
    id: string
    provider: string
    dids: string[]
    isDefault?: boolean
    createdAt: string
    updatedAt: string
}

export interface IConfigCredentialModel {
    mandatory: boolean
    purpose: string
    trustLevel: number
    type: string
}

export interface ICredentialTypeModel {
    id: string
    name: { en?: string; es?: string }
    schema: string
    status: string
    groupList: string[]
    updatedAt?: Date
    createdAt?: Date
    uri?: string
    multi?: boolean
    isPrivate?: boolean
}

export interface ICredentialsGroupModel {
    hidden: boolean
    name: Record<supportedLanguage, string>
    id: string
    mainClaim: string
    issuerList?: ICredentialIssuerModel[]
    types?: ICredentialTypeModel[]
    isPrivate?: boolean
}

export interface ICredentialIssuerModel {
    id: string
    publicRequest?: boolean
}

export interface ICredentialServiceProviderModel {
    address?: string
    advertised: boolean
    domain?: string
    id?: string
    endpoint?: string
    isPrivate?: boolean
}

export interface ICredentialAuthorityModel {
    id: string
    ledger?: string
    level?: number
    address?: string
    name: { en?: string; es?: string }
    domain?: string
    isPrivate?: boolean
}

export interface ICatalogModel {
    authority: ICredentialAuthorityModel[]
    credentialGroup: ICredentialsGroupModel[]
    credentialType: ICredentialTypeModel[]
    serviceProvider: ICredentialServiceProviderModel[]
}

export interface ICredentialsItemModel {
    id?: string
    status?: string
    credentialStatus?: {
        id: string
        type: string
    }
    credentialSubject: {
        id: string
    }
    group?: string
    type: string[]
    issuer?: string
    updatedAt?: Date
    createdAt?: Date
    ['@context']?: string | string[]
    proof?: ProofModel[]
    issued?: string
    expirationDate?: string
}

export interface ICredentialsActivityModel {
    data?: {
        id: string
        status: string
        group?: string
        presentationRequest?: PresentationRequestModel
        validator?: ValidatorModel
        createdAt: string
        updatedAt: string
        data: {
            ['@context']?: string[]
            credentialSchema: {
                id: string
                type: string
            }
            credentialStatus: {
                id: string
                type: string
            }
            credentialSubject
            id: string
            issuanceDate: string
            issued: string
            issuer?: string
            proof?: ProofModel[]
            type: string[]
            validFrom?: string
        }[]
    }
    id: string
    href?: string
    typeNames?: string[]
}

export interface IEWRequestCredentialModel {
    vc: {
        ['@context']?: string[]
        credentialSchema: {
            id: string
            type: string
        }
        credentialStatus: {
            id: string
            type: string
            statusPurpose: string
            statusListIndex: string
            statusListCredential: string
        }
        credentialSubject: {
            id: string
        }
        id: string
        issuer: string
        type: string[]
        expirationDate: string
        issuanceDate: string
        validFrom: string
        issued: string
        termsOfUse: {
            id: string
            type: string
        }
    }
}

export interface IIssuancePaginatedResponseModel {
    items: ICredentialsActivityModel[]
    links: {
        last: string
        next?: string
        prev?: string
    }
    pageSize: number
    self: string
    total: number
}

export interface IIssuanceRequestModel {
    id: string
    status: string
    group?: string
    presentationRequest?: PresentationRequestModel
    data?: ICredentialsItemModel[]
    validator?: ValidatorModel
    createdAt: string
    updatedAt: string
    href?: string
}

export interface PresentationRequestModel {
    additionalData?: string
    auth?: any
    callback?: string
    id?: string
    nonce?: string
    proof?: ProofModel[]
    requested?: any[]
}

export interface ValidatorModel {
    id?: string
    securityMechanisms?: {
        ['@context']?: string[]
        proof?: ProofModel[]
        type?: string[]
        verifiableCredential?: ICredentialsItemModel[]
    }
    verifiablePresentation?: VerifiablePresentationModel
}

export interface VerifiablePresentationModel {
    ['@context']?: string[]
    proof?: ProofModel[]
    type?: string[]
    verifiableCredential?: ICredentialsItemModel[]
}

export interface ProofModel {
    challenge?: string
    proofValue?: string
    created?: string
    creator?: string
    domain?: string
    nonce?: string
    proofPurpose?: string
    signatureValue?: string
    type?: string
    verificationMethod?: string
}

export interface IRoleModel {
    role_id: string
    scopes: string[]
    createdAt?: string
    updatedAt?: string
}

export interface LocationStateModel {
    from: {
        pathname: string
        state?: any
    }
}

export interface OrganizationModel {
    id: string
    providerId: string
    orgInfo: {
        name: string
        domainName?: string
        services?: {
            id: string
            type: string
            serviceEndpoint: string
        }[]
        display?: Object
        areaGroup?: string
        discoveryURL?: string
        identifierBag?: string
        legalPersonalIdentifier?: string
        LEI?: string
        SEED?: string
        SIC?: string
    }
    billingInfo?: {
        legalAddress: {
            street: string
            suite?: string
            number: string
            locality: string
            region: string
            country: string
            postalCode: string
        }
        LegalName: string
        vatRegistration: string
        taxReference?: string
    }
    createdAt?: string
}

export interface CreateOrgPayloadModel {
    id?: string
    providerId: string
    orgInfo: {
        name: string
        domainName?: string
        services?: {
            id: string
            type: string
            serviceEndpoint: string
        }[]
        display?: {}
        areaGroup?: string
        discoveryURL?: string
        identifierBag?: string
        legalPersonalIdentifier?: string
        LEI?: string
        SEED?: string
        SIC?: string
    }
    billingInfo?: {
        legalAddress: {
            street: string
            suite?: string
            number: string
            locality: string
            region: string
            country: string
            postalCode: string
        }
        LegalName: string
        vatRegistration: string
        taxReference?: string
    }
    priceId?: string
}

export interface PaymentMethodModel {
    id?: string
    creditCardNumber?: string
    holderName?: string
    ccvCheck?: string
    expiration?: string
    email?: string
}

export interface ILicenseItem {
    orgId: string
    sbxId: string
    licenseType: string
    dids: string[]
    tenants: string[]
    createdAt: Date
    updatedAt: Date
    providerId?: string
    usage?: LicenseUsage
    customLimits?: ILicenseCustomLimits
}

export interface ILicenseCustomLimits {
    ValidFrom?: string
    ValidUntil?: string
    Limits: ILimitsModel[]
}

export interface IProductFeaturesModel {
    activeUsersInfo?: string
    issuedCredentialsInfo?: string
    apiKeysInfo?: string
    verificationTemplates?: number | string
    activeUsers?: number | string
    issuanceTemplates?: number | string
    issuedCredentials?: number | string
    dids: string
    apiKeys: string
    notifications: string[]
    stadistics: boolean
    nFactor: string[]
    didPublicCatalogue: boolean
    newSchemaPublicCatalogue: string
    customQR: string
    support: string
    slas: boolean
    credentialsInW3CFormat: boolean
    sandBoxEnvironment: boolean
    ebsiIntegration: boolean
    customGDPRPurposes: boolean
    credentialsInISO28013: boolean
    integrationCustomTrustFrameworks: boolean
    integrationCustomTrustInfraestructure: boolean
    standardSupport: boolean | string
    premierSupport: boolean | string
}

export interface InfoTogglesPricingModel {
    verificationTemplate: string
    activeUser: string
    issuanceTemplate: string
    issuedCredential: string
    securityFactorMechanism: string
    sandboxEnvironment: string
    didInPublicCatalogue: string
    schemaInPublicCatalogue: string
    ebsiIntegration: string
    integrationCustomFrameworks: string
    integrationCustomInfraestructure: string
}

export interface IProductForTableModel {
    id: string
    name: string
    description: string
    prices: IPriceModel[]
    type?: string
    features?: IProductFeaturesModel
    subPriceMonthLabel?: string
    subPriceYearLabel?: string

    button: {
        label: 'Contact us'
        outsideWeb: false
        url: '/company/contact'
    }
}

export interface IDataAgreementItem extends DataAgreementTemplate {
    id: string
    version: string
    data_subject: string
    data_holder: string
    termination_timestamp?: number
    event: DataAgreementEvent[]
}

export declare type DataAgreementEvent = {
    timestamp: number
    principle_did: string
    state: DataAgreementState
}

export type DataAgreementState =
    | 'Definition'
    | 'Preparation'
    | 'Capture'
    | 'Modification'
    | 'Revocation'

export type ISandboxInvitation = {
    sandboxId: string
    invitation: string
}

export type ISubscriptionInfo = {
    currentPeriodEnd: string
    currentPeriodStart: string
    currentPriceId: string
    currentTierId: string
    subscriptionSchedule?: {
        scheduledPriceId: string
        scheduledTierId: string
    }
    subscriptionStatus?: string
    subscriptionNextPaymentAttempt?: string
    subscriptionLivemode?: boolean
    cancelAtPeriodEnd: boolean
    orgId: string
    paymentMethod?: any
    paymentOutsideStripe: boolean
    provider: string
}

export type UserProfile = {
    email: string
    name?: string
    data: IUserItem
    profilePic?: string
}

export enum MetricCode {
    Cmn1 = 'Cmn1',
    Cmn2 = 'Cmn2',
    Cnt1 = 'Cnt1',
    Cnt2 = 'Cnt2',
    Ctf1 = 'Ctf1',
    Ctf2 = 'Ctf2',
    Vch1 = 'Vch1',
    Vch2 = 'Vch2',
    Ew1 = 'Ew1',
    Ew2 = 'Ew2',
    Ew3 = 'Ew3',
}

export interface StatisticsParams {
    org: string
    begin?: string
    end?: string
    interval?: statsInterval
    window?: statsWindow
    increment?: boolean
}

export type statsInterval = 'D' | 'W' | 'M' | 'Y'

export type statsWindow = 'current' | 'previous'

export interface MetricUsage {
    current: number
    max: number
}

export interface MetricValue {
    value: number
    time: Date
    dStr?: string
}

export interface MetricStatistic {
    id: MetricCode
    name: string
    begin: Date
    end: Date
    values: MetricValue[]
    percentage?: number
    variation?: number
    variation_relative?: any
    percentage_relative?: any
}

export type LicenseUsage = Record<MetricCode, MetricUsage>

export type Statistics = Record<MetricCode, MetricStatistic>

export interface PermissionsDataModel {
    scopes?: string[]
    every?: boolean
}

export interface ActionModel {
    label: string
    action: ((x?: any) => void) | any | Function
    hasScope: boolean
    color?: string
}

export interface IDataChart {
    x: Date
    y: number
    dStr?: string
}

export interface IUsedFeatures {
    verificationTemplates: any
    issuanceTemplates: any
    activeUsers: any
    issuedCredentials: any
    dids: any
    apiKeys: any
}

export interface CertificateModel {
    alias?: string
    certificate: {
        AuthorityKeyId: string
        BasicConstraintsValid: boolean
        CRLDistributionPoints: string[]
        DNSNames: any
        EmailAddresses: string[]
        ExcludedDNSDomains: any
        ExcludedEmailAddresses: any
        ExcludedIPRanges: any
        ExcludedURIDomains: any
        ExtKeyUsage: number[]
        Extensions: [
            {
                Id: number[]
                Critical: boolean
                Value: string
            }
        ]
        ExtraExtensions: any
        IPAddresses: any
        IsCA: boolean
        Issuer: {
            Country: string[]
            Organization: string[]
            OrganizationalUnit: string[]
            Locality: any
            Names: {
                Type: number[]
                Value: string
            }
            PostalCode: any
            Province: any
            SerialNumber?: string
            StreetAddress: any
            CommonName: string
            ExtraNames: any
        }
        IssuingCertificateURL: string[]
        KeyUsage: number
        MaxPathLen: number
        MaxPathLenZero: boolean
        NotAfter: string
        NotBefore: string
        OCSPServer: string[]
        PermittedDNSDomains: any
        PermittedDNSDomainsCritical: boolean
        PermittedEmailAddresses: any
        PermittedIPRanges: any
        PermittedURIDomains: any
        PolicyIdentifiers: number[][]
        PublicKey: {
            N: any
            E: number
        }
        PublicKeyAlgorithm: number
        Raw: string
        RawIssuer: string
        RawSubject: string
        RawSubjectPublicKeyInfo: string
        RawTBSCertificate: string
        SerialNumber: number
        Signature: string
        Subject: {
            Country: string[]
            Organization: string[]
            OrganizationalUnit: string[]
            Locality: any
            Names: {
                Type: number[]
                Value: string
            }
            PostalCode: any
            Province: any
            SerialNumber?: string
            StreetAddress: any
            CommonName: string
            ExtraNames: any
        }
        SubjectKeyId: string
        URIs: any
        UnhandledCriticalExtensions: any
        UnknownExtKeyUsage: any
        Version: number
    }
    id: string
}

export type LicenseToPreselect =
    | 'Starter'
    | 'Essential'
    | 'Professional'
    | 'Enterprise'

export type PaymentMethodOption = 'Strapi' | 'None' | 'Multi'

export interface brandDetails {
    paymentMethod: PaymentMethodOption
}

export interface DataRequestModel {
    priceId: string
    orgId: string
    message?: string
    domainName?: string
}

export interface RequestModel {
    type: string
    data: DataRequestModel
}

export interface ITierModel {
    id?: any
    name: string
    description: string
    prices: IPriceModel[]
    provider?: string
    isPopular?: boolean
    ebsiDids?: boolean
    index: number
    limits: ILimitsModel[]
}

export interface IPriceModel {
    id?: string
    name?: string
    currency: string
    recurringInterval: string
    amount: number
    type: string
    numTrialDays?: number
    active?: boolean
}

export interface ILimitsModel {
    Code: MetricCode
    Value: number
    Description?: string
    TimeRange?: string | null
    Active?: boolean
}

export interface NumberFormatOptions {
    notation: 'compact' | 'standard' | 'scientific' | 'engineering' | undefined
    compactDisplay: 'short' | 'long' | undefined
    minimumFractionDigits: 0
}

export interface IGeneralSelectOption {
    id?: string
    text: string
    value: any
    action?: (x?: any) => void | void
}

export interface ITabSectionModel {
    id: string
    tabTitle: string
    tabContent?: React.ReactNode
    disabled?: boolean
    info?: string
    badget?: string | number
    button?: any
}

// Pagination
export type PageItemType = 'PAGE'
export type EllipsisItemType = 'ELLIPSIS'
export type FirstPageLinkItemType = 'FIRST_PAGE_LINK'
export type PreviousPageLinkItemType = 'PREVIOUS_PAGE_LINK'
export type NextPageLinkItemType = 'NEXT_PAGE_LINK'
export type LastPageLinkItemType = 'LAST_PAGE_LINK'

export type PaginationItemType =
    | PageItemType
    | EllipsisItemType
    | FirstPageLinkItemType
    | PreviousPageLinkItemType
    | NextPageLinkItemType
    | LastPageLinkItemType

export interface PaginationItemTypes {
    PAGE: PageItemType
    ELLIPSIS: EllipsisItemType
    FIRST_PAGE_LINK: FirstPageLinkItemType
    PREVIOUS_PAGE_LINK: PreviousPageLinkItemType
    NEXT_PAGE_LINK: NextPageLinkItemType
    LAST_PAGE_LINK: LastPageLinkItemType
}

export interface PaginationItemKeys {
    FIRST_ELLIPSIS: number
    SECOND_ELLIPSIS: number
    FIRST_PAGE_LINK: number
    PREVIOUS_PAGE_LINK: number
    NEXT_PAGE_LINK: number
    LAST_PAGE_LINK: number
    [type: string]: number
}

export interface PaginationProps {
    currentPage: number
    totalPages: number
    boundaryPagesRange?: number
    siblingPagesRange?: number
    hideEllipsis?: boolean
    hidePreviousAndNextPageLinks?: boolean
    hideFirstAndLastPageLinks?: boolean
    onChange?: (x?) => void
    disabled?: boolean
    totalItems?: number
    pageSize?: number
    className?: string
}

export interface IGeneralSelectOption {
    id?: string
    text: string
    value: any
    action?: (x?: any) => void | void
}

export interface IEWCredentialsPaginatedResponseModel {
    items: ICredentialsItemModel[]
    links: {
        last: string
        next?: string
        prev?: string
    }
    pageSize: number
    self: string
    total: number
}

export interface IConnectionsDataAgreement {
    created_at?: string
    dag: {
        '@context': string[] | string
        id: string
        version: string
        template_id: string
        template_version: string
        data_subject: string
        data_holder: string
        data_receiver: DA_DataReceiver
        purposes: DA_Purpose[]
        personal_data: DA_PersonalData[]
        dpia?: {
            dpia_date: Date
            dpia_summary_url: string
        }
        event: DataAgreementEvent[]
    }
}

export interface IConnectionsDataAgreementGroup {
    created_at?: string
    dataAgreements: IConnectionsDataAgreement[]
    dataReceiverId?: string
    dataReceiver?: DA_DataReceiver
}

export interface DA_DataReceiver {
    id: string
    name: string
    url?: string
    service?: string
    consent_duration?: number
    form_of_consent: 'explicit' | 'implicit'
}

export interface UserCredentialSelection {
    process_id: string
    needs_consent?: boolean
    available_credentials?: CredentialSelection[]
}

export interface UserSelectedCredentials {
    process_id: string
    selected_credentials?: CredentialSelection[]
}

export interface CredentialSelection {
    descriptor_id: string
    credentials: EWCredential[]
    mandatory?: boolean
    purpose?: string
    trustLevel?: number
}

export interface EWCredential {
    vc: VerifiableCredential
    vcFormat?: any
}

export interface IEWConnectionsPaginatedResponseModel {
    items: IConnectionsDataAgreement[]
    links: {
        last: string
        next?: string
        prev?: string
    }
    pageSize: number
    self: string
    total: number
}

export interface IAppIntegrationModel {
    client_id?: string
    client_name: string
    client_secret?: string
    redirect_uris: string[]
    grant_types: string[]
    response_types: string[]
    scope: string
    formScopes?: string[]
    audience?: any
    owner: string
    policy_uri: string
    allowed_cors_origins: string[]
    tos_uri?: string
    client_uri?: string
    logo_uri?: string
    contacts: string[]
    client_secret_expires_at: number
    subject_type: string
    jwks?: {
        keys: {
            use: string
            kty: string
            kid: string
            n: string
            e: string
            x5c: string[]
        }[]
    }
    token_endpoint_auth_method: string
    token_endpoint_auth_signing_alg?: string
    request_uris?: string[] | any[]
    request_object_signing_alg?: string
    userinfo_signed_response_alg?: string
    created_at?: string
    updated_at?: string
    frontchannel_logout_uri?: string
    post_logout_redirect_uris?: string[]
    backchannel_logout_uri?: string
    metadata?: any
    connect_tenant?: string
    connect_secret?: string
    connect_apikey?: string
    license_tenant?: string
    license_organization?: string
    qrCustom?: {
        title?: string
        description?: string
        isBrandHide?: boolean
    }
    display_tutorial?: boolean
    // Optional param in front (not comming from BE)
    _searchableText?: boolean
}

export interface IAppIntegrationModelSearchable extends IAppIntegrationModel {
    created_at_formatted?: string
    redirect_uris__str?: string
}

export interface IAppIntScope {
    scope: string
    credentialTypes: string[][] // array of the credentials that will be displayed on the wallet
    description_short: TranslationModel //short visualization for configuration
    description_long: TranslationModel //long description for the consent screen
}

export interface IEWNotificationModel {
    notificationId: string
    credentialGroup: string
    group_name: Map<string, string> | TranslationModel
    issuer_name: Map<string, string> | TranslationModel
    expirationAt?: string
    readAt?: string
    createdAt?: string
    expired?: boolean
    createdFromTime?: string
}
