import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { didsSelectors } from '../../../studio/Dids'
import {
    IDIDModel,
    ICredentialAuthorityModel,
    IConnectionsDataAgreementGroup,
} from '../../../../interfaces/interfaces'
import { sessionSelectors } from '../../../common/session'
import {
    getIsActiveSession,
    userHasScopes,
} from '../../../../utils/permissionsUtil'
import styles from './connections.module.scss'
import { didsScopes } from '../../../studio/Dids/views/didList/didList.constants'
import { getCatalog } from '../../../common/catalog/catalogActions'
import { getDids, setEWAccountDidAction } from '../../../studio/Dids/didActions'
import { getStatistics } from '../../../common/Statistics/statisticsActions'
import DidsSelector from '../../../../Components/molecules/didsSelector/DidsSelector'
import { licensesSelectors } from '../../../common/licenses'
import { statsSelectors } from '../../../common/Statistics'
import { errorPanelReachedFeatures } from '../../../common/PanelScafolding/private/panelScafoldingPrivateConstants'
import { getLimitsValues } from '../../../../utils/licensesUtil'
import NoConnectionsPanel from '../components/noConnectionsPanel/NoConnectionsPanel'
import ConnectionsTable from '../components/connectionsTable/ConnectionsTable'
import {
    headersConnectionsTable,
    propertiesToFuzzySearchConnections,
    scanConnectionSteps,
} from '../data/connections.constants'
import { verifierScopes } from '../../../studio/Verifier/data/verifier.constants'
import { getVerifierConfigs } from '../../../studio/Verifier/verifierActions'
import TableSkeleton from '../../../../Components/molecules/skeletons/tableSkeleton/TableSkeleton'
import { defaultPageSize } from '../../../../Components/Pagination/paginationConstants'
import { catalogSelectors } from '../../../common/catalog'
import DarkButton from '../../../../Components/atoms/buttons/DarkButton/DarkButton'
import { connectionsSelectors } from '../../../../store/connections'
import { getConnections } from '../../../../store/connections/connectionsActions'
import SearchableListHeader from '../../../../Components/molecules/headers/SearchableListHeader/SearchableListHeader'
import { getCredentials } from '../../../../store/requestCredentials/requestCredentialsActions'
import { clearModalState } from '../../../../store/scanModal/scanModalActions'
import CardListSkeleton from '../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'

type ISearchState = {
    searched: string
    searchedResult: any
}

export const Connections: React.FC = () => {
    const dispatch = useDispatch()

    // Selectors
    const isLoading = useSelector(didsSelectors.isLoading)
    const didConfigs = useSelector(didsSelectors.getEWDids)
    const EWSelectedDid = useSelector(didsSelectors.getEWDid)
    const didsSetup = useSelector(didsSelectors.getDids)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const excededLimitsState = useSelector(licensesSelectors.excededLimits)
    const usedFeatures = useSelector(statsSelectors.getUsedFeaturesNumber)
    const statisticsIsLoading = useSelector(statsSelectors.getStatisticsLoading)
    const currentLicense = useSelector(licensesSelectors.getCurrentProduct)
    const authorityIssuers = useSelector(catalogSelectors.getCatalogAuthority)
    const connectionsData = useSelector(
        connectionsSelectors.getFormattedConnections
    )
    const selectedConnection = useSelector(
        connectionsSelectors.getSelectedConection
    )
    const connectionsStatusLoading = useSelector(connectionsSelectors.isLoading)
    const loadingCatalog = useSelector(catalogSelectors.getLoadingStatus)
    const licenseCustomLimits = useSelector(
        sessionSelectors.getLicenseCustomLimits
    )

    // States and Consts
    const [dids, setDids] = useState<IDIDModel[]>(didConfigs)
    const [selecteValue, setSelectedValue] = useState(EWSelectedDid)
    const [loading, setLoading] = useState(isLoading)
    const [selectedItem, selectItem] = useState<
        IConnectionsDataAgreementGroup | undefined
    >()
    const [authority, setAuthority] = useState<ICredentialAuthorityModel[]>()
    const [showScanModal, setShowScanModal] = React.useState(false)
    const [scanCurrentStep, setScanCurrentStep] = React.useState(
        scanConnectionSteps[0]
    )

    /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
    // const [paginatedConnections, setPaginatedConnections] =
    //     useState<IConnectionsDataAgreement[]>()

    /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
    // const [pageIndex, setPageIndex] = useState(1)
    // const [totalItems, setTotalItems] = useState(connectionsData?.total || 0)

    const [connections, setConnections] = useState<
        { [x: string]: string | any[] }[] | undefined
    >()

    // const connectionsDataFormatted =
    //     connections?.length &&
    //     connections?.map((el) => {
    //         // @ts-ignore
    //         const formattedConnection = { ...el?.dag }
    //         formattedConnection.dataReceiverId = el?.dag.data_receiver?.id

    //         return formattedConnection
    //     })

    // const groupedDataAgreements =
    //     connections?.length && connectionsDataFormatted
    //         ? regroupByProperty(
    //               connectionsDataFormatted,
    //               'dataReceiverId',
    //               'dataAgreements'
    //           )
    //         : []

    // const groupedDataAgreementsFullObject = groupedDataAgreements?.map(
    //     (dag) => {
    //         const formattedDag = { ...dag } as Object
    //         const dataReceiver = authorityIssuers?.find((authority) => {
    //             return authority?.id === dag?.dataReceiverId
    //         })
    //         if (dataReceiver && dag) {
    //             formattedDag['dataReceiver'] = dataReceiver
    //         }

    //         return formattedDag
    //     }
    // )

    const initialListState = {
        searched: '',
        searchedResult: connections || '',
        /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
        // searchedResult: paginatedCreds || '',
    }

    /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
    // const paginationData = {
    //     currentPage: pageIndex,
    //     totalPages: getTotalPageItems(totalItems || 0, defaultPageSize) || 0,
    //     onChange: handlePageChange,
    //     totalItems: totalItems || 0,
    //     pageSize: defaultPageSize,
    //     page: pageIndex,
    // }
    // const showPagination =
    //     !!paginationData?.totalItems &&
    //     !!paginationData.pageSize &&
    //     paginationData?.totalItems >= paginationData.pageSize &&
    //     !!credentialsData?.items?.length

    const [searchState, setSearchState] =
        useState<ISearchState>(initialListState)
    const hasDidsReadScope = !!(
        availableScopes && userHasScopes(didsScopes?.read, availableScopes)
    )
    const hasCreateScope = !!(
        availableScopes && userHasScopes(didsScopes?.create, availableScopes)
    )
    const showDidsSelector =
        (didConfigs?.length && !!didConfigs[0]) || isLoading

    const limitsAreExceded =
        excededLimitsState?.length &&
        excededLimitsState?.includes(errorPanelReachedFeatures.dids)

    const limitsAreReached =
        usedFeatures?.dids ===
        getLimitsValues(currentLicense, licenseCustomLimits)?.dids

    const canUserCreateDIDs =
        !!hasCreateScope && !limitsAreReached && !limitsAreExceded

    const hasReadVerifiersScope = !!(
        availableScopes && userHasScopes(verifierScopes?.read, availableScopes)
    )

    // TODO: Update this constants with scopes if they're updated in BE
    const hasReadConnectionsScope = true
    const hasCreateConnectionsScope = true
    const hasReadCredsScope = true

    // Effects
    useEffect(() => {
        if (hasDidsReadScope) {
            dispatch(getCatalog())
            dispatch(getDids())
        }
        if (hasReadVerifiersScope) {
            dispatch(getVerifierConfigs())
        }
        if (hasReadCredsScope) {
            dispatch(getCatalog()),
                EWSelectedDid && dispatch(getCredentials(EWSelectedDid))
        }

        if (hasReadConnectionsScope) {
            dispatch(getCatalog()),
                EWSelectedDid &&
                    authorityIssuers?.length &&
                    dispatch(getConnections(EWSelectedDid))
        }
        // This will be used in the future to know if more dids can be created or if organization has reached the limit
        if (!statisticsIsLoading && getIsActiveSession()) {
            dispatch(getStatistics())
        }
    }, [])

    // Effects
    useEffect(() => {}, [didsSetup, dids])
    useEffect(() => {}, [selectedConnection])
    useEffect(() => {
        if (hasReadConnectionsScope) {
            EWSelectedDid &&
                authorityIssuers?.length &&
                dispatch(getConnections(EWSelectedDid))
        }
    }, [authorityIssuers])

    useEffect(() => {
        EWSelectedDid &&
            authorityIssuers?.length &&
            dispatch(
                getConnections(
                    EWSelectedDid
                    /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
                    // 1,
                    // defaultPageSize
                )
            )
        selectItem(undefined), setLoading(true)
        setTimeout(() => {
            setSelectedValue(EWSelectedDid)
            setLoading(false)
        }, 1000)
    }, [EWSelectedDid])

    useEffect(() => {
        !dids?.length && setDids(didConfigs)
    }, [didConfigs])

    useEffect(() => {
        setAuthority(authorityIssuers || [])
    }, [authorityIssuers?.length])

    useEffect(() => {
        selectItem(undefined), setConnections(connectionsData)
    }, [connectionsData])

    useEffect(() => {
        /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
        // setPaginatedConnections(
        //         paginate(connections, defaultPageSizeForPreview, pageIndex)
        //     )
        setSearchState(initialListState)
    }, [connections])

    useEffect(() => {
        /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
        // setTotalItems(connectionsData?.total || 0)
    }, [connectionsData])

    /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
    // useEffect(() => {
    //     if (!!connections?.length) {
    //         setSearchState(initialListState)
    //     }
    // }, [paginatedConnections])

    // Functions
    const selectDid = (e) => dispatch(setEWAccountDidAction(e?.target?.value))

    const handleSearchedChange = (items) => {
        if (!!items) {
            setSearchState({
                ...searchState,
                searchedResult: items,
                /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
                // searchedResult: paginate(items, defaultPageSize, 1),
            })
            /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
            // setTotalItems(items.length)
        }
    }

    /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
    // const handlePageChange = (page) => {
    //     dispatch(
    //         getConnections(
    //             EWSelectedDid || ''
    //             page,
    //             defaultPageSize
    //         )
    //     )
    //     setPageIndex(page)
    //     scrollToTop()
    // }

    return (
        <div className={styles.connections}>
            <header className={styles.connections__header}>
                {showDidsSelector ? (
                    <DidsSelector
                        options={didConfigs}
                        value={selecteValue}
                        onChange={selectDid}
                        canCreate={canUserCreateDIDs}
                        isLoading={loading}
                    />
                ) : null}
                <DarkButton
                    text={'ew.modalScan.connect'}
                    disabled={!hasCreateConnectionsScope}
                    functionality={() => setShowScanModal(true)}
                />
            </header>
            {!isLoading &&
            !connectionsStatusLoading &&
            loadingCatalog?.status !== 'active' ? (
                <SearchableListHeader
                    title={''}
                    isLoading={
                        isLoading ||
                        connectionsStatusLoading ||
                        loadingCatalog?.status === 'active'
                    }
                    className={styles.searchBar}
                    options={connections}
                    propertiesToFuzzySearch={propertiesToFuzzySearchConnections}
                    handleSearchedChange={handleSearchedChange}
                />
            ) : (
                <CardListSkeleton
                    cardsNumber={1}
                    className={styles.searchBarSkeleton}
                />
            )}

            {!isLoading &&
            loadingCatalog?.status !== 'active' &&
            authorityIssuers?.length &&
            !connectionsStatusLoading &&
            connections ? (
                connections?.length ? (
                    <ConnectionsTable
                        tableData={searchState?.searchedResult}
                        headerData={headersConnectionsTable}
                        authorityIssuers={authority}
                        selectItem={selectItem}
                        selectedItem={selectedItem}
                        /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
                        // paginationData={paginationData}
                    />
                ) : (
                    <NoConnectionsPanel
                        showScanModal={() => setShowScanModal(true)}
                    />
                )
            ) : (
                <TableSkeleton
                    columnsNumber={3}
                    rowsNumber={defaultPageSize}
                    responsiveCards={true}
                />
            )}
            {showScanModal && scanCurrentStep ? (
                <scanCurrentStep.content
                    isConnection={true}
                    hideModal={() => {
                        setShowScanModal(false),
                            setScanCurrentStep(scanConnectionSteps[0]),
                            dispatch(clearModalState())
                    }}
                    setScanCurrentStep={setScanCurrentStep}
                    scanCurrentStep={scanCurrentStep}
                    scanSteps={scanConnectionSteps}
                />
            ) : null}
        </div>
    )
}
