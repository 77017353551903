import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { licensesSelectors } from '../..'
import {
    brandLabels,
    countriesAndStates,
    domains,
} from '../../../../../assets/globalConstants'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import ErrorFilledPanel from '../../../../../Components/molecules/notification/panels/error/errorFilledPanel/ErrorFilledPanel'
import { initialLang } from '../../../../../i18n'
import { IPriceModel, StepModel } from '../../../../../interfaces/interfaces'
import { formatStringDate } from '../../../../../utils/formatDataUtil'
import {
    hideScreenLoaderAction,
    showScreenLoaderAction,
} from '../../../Loader/loaderActions'
import {
    createOrgAction,
    getOrgAction,
    loadOrgAction,
    updateOrgAction,
} from '../../../organization/organizationActions'
import {
    getIfTermsAreAccepted,
    getOrg,
} from '../../../organization/organizationSelectors'
import { sessionSelectors } from '../../../session'
import { userSelectors } from '../../../Team/tabsContent/users/store'
import { getUsers } from '../../../Team/tabsContent/users/store/usersActions'
import { orgServicesEndpoints } from '../../../Welcome/welcomeConstants'
import {
    subscriptionSteps,
    createSubscriptionSteps,
} from '../../licenses.constants'
import {
    preselectLicenseAction,
    setCurrentStepAction,
    setNewLicenseAction,
    setSelectedPeriodAction,
    updateLicenseAction,
} from '../../licensesActions'
import styles from './changeSubscription.module.scss'
import SelectSubscriptionFirstStep from './steps/firstStepNoLicense/SelectSubscriptionFirstStep'
import ChangeSubscriptionFirstStep from './steps/firstStep/ChangeSubscriptionFirstStep'
import ChangeSubscriptionSecondStep from './steps/secondStep/ChangeSubscriptionSecondStep'
import ChangeSubscriptionThirdStep from './steps/thirdStep/ChangeSubscriptionThirdStep'
import { onPremise } from '../../../../../data/globalVar'
import cx from 'classnames'
import { userHasScopes } from '../../../../../utils/permissionsUtil'
import {
    getIfProductHasAmount,
    getIfProductHasTrialDays,
    getPrice,
    getTierActivePriceMonthly,
    getTierActivePriceYearly,
} from '../../../../../utils/licensesUtil'
import { setSelectedRoute } from '../../../PanelScafolding/scafoldingActions'
import { useHistory } from 'react-router-dom'
import { orgSelectors } from '../../../organization'
import {
    rolesScopes,
    usersScopes,
} from '../../../Team/tabsContent/users/store/team.constants'
import {
    defaultCountry,
    defaultCountryByBrand,
    defaultCountryIndexByBrand,
} from '../../../../../utils/formUtils'
import { getRoles } from '../../../Team/tabsContent/roles/store/rolesActions'
import { rolesSelectors } from '../../../Team/tabsContent/roles/store'
import { isDefaultBrandActive } from '../../../../../utils/brandThemeUtil'

type IChangeSubscriptionProps = {}

const ChangeSubscription: React.FC<IChangeSubscriptionProps> = ({}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const currentStep = useSelector(licensesSelectors.getCurrentStep)
    const subscriptionInfo = useSelector(licensesSelectors.getSubscriptionInfo)
    let currentSubscriptionPeriod = useSelector(
        licensesSelectors.getCurrentSubscriptionPeriod
    )
    const subscriptionIsUpdating = useSelector(
        licensesSelectors.updatingSubscriptionLoading
    )
    const org = useSelector(orgSelectors.getOrg)
    const selectedPeriod = useSelector(licensesSelectors.getSelectedPeriod)
    const currentLicense = useSelector(licensesSelectors.getCurrentProduct)
    const newLicense = useSelector(licensesSelectors.getNewLicense)
    const subscriptionInfoIsLoading = useSelector(
        licensesSelectors.getSubscriptionInfoIsLoading
    )

    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)

    const licenseIsIncomplete =
        subscriptionInfo?.subscriptionStatus === 'incomplete'
    const currentSubscriptionPeriodIsYear = currentSubscriptionPeriod === 'year'
    let newIsLowerThanCurrent =
        currentLicense?.prices &&
        newLicense?.prices &&
        currentLicense?.prices[0]?.amount > newLicense?.prices[0]?.amount
    const [nextStepActive, setIsNextStepActive] = useState(false)

    const newLicenseType = newLicense?.id

    const newPriceId = newLicense?.prices?.find((el) => {
        return (
            el.recurringInterval?.toLowerCase() === selectedPeriod.toLowerCase()
        )
    })?.id

    useEffect(() => {
        document?.getElementById('topOfTheView')?.scrollIntoView(false)
    }, [])

    useEffect(() => {
        newIsLowerThanCurrent =
            currentLicense?.prices &&
            newLicense?.prices &&
            currentLicense?.prices[0]?.amount > newLicense?.prices[0]?.amount
    }, [newLicense])

    const selectPeriod = (period: string) => {
        dispatch(setSelectedPeriodAction(period))
    }

    const changeActiveStep = (step: StepModel) => {
        dispatch(setCurrentStepAction(step)),
            document?.getElementById('topOfTheView')?.scrollIntoView(false)
    }

    const getSavedYearlyPayment = () => {
        const yearlyAmount = newLicense?.prices?.filter((el) => {
            return el.recurringInterval === 'year'
        })[0]?.amount
        const monthlyAmount = newLicense?.prices?.filter((el) => {
            return el.recurringInterval === 'month'
        })[0]?.amount

        const monthlyAmountTotal = monthlyAmount * 12

        return (monthlyAmountTotal - yearlyAmount) / 100
    }

    const getPriceId = (prices: IPriceModel[]) => {
        const selectedPeriodPrice = prices?.filter((el) => {
            return el.recurringInterval === selectedPeriod
        })[0]

        return selectedPeriodPrice?.id
    }

    const goToNextStep = () => {
        const nextStepIndex = currentStep?.order || 0 + 1
        if (
            currentStep?.order === 2 &&
            (licenseIsIncomplete || !subscriptionInfo?.subscriptionStatus)
        ) {
            if (!!validOrgForm && aceptedConditions) {
                submitOrgData()
            } else {
                if (newLicenseType && newPriceId && orgId) {
                    const lastStep = flowSteps?.length - 1
                    dispatch(showScreenLoaderAction())
                    setCurrentStepAction(flowSteps[lastStep])
                    !onPremise &&
                        userIsOrgOwner &&
                        dispatch(
                            updateLicenseAction(newLicense, newPriceId, orgId)
                        )
                }
            }
        }
        if (currentStep?.order === 1) {
            !licenseIsIncomplete && org?.id && submitOrgData()
            dispatch(setCurrentStepAction(flowSteps[nextStepIndex]))
        } else if (
            currentStep?.order === 3 ||
            (!licenseIsIncomplete &&
                (org?.id || orgId) &&
                currentStep?.order === 2)
        ) {
            if (newLicenseType && newPriceId && orgId) {
                dispatch(showScreenLoaderAction())
                !onPremise &&
                    userIsOrgOwner &&
                    !licenseIsIncomplete &&
                    dispatch(updateLicenseAction(newLicense, newPriceId, orgId))
            }
        }
    }

    const updateLicense = () => {
        !onPremise &&
            userIsOrgOwner &&
            !licenseIsIncomplete &&
            dispatch(
                updateLicenseAction(newLicense, newPriceId || '', orgId || '')
            )
    }

    const [previousStepsAreInactive, setPreviousStepsAreInactive] =
        React.useState(!!org?.id?.length)
    const deactivatePreviousSteps = () => {
        setPreviousStepsAreInactive(true)
    }

    const [showDowngradeInfo, setShowDowngradeInfoModal] = React.useState(false)

    // ORGANIZATION DATA
    const user = useSelector(sessionSelectors.getUserEmail_s)
    const uRole = useSelector(sessionSelectors.getUserRole_s)
    const userIsOrgOwner = uRole === 'OrganizationOwner'
    const users = useSelector(userSelectors?.getUsers)
    const roles = useSelector(rolesSelectors.getRoles)

    const initialFormState = {
        email: user,
        // TODO: Get role from state when it is saved in localstore
        role: uRole || roles[0]?.role_id,
    }

    const [formProfileData, setFormProfileState] = useState(initialFormState)
    const { email, role } = formProfileData
    const canReadRoles = !!(
        availableScopes && userHasScopes(rolesScopes?.read, availableScopes)
    )
    const canReadUsers = !!(
        availableScopes && userHasScopes(usersScopes?.read, availableScopes)
    )

    useEffect(() => {
        if (!isNewUser && orgId) {
            canReadUsers && dispatch(getUsers())
            canReadRoles && dispatch(getRoles())
        }
        if (!orgId && !isNewUser) {
            !onPremise && dispatch(getOrgAction())
        }
    }, [])

    // ORGANIZATION
    const termsAreAccepted = useSelector(getIfTermsAreAccepted)
    const [aceptedConditions, setConditionsState] = useState(termsAreAccepted)

    const orgId = useSelector(sessionSelectors.getUserOrganization)
    const orgInfo = org?.orgInfo
    const orgBilling = org?.billingInfo
    const orgAddress = orgBilling?.legalAddress
    const currentCountry = orgAddress
        ? orgAddress?.country
        : defaultCountryByBrand
    const orgCountry = countriesAndStates.find(
        (el) => currentCountry === el.name_en || currentCountry === el.name_es
    )
    const orgCountryTranslated =
        orgCountry && initialLang
            ? orgCountry['name_' + initialLang]
            : orgCountry?.name_es || defaultCountry(defaultCountryIndexByBrand)

    // STATES
    const defaultStates = defaultCountryByBrand?.states
    const [states, setStates] = useState(orgCountry?.states || defaultStates)
    const emptyFormOrgData = {
        name: orgInfo?.name || '',
        legalName: orgBilling?.LegalName || '',
        domainName: orgInfo?.domainName || domains ? domains[0]?.value : '',
        VATRegistration: org?.billingInfo?.vatRegistration || '',
        discoveryURL: orgInfo?.discoveryURL || '',
    }
    const emptyFormAddress = {
        street: orgAddress?.street || '',
        suite: orgAddress?.suite || '',
        number: orgAddress?.number || '',
        locality: orgAddress?.locality || '',
        country: orgCountryTranslated || '',
        region: orgAddress?.region || states ? states[0] : '',
        postalCode: orgAddress?.postalCode || '',
    }
    const [formOrgData, setFormState] = useState(emptyFormOrgData)
    const [legalAddres, setLegalAddressState] = useState(emptyFormAddress)

    const { name, legalName, domainName, VATRegistration, discoveryURL } =
        formOrgData
    const { street, suite, number, locality, region, country, postalCode } =
        legalAddres

    const validOrgForm = !!(
        name.trim()?.length &&
        legalName.trim()?.length &&
        domainName.trim()?.length &&
        discoveryURL.trim()?.length &&
        street.trim()?.length &&
        number.trim()?.length &&
        locality.trim()?.length &&
        region &&
        region?.trim()?.length &&
        country &&
        country?.trim()?.length &&
        postalCode.trim()?.length &&
        VATRegistration.trim()?.length
    )
    const currentAndEqualLicensesAreSameType =
        currentLicense?.id === newLicense?.id

    const newLicenseDate = formatStringDate(
        subscriptionInfo?.currentPeriodEnd,
        'DD/MM/YYYY'
    )

    // This will be util when we show the amount of the current license period not used
    // const alreadyPayed = !newIsLowerThanCurrent
    // ? currentLicense?.prices?.filter((el) => {
    //       return el.recurringInterval === 'month'
    //   })[0]?.amount
    // : null

    // Functions
    const handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        setFormState({
            ...formOrgData,
            [name]: value,
        })
    }

    const handleAddressInputsChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        setLegalAddressState({
            ...legalAddres,
            [name]: value,
        })
    }

    const getStates = (name: string) => {
        setStates([])
        const selectedCountry = countriesAndStates.find((el) => {
            return name === el.name_en || name === el.name_es
        })

        setStates(selectedCountry?.states || [])
        setLegalAddressState({
            ...legalAddres,
            country: name,
            region: selectedCountry?.states[0] || '',
        })
    }

    const isNewUser = !!(
        (availableScopes &&
            userHasScopes(['createOrganization'], availableScopes)) ||
        (!subscriptionInfo?.subscriptionStatus &&
            !subscriptionInfoIsLoading &&
            subscriptionInfo?.subscriptionStatus !== 'incomplete')
    )

    const flowSteps = isNewUser ? createSubscriptionSteps : subscriptionSteps

    useEffect(() => {
        setFormState({
            ...emptyFormOrgData,
            domainName: orgInfo?.domainName || domains[0]?.value || '',
        })
    }, [
        orgBilling?.vatRegistration?.length,
        orgInfo?.domainName?.length,
        domains,
    ])

    useEffect(() => {}, [selectedPeriod, availableScopes])

    useEffect(() => {
        if (currentAndEqualLicensesAreSameType) {
            dispatch(
                setSelectedPeriodAction(
                    currentSubscriptionPeriodIsYear ? 'month' : 'year'
                )
            )
        }
    }, [currentAndEqualLicensesAreSameType])

    useEffect(() => {
        if (country && orgCountryTranslated && orgCountry) {
            orgCountry && setStates(orgCountry?.states)
            setLegalAddressState(emptyFormAddress)
        }
    }, [orgAddress])

    const buildDataObject = () => {
        return {
            id: org?.id || undefined || '',
            providerId: 'gataca',
            orgInfo: {
                name: name,
                domainName: domainName,
                discoveryURL: discoveryURL,
                services: orgServicesEndpoints,
            },
            billingInfo: {
                legalAddress: {
                    street,
                    suite,
                    number,
                    locality,
                    region,
                    country,
                    postalCode,
                },
                LegalName: legalName,
                vatRegistration: VATRegistration,
            },
            priceId: getPriceId(newLicense?.prices),
        }
    }
    const selectStarter = () => {
        dispatch(preselectLicenseAction('Starter'))
        history?.push('/home')
        dispatch(setSelectedRoute('home'))
    }

    const formattedOrgToCreate = buildDataObject()

    function submitOrgData() {
        if (!onPremise && !org?.id) {
            dispatch(showScreenLoaderAction())
            dispatch(createOrgAction(formattedOrgToCreate, false))
        }
    }

    const setNewLicense = (license) => {
        ;(userIsOrgOwner || isNewUser) && dispatch(setNewLicenseAction(license))
    }
    const isLowerThanCurrent = (tier) => {
        return (
            currentLicense?.prices &&
            tier?.prices &&
            (currentLicense?.prices[0]?.amount >
                newLicense?.prices[0]?.amount ||
                (currentSubscriptionPeriod === 'year' &&
                    selectedPeriod === 'month' &&
                    currentLicense?.id === newLicense?.id))
        )
    }

    const isUpgrade =
        (selectedPeriod === 'year' &&
            !newIsLowerThanCurrent &&
            currentLicense.id !== newLicense?.id) ||
        (currentLicense.id === newLicense?.id &&
            currentSubscriptionPeriod === 'month')

    return (
        <React.Fragment>
            <ScreenHeader
                previousStepsAreInactive={previousStepsAreInactive}
                id={'topOfTheView'}
                className={cx('marginTop10')}
                title={'subscription.title'}
                hideBorderBottom={true}
                steps={
                    userIsOrgOwner ||
                    (isNewUser &&
                        !getIfProductHasTrialDays(newLicense, selectedPeriod) &&
                        getIfProductHasAmount(newLicense, selectedPeriod))
                        ? flowSteps
                        : undefined
                }
                currentStep={currentStep}
                onChangeStep={changeActiveStep}
                nextStepActive={nextStepActive && currentStep !== flowSteps[1]}
            />
            {userIsOrgOwner || isNewUser ? (
                <div className={styles.changeSubscriptionContainer}>
                    {currentStep?.id === flowSteps[0]?.id ? (
                        orgId ? (
                            <ChangeSubscriptionFirstStep
                                getPrice={getPrice}
                                // selectedPeriod={selectedPeriod}
                                updateLicense={updateLicense}
                                hideDowngradeInfoModal={() => {
                                    setShowDowngradeInfoModal(false)
                                }}
                                showDowngradeInfoModal={showDowngradeInfo}
                                goToNextStep={() => {
                                    setShowDowngradeInfoModal(false),
                                        goToNextStep()
                                }}
                                flowSteps={flowSteps}
                                setPeriodChecked={selectPeriod}
                                switchPeriodValue={selectedPeriod}
                                setIsNextStepActive={setIsNextStepActive}
                                setNewLicense={setNewLicense}
                                currentLicense={currentLicense}
                                newLicense={newLicense}
                            />
                        ) : (
                            <SelectSubscriptionFirstStep
                                getPrice={getPrice}
                                flowSteps={flowSteps}
                                setPeriodChecked={selectPeriod}
                                switchPeriodValue={selectedPeriod}
                                setIsNextStepActive={setIsNextStepActive}
                                setNewLicense={setNewLicense}
                                currentLicense={currentLicense}
                                newLicense={newLicense}
                            />
                        )
                    ) : null}
                    {currentStep?.label === flowSteps[1]?.label &&
                        (!subscriptionInfo?.subscriptionStatus &&
                        flowSteps[1]?.id === createSubscriptionSteps[1]?.id ? (
                            <ChangeSubscriptionSecondStep
                                isNewUser={isNewUser}
                                termsAreAccepted={aceptedConditions}
                                handleTermsInput={setConditionsState}
                                setIsNextStepActive={setIsNextStepActive}
                                states={states}
                                formOrgData={formOrgData}
                                legalAddres={legalAddres}
                                email={email}
                                flowSteps={flowSteps}
                                validOrgForm={validOrgForm}
                                handleInputChange={handleInputChange}
                                handleAddressInputsChange={
                                    handleAddressInputsChange
                                }
                                getStates={getStates}
                            />
                        ) : (
                            <ChangeSubscriptionThirdStep
                                setPreviousStepsInactive={
                                    deactivatePreviousSteps
                                }
                                newLicense={newLicense}
                                newPriceId={newPriceId || ''}
                                licenseIsIncomplete={!!licenseIsIncomplete}
                                flowSteps={flowSteps}
                                formattedOrgToCreate={formattedOrgToCreate}
                                setIsNextStepActive={setIsNextStepActive}
                            />
                        ))}
                    {currentStep?.id === flowSteps[2]?.id && (
                        <ChangeSubscriptionThirdStep
                            setPreviousStepsInactive={deactivatePreviousSteps}
                            newLicense={newLicense}
                            newPriceId={newPriceId || ''}
                            licenseIsIncomplete={!!licenseIsIncomplete}
                            formattedOrgToCreate={formattedOrgToCreate}
                            flowSteps={flowSteps}
                            setIsNextStepActive={setIsNextStepActive}
                        />
                    )}

                    <div className={styles.subscriptionPayment}>
                        {!getIfProductHasTrialDays(
                            newLicense,
                            selectedPeriod
                        ) ? (
                            <div className={styles.periodButtons}>
                                <button
                                    className={`${
                                        styles.periodButtons__monthly
                                    } ${
                                        selectedPeriod === 'month'
                                            ? styles.periodButtons__active
                                            : styles.periodButtons__inactive
                                    }${
                                        currentLicense?.id === newLicense?.id &&
                                        currentSubscriptionPeriod === 'month'
                                            ? styles.periodButtons__disabled
                                            : ''
                                    }`}
                                    disabled={
                                        currentLicense?.id === newLicense?.id &&
                                        currentSubscriptionPeriod === 'month'
                                    }
                                    onClick={() => selectPeriod('month')}
                                >
                                    <p className={cx('bodyBoldXS')}>
                                        {t('subscription.payMonthly')}
                                    </p>
                                    <span className={cx('bodyRegularXS')}>
                                        {t('subscription.fullPrice')}
                                    </span>
                                </button>
                                <button
                                    className={`${cx('marginLeft10')} ${
                                        styles.periodButtons__yearly
                                    } ${
                                        selectedPeriod === 'year'
                                            ? styles.periodButtons__active
                                            : styles.periodButtons__inactive
                                    } ${
                                        currentLicense?.id === newLicense?.id &&
                                        currentSubscriptionPeriodIsYear
                                            ? styles.periodButtons__disabled
                                            : ''
                                    }`}
                                    disabled={
                                        currentLicense?.id === newLicense?.id &&
                                        currentSubscriptionPeriodIsYear
                                    }
                                    onClick={() => selectPeriod('year')}
                                >
                                    <p className={cx('bodyBoldXS')}>
                                        {t('subscription.payYearly')}
                                    </p>
                                    {!!getSavedYearlyPayment() ? (
                                        <span className={cx('bodyRegularXS')}>
                                            {t('subscription.savePrice', {
                                                quantity:
                                                    getSavedYearlyPayment(),
                                            })}
                                        </span>
                                    ) : (
                                        <span className={cx('bodyRegularXS')}>
                                            {t('subscription.fullPrice')}
                                        </span>
                                    )}
                                </button>
                            </div>
                        ) : null}
                        {!getIfProductHasTrialDays(
                            newLicense,
                            selectedPeriod
                        ) ||
                        !getIfProductHasTrialDays(
                            currentLicense,
                            selectedPeriod
                        ) ||
                        licenseIsIncomplete ? (
                            <LightPanel className={styles.billSummary}>
                                <p
                                    className={cx(
                                        'bodyBoldXS neutral1000',
                                        styles.billSummary__title
                                    )}
                                >
                                    {t('subscription.billSummary')}
                                </p>
                                <div>
                                    <p
                                        className={cx(
                                            'bodyRegularXS neutral1000 marginTop10',
                                            styles.billSummary__label
                                        )}
                                    >
                                        {t('subscription.subscriptionBill', {
                                            type: newLicense?.name,
                                        })}
                                    </p>
                                    <p
                                        className={cx(
                                            'bodyBoldSM neutral1000 marginTop15',
                                            styles.billSummary__price
                                        )}
                                    >
                                        {getPrice(
                                            newLicense?.prices,
                                            selectedPeriod
                                        )
                                            ? getPrice(
                                                  newLicense?.prices,
                                                  selectedPeriod
                                              ) +
                                              '€ / ' +
                                              t(
                                                  'subscription.' +
                                                      selectedPeriod
                                              )
                                            : t('subscription.customPricing')}
                                    </p>
                                </div>

                                {/* This will be util when we show the amount of the current license period not used */}
                                {/* {!!alreadyPayed &&
                            alreadyPayed > 0 &&
                            !currentSubscriptionPeriodIsYear ? (
                                <div>
                                    <p className={styles.billSummary__label}>
                                        {t('subscription.alreadyPayed')}
                                    </p>
                                    <p className={styles.billSummary__data}>
                                        {alreadyPayed / 100} EUR
                                    </p>
                                </div>
                            ) : null} */}

                                {/* <div>
                                <p className={styles.billSummary__label}>
                                    {t('subscription.totalDue')}
                                </p>
                                <p className={styles.billSummary__price}>
                                    {
                                        getPrice(newLicense?.prices, switchPeriodValue)
                                        // -
                                        //     (!!alreadyPayed && alreadyPayed > 0
                                        //         ? alreadyPayed / 100
                                        //         : 0)}
                                    }
                                    {' /'} {t('subscription.' + selectedPeriod)}
                                    <Description
                                        className={styles.totalDueDisscount}
                                        text={
                                            '* ' + t('subscription.disscount')
                                        }
                                    />
                                </p>
                            </div> */}

                                {!isNewUser ? (
                                    <div className={styles.billSummary__info}>
                                        {isUpgrade &&
                                            getIfProductHasAmount(
                                                newLicense,
                                                selectedPeriod
                                            ) && (
                                                <p
                                                    className={cx(
                                                        'bodyRegularXS neutral700',
                                                        styles.billSummary__label
                                                    )}
                                                >
                                                    *{' '}
                                                    <Trans
                                                        defaults={
                                                            'subscription.disscount'
                                                        }
                                                        components={{
                                                            bold: <strong />,
                                                        }}
                                                    />
                                                </p>
                                            )}

                                        {getIfProductHasAmount(
                                            newLicense,
                                            selectedPeriod
                                        ) ? (
                                            <p
                                                className={cx(
                                                    'bodyRegularXS neutral700',
                                                    styles.billSummary__label
                                                )}
                                            >
                                                *{' '}
                                                <Trans
                                                    defaults={
                                                        isUpgrade
                                                            ? 'subscription.newSubscriptionStarts'
                                                            : 'subscription.yourNewSubscriptionDate'
                                                    }
                                                    values={{
                                                        date: newLicenseDate,
                                                    }}
                                                />
                                            </p>
                                        ) : null}
                                        {selectedPeriod === 'year' && (
                                            <p
                                                className={cx(
                                                    'bodyRegularXS neutral700',
                                                    styles.billSummary__label
                                                )}
                                            >
                                                <Trans
                                                    i18nKey={
                                                        'subscription.yearlyWarning'
                                                    }
                                                />
                                            </p>
                                        )}
                                    </div>
                                ) : getIfProductHasAmount(
                                      newLicense,
                                      selectedPeriod
                                  ) ? (
                                    <div>
                                        <p
                                            className={
                                                styles.billSummary__label
                                            }
                                        >
                                            {t('subscription.totalDue')}
                                        </p>
                                        <p
                                            className={
                                                styles.billSummary__price
                                            }
                                        >
                                            {getPrice(
                                                newLicense?.prices,
                                                selectedPeriod
                                            ) + '€'}
                                        </p>
                                    </div>
                                ) : null}

                                <DarkButton
                                    className={styles.billSummary__continue}
                                    text={
                                        getIfProductHasAmount(
                                            newLicense,
                                            selectedPeriod
                                        )
                                            ? currentStep?.order === 1 ||
                                              currentStep?.order === 2
                                                ? 'subscription.continue'
                                                : 'public.subscribe'
                                            : 'subscription.contactUs'
                                    }
                                    disabled={
                                        !currentLicense ||
                                        !newLicense ||
                                        !nextStepActive
                                    }
                                    functionality={() => {
                                        !getIfProductHasTrialDays(
                                            newLicense,
                                            selectedPeriod
                                        ) ||
                                        !!subscriptionInfo?.subscriptionStatus
                                            ? getIfProductHasAmount(
                                                  newLicense,
                                                  selectedPeriod
                                              )
                                                ? isLowerThanCurrent(
                                                      newLicense
                                                  ) &&
                                                  currentStep?.order === 1 &&
                                                  !isDefaultBrandActive()
                                                    ? setShowDowngradeInfoModal(
                                                          true
                                                      )
                                                    : goToNextStep()
                                                : window?.open(
                                                      brandLabels?.contactlink,
                                                      '_blank'
                                                  )
                                            : selectStarter()
                                    }}
                                />
                            </LightPanel>
                        ) : (
                            <LightPanel>
                                <p className={styles.billSummary__title}>
                                    {t('subscription.freeSubscription')}
                                </p>
                                <div>
                                    <p className={styles.billSummary__label}>
                                        <Trans
                                            i18nKey={
                                                'subscription.notPaymentInfoNeeded'
                                            }
                                            values={{
                                                brand: brandLabels?.brand,
                                            }}
                                        />
                                    </p>
                                </div>
                                <DarkButton
                                    className={styles.billSummary__continue}
                                    text={
                                        currentStep?.order === 1
                                            ? 'subscription.continue'
                                            : currentStep?.order === 2
                                            ? 'public.saveContinue'
                                            : 'public.subscribe'
                                    }
                                    disabled={
                                        !currentLicense ||
                                        !newLicense ||
                                        !nextStepActive
                                    }
                                    functionality={() => {
                                        !getIfProductHasTrialDays(
                                            newLicense,
                                            selectedPeriod
                                        ) ||
                                        (!!subscriptionInfo?.subscriptionStatus &&
                                            newIsLowerThanCurrent &&
                                            !isDefaultBrandActive())
                                            ? setShowDowngradeInfoModal(true)
                                            : selectStarter()
                                    }}
                                />
                            </LightPanel>
                        )}
                    </div>
                </div>
            ) : (
                <ErrorFilledPanel
                    title={'public.noPermissionWarning'}
                    label={''}
                />
            )}
        </React.Fragment>
    )
}

ChangeSubscription.defaultProps = {}

export default ChangeSubscription
