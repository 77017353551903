import * as React from 'react'
import cx from 'classnames'
import styles from './cardLeftColumn.module.scss'
import { t } from 'i18next'
import {
    TooltipArrowPosition,
    TooltipPosition,
} from '../../../../../../../../../data/globalVar'
import GaHoverTooltip from '../../../../../../../../../Components/molecules/tooltips/GaHoverTooltip/GaHoverTooltip'

export type ICardLeftColumnProps = {
    text: string
    information?: string
    extraTranslationParams?: Object
}

export const CardLeftColumn: React.FC<ICardLeftColumnProps> = (props) => {
    const { text, information, extraTranslationParams } = props
    return (
        <div key={0} className={styles?.card__row}>
            <div>
                {!information ? (
                    <p className={`${cx('bodyRegularSM neutral1000')}`}>
                        {t(text)}
                    </p>
                ) : (
                    <GaHoverTooltip
                        position={TooltipPosition.right}
                        positionArrowDirection={TooltipArrowPosition.center}
                        label={
                            <p className={`${cx('bodyRegularSM neutral1000')}`}>
                                {t(text)}
                            </p>
                        }
                        info={information || ''}
                    />
                )}
            </div>
        </div>
    )
}

export default CardLeftColumn
