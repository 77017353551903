import React, { Suspense, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { scafoldingActions, scafoldingSelectors } from '..'
import cx from 'classnames'
import {
    ActionStatusTypes,
    brandColors,
    brandImages,
    brandLabels,
    brandPaymentMethod,
    gaColors,
    gaImages,
} from '../../../../assets/globalConstants'
import GaImage from '../../../../Components/atoms/Image/Image'
import BurgerMenuButton from '../../../../Components/BurgerMenuButton/BurgerMenuButton'
import DropdownMenu from '../../../../Components/DropdownMenu/DropdownMenu'
import LimitReachedModal from '../../../../Components/molecules/modals/limitReachedModal/LimitReachedModal'
import ErrorFilledPanel from '../../../../Components/molecules/notification/panels/error/errorFilledPanel/ErrorFilledPanel'
import { homeSelectors } from '../../../studio/Home'
import {
    hideScanNavigation,
    setShowAgeVerificationModal,
} from '../../../studio/Home/homeActions'
import SandboxModal from '../../../studio/Home/HomeComponents/sandboxModal/SandBoxModal'
import { licensesSelectors } from '../../licenses'
import {
    cancelDowngradeAction,
    getSubscriptionInfoAction,
    preselectLicenseAction,
    setCurrentStepAction,
    setNewLicenseAction,
    setReachedLimitsAction,
} from '../../licenses/licensesActions'
import { screenLoaderActive } from '../../Loader/loaderSelectors'
import {
    default as Loader,
    default as ScreenLoader,
} from '../../Loader/screenLoader/ScreenLoader'
import { sessionActions, sessionSelectors } from '../../session'
import { setSelectedRoute, setSettings } from '../scafoldingActions'
import TenantModal from '../tenantModal/TenantModal'
// @ts-ignore
import PanelStyles from './PanelScafoldingPrivate.module.scss'
import ChevronLeftIcon from '../../../../assets/iconsLibrary/simple/chevronLeft/chevronLeftIcon'
import {
    errorPanelReachedFeatures,
    helpMenuExtraMobile,
    helpMenuExtraOnPremiseMobile,
    settingRoutes,
    sideMenu,
    sideMenuExtraMobile,
    sideMenuExtraOnPremiseMobile,
    sideMenuOnPremiseSettings,
    sideMenuSettings,
    sideOnPremiseMenu,
    walletRoutes,
    walletSideMenu,
} from './panelScafoldingPrivateConstants'
import { formatStringDate } from '../../../../utils/formatDataUtil'
import localStorageService from '../../../../services/localStorageService'
import moment from 'moment'
import { userHasScopes } from '../../../../utils/permissionsUtil'
import { noPaymentMethod, onPremise } from '../../../../data/globalVar'
import ProfileIcon from '../../../../assets/iconsLibrary/profile/profileIcon'
import HelpIcon from '../../../../assets/iconsLibrary/help/helpIcon'
import Notification from '../../Notification/Notification'
import WarningPanel from '../../../../Components/molecules/notification/panels/warning/warningPanel'
import WarningFilledPanel from '../../../../Components/molecules/notification/panels/warning/warningFilledPanel/warningFilledPanel'
import OrganizationModal from '../../../studio/Home/HomeComponents/organizationModal/OrganizationModal'
import { getSubscriptionPaymentMethod } from '../../licenses/licensesSelectors'
import WarningModal from '../../../../Components/molecules/table/dynamicTable/warninModal/WarningModal'
import {
    deleteOrgAction,
    getOrgAction,
} from '../../organization/organizationActions'
import { showScreenLoaderAction } from '../../Loader/loaderActions'
import { getOrg } from '../../organization/organizationSelectors'
import { setSession } from '../../session/sessionActions'
import { setNotSigningUp } from '../../Login/loginAction'
import { subscriptionSteps } from '../../licenses/licenses.constants'
import ErrorPanelWithAction from '../../../../Components/molecules/notification/panels/error/errorPanelWithAction/ErrorPanelWithAction'
import { getPriceById } from '../../../../utils/licensesUtil'
import { isDefaultBrandActive } from '../../../../utils/brandThemeUtil'
import AgeVerification from '../../../studio/Home/HomeComponents/ageVerification/AgeVerification'
import { showAgeVerificationModal } from '../../../studio/Home/homeSelectors'
import BellIcon from '../../../../assets/iconsLibrary/bell/bellIcon'
import EWNotificationsModal from '../Components/EWNotificationsModal/EWNotificationsModal'
import {
    notificationActions,
    notificationsSelectors,
} from '../../../../store/notifications'
import { didsSelectors } from '../../../studio/Dids'
import {
    startNotificationsPolling,
    stopNotificationsPolling,
} from '../../../../store/notifications/notificationsActions'

type IPanelSProps = {}

const PanelScafoldingPrivate: React.FC<IPanelSProps> = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const pollingInterval = 1000
    const currentUrlRoute = location.pathname
    const supportLink = process.env.REACT_APP_SUPPORT_URL
    const activeRoute = useSelector(scafoldingSelectors.activeRoute)
    const isSettings = useSelector(scafoldingSelectors.showSettings)
    const subscriptionInfo = useSelector(licensesSelectors.getSubscriptionInfo)
    const showScanSandbox = useSelector(homeSelectors.displayScanNavigation)
    const showReachedLimitsModal = useSelector(
        licensesSelectors.areReachedLimits
    )
    const showTenantsModal = useSelector(sessionSelectors.getShowTenantModal)
    const showScreenLoader = useSelector(screenLoaderActive)
    const userTenants = useSelector(sessionSelectors?.getTenants)
    const excededLimitsState = useSelector(licensesSelectors.excededLimits)
    const uRole = useSelector(sessionSelectors.getUserRole_s)
    const orgId = useSelector(sessionSelectors.getUserOrganization)
    const organizationId = useSelector(getOrg)?.id
    const tokenOrg = useSelector(sessionSelectors.getUserOrganization)
    const userLicense = useSelector(sessionSelectors.getLicenses)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const subscriptionPaymentMethod = useSelector(getSubscriptionPaymentMethod)
    const tiers = useSelector(licensesSelectors.getProducts)
    const preselectLicense = useSelector(
        licensesSelectors?.getPreselectedLicense
    )
    const isWalletActive = useSelector(scafoldingSelectors.isWalletActive)
    const showVerAgeModal = useSelector(showAgeVerificationModal)
    const getNotificationsStatus = useSelector(
        notificationsSelectors.getNotificationListStatus
    )
    const ewNotificationsList = useSelector(
        notificationsSelectors.notificationsList
    )
    const newNotifications = useSelector(
        notificationsSelectors.theresNewNotifications
    )
    const EWSelectedDid = useSelector(didsSelectors.getEWDid)

    const onCloudSideMenu = !isWalletActive ? sideMenu : walletSideMenu
    const sideMenuList = !onPremise ? onCloudSideMenu : sideOnPremiseMenu
    const settingsMenuList = !onPremise
        ? sideMenuSettings
        : sideMenuOnPremiseSettings
    const sideMenuExtraMobileList = !onPremise
        ? sideMenuExtraMobile
        : sideMenuExtraOnPremiseMobile
    const helpMenuExtraMobileList = !onPremise
        ? helpMenuExtraMobile
        : helpMenuExtraOnPremiseMobile
    const userIsOrgOwner = uRole === 'OrganizationOwner'
    const starterProduct = tiers?.find((tier) => {
        return tier?.name?.toLowerCase()?.includes('Starter')
    })
    const trialStarterDate = subscriptionInfo?.currentPeriodStart
    const showFillOrgDataPanel =
        !tokenOrg?.length &&
        subscriptionInfo?.subscriptionStatus !== 'incomplete' &&
        preselectLicense === 'Starter'
    const showCompleteOrgDataPanel =
        noPaymentMethod &&
        tokenOrg?.length &&
        userIsOrgOwner &&
        localStorageService.getLocalStorageData('SubR') === 'Y' &&
        subscriptionInfo?.subscriptionStatus === 'incomplete' &&
        localStorageService.getLocalStorageData('UpO') !== 'Y' &&
        preselectLicense !== 'Starter'
    const showActivatingSubPanel =
        noPaymentMethod &&
        tokenOrg?.length &&
        localStorageService.getLocalStorageData('SubR') === 'Y' &&
        localStorageService.getLocalStorageData('UpO') === 'Y' &&
        subscriptionInfo?.subscriptionStatus === 'incomplete' &&
        preselectLicense !== 'Starter'
    const newLicenseDate = formatStringDate(
        subscriptionInfo?.currentPeriodEnd,
        'DD/MM/YYYY'
    )
    const routeIsCreateAppInt = currentUrlRoute === '/create-app-integration'

    const [expanded, setExpanded] = useState([''])
    const pathname = window?.location?.pathname
    const formattedPathName = pathname?.replace('/', '')
    const currentRouteIsFromSettings =
        !!settingRoutes?.includes(formattedPathName)
    const currentRouteIsFromWallet = !!walletRoutes?.includes(formattedPathName)

    const [showTrialModal, setShowTrialModal] = useState(
        subscriptionInfo?.subscriptionStatus?.includes('trialing')
    )
    const [showTrialPaymentsModal, setShowTrialPaymentsModal] = useState(
        subscriptionInfo?.subscriptionStatus?.includes('trialing')
    )
    // const requestSubStatus = useSelector(
    //     licensesSelectors.getRequestSubscritptionStatus
    // )

    // SET THIS A TRUE BY DEFAULT
    const [showHeaderAndNav, setShowHeaderAndNav] = useState(
        // true
        currentUrlRoute !== '/change-subscription' && !routeIsCreateAppInt
    )

    const [showOrganizationDetailsModal, setShowOrganizationModal] =
        useState(false)

    const showOrganizationModal = () => setShowOrganizationModal(true)
    const hideOrganizationModal = () => setShowOrganizationModal(false)

    const [showSureGoBackStudioModal, setSureGoBackStudioModal] =
        useState(false)

    const showGoBackStudioModal = () => setSureGoBackStudioModal(true)
    const hideGoBackStudioModal = () => setSureGoBackStudioModal(false)

    const [showNotificationsModal, setShowNotificationsModal] = useState(false)
    const thereIsDue = subscriptionInfo?.subscriptionStatus === 'past_due'
    const userPaymnetByStripe =
        subscriptionInfo?.currentPriceId &&
        !subscriptionInfo?.paymentOutsideStripe &&
        !noPaymentMethod
    const isNewUser = !!(
        availableScopes &&
        userHasScopes(['createOrganization'], availableScopes)
    )

    const selectStarter = (license?) => {
        starterProduct && dispatch(preselectLicenseAction('Starter'))
        starterProduct && dispatch(setNewLicenseAction(starterProduct))
        goBackHome()
    }

    const goBackHome = () => {
        hideGoBackStudioModal()
        dispatch(setSelectedRoute('home'))
        history?.push('/home')
    }

    const goBackStudio = () => {
        dispatch(getSubscriptionInfoAction())
        dispatch(setSession())
        dispatch(setNotSigningUp())
        dispatch(setCurrentStepAction(subscriptionSteps[0]))
        goBackHome()
    }

    const goBackStudioFromGatacaOnboarding = () => {
        goBackHome()
    }

    useEffect(() => {}, [preselectLicense, subscriptionInfo, isWalletActive])

    useEffect(() => {}, [
        showScreenLoader,
        showTenantsModal,
        showReachedLimitsModal,
    ])

    useEffect(() => {
        setShowHeaderAndNav(
            currentUrlRoute !== '/change-subscription' && !routeIsCreateAppInt
        )
    }, [dispatch, currentUrlRoute])

    useEffect(() => {
        setShowTrialModal(
            !!subscriptionInfo?.subscriptionStatus?.includes('trialing') &&
                !subscriptionPaymentMethod?.id?.length
        )
        setShowTrialPaymentsModal(
            !!subscriptionInfo?.subscriptionStatus?.includes('trialing') &&
                !subscriptionPaymentMethod?.id?.length &&
                areSevenDaysBeforeTrialExpires()
        )
    }, [
        subscriptionInfo.paymentMethod,
        subscriptionInfo?.subscriptionStatus,
        subscriptionInfo?.subscriptionNextPaymentAttempt,
    ])

    useEffect(() => {
        isWalletActive ? navigate('wallet') : navigate('home')
    }, [isWalletActive])

    useEffect(() => {}, [ewNotificationsList])

    useEffect(() => {
        // This will set the active route with user navigates using back or next browser buttons
        dispatch(scafoldingActions.setSelectedRoute(formattedPathName))
    }, [pathname])

    function navigate(url) {
        setActiveRoute(url)
        history.push(url)
    }

    const setActiveRoute = (route: string) => {
        dispatch(setSelectedRoute(route))
    }

    const closeFirstModal = () => {
        setShowTrialModal(false)
        localStorageService.setLocalStorageData('TMClosed', 'true')
        localStorageService.setLocalStorageData('TMSeen', 'true')
    }

    const closeNotificationsModal = () => {
        setShowNotificationsModal(false)
        dispatch(notificationActions.clearNotificationsList())
    }

    const openNotificationsModal = () => {
        setShowNotificationsModal(true)
    }

    const areSevenDaysBeforeTrialExpires = () => {
        var expirationDate = subscriptionInfo?.currentPeriodEnd
            ? moment(subscriptionInfo?.currentPeriodEnd)
            : undefined
        const expirationDate7daysBefore =
            expirationDate && moment(expirationDate)?.subtract(7, 'd')
        const todayDate = moment()
        const daysUntilExpirationDate = expirationDate7daysBefore?.diff(
            todayDate,
            'days'
        )

        return (
            (daysUntilExpirationDate && daysUntilExpirationDate < 8) ||
            daysUntilExpirationDate === 0
        )
    }

    const deleteOrg = () => {
        !onPremise &&
            (!!tokenOrg || orgId?.length) &&
            (dispatch(showScreenLoaderAction()),
            dispatch(deleteOrgAction(tokenOrg || orgId || '')))
    }

    const cancelDowngrade = () => {
        dispatch(showScreenLoaderAction())
        !onPremise && dispatch(cancelDowngradeAction())
    }

    const navigateBilling = () => {
        dispatch(setSettings('billing'))
        dispatch(setSelectedRoute('billing')), history.push('billing')
    }

    const navigateSubscription = () => {
        dispatch(setSettings('subscription'))
        dispatch(setSelectedRoute('subscription')), history.push('subscription')
    }

    const hideScanModal = () => {
        dispatch(hideScanNavigation())
    }

    const hideTenantsModal = () => {
        dispatch(sessionActions?.hideTenantModal())
    }

    const hideReachedLimitsModal = () => {
        dispatch(setReachedLimitsAction(''))
    }

    const trialModalNotClosed = () => {
        const trialModalClosed =
            localStorageService.getLocalStorageData('TMClosed')
        return trialModalClosed !== 'true'
    }

    const trialModalSeen = () => {
        const trialModalSeen = localStorageService.getLocalStorageData('TMSeen')
        return trialModalSeen === 'true'
    }

    const startNotificationPolling = () => {
        const fetch = () =>
            dispatch(
                notificationActions.getUnreadNotifications(EWSelectedDid || '')
            )
        dispatch(startNotificationsPolling(pollingInterval, fetch))
    }

    const hasBillingScope = !!(
        availableScopes &&
        userHasScopes(['manageSubscriptions'], availableScopes)
    )
    const showGoToBillingButton =
        hasBillingScope && userPaymnetByStripe && userIsOrgOwner

    const currentLicenseType = tiers?.find(
        (tier) => tier.id === subscriptionInfo?.currentTierId
    )

    const currentPrice = getPriceById(
        currentLicenseType?.prices,
        subscriptionInfo?.currentPriceId
    )

    const currentPriceHasTrialDays = !!currentPrice?.numTrialDays

    useEffect(() => {
        // This changes the if settings menu has to be showed when user navigates
        dispatch(
            scafoldingActions.setSettings(
                !currentRouteIsFromSettings ? undefined : true
            )
        )
        if (
            !currentRouteIsFromSettings &&
            isWalletActive === currentRouteIsFromWallet
        ) {
            dispatch(
                scafoldingActions.setWalletActive(!!currentRouteIsFromWallet)
            )
        }
        if (
            currentRouteIsFromWallet &&
            getNotificationsStatus !== ActionStatusTypes.loading
        ) {
            EWSelectedDid && startNotificationPolling()
        } else if (!currentRouteIsFromWallet) {
            dispatch(stopNotificationsPolling())
        }
    }, [activeRoute, currentRouteIsFromWallet, isWalletActive, EWSelectedDid])

    return (
        <Suspense fallback={<Loader />}>
            <>
                {showHeaderAndNav ? (
                    <header
                        id={'topOfTheWiew'}
                        aria-label="Studio"
                        className={PanelStyles.topbar}
                    >
                        <BurgerMenuButton
                            menuOptions={
                                isSettings ? settingsMenuList : sideMenuList
                            }
                            expanded={expanded}
                            selected={activeRoute}
                            setExpanded={setExpanded}
                        />

                        <Link
                            className={PanelStyles.logoCont}
                            to={isWalletActive ? '/wallet' : '/home'}
                            onClick={() => (
                                dispatch(
                                    setSelectedRoute(
                                        isWalletActive ? 'wallet' : 'home'
                                    )
                                ),
                                dispatch(setSettings())
                            )}
                        >
                            <GaImage image={brandImages.logoPlatform} />
                        </Link>

                        <div className={PanelStyles.rightSpace}>
                            <DropdownMenu
                                menuOptions={helpMenuExtraMobileList()}
                                icon={gaImages.helpCircle}
                                iconComponent={() => (
                                    <HelpIcon
                                        size={24}
                                        color={brandColors.neutral1000}
                                    />
                                )}
                                iconClassName={PanelStyles.user__image}
                            ></DropdownMenu>
                            {currentRouteIsFromWallet ? (
                                <div className={PanelStyles.notifications}>
                                    <div
                                        onClick={() =>
                                            !showNotificationsModal
                                                ? openNotificationsModal()
                                                : closeNotificationsModal()
                                        }
                                    >
                                        <BellIcon
                                            style={
                                                ewNotificationsList?.length &&
                                                newNotifications
                                                    ? 'active'
                                                    : 'default'
                                            }
                                            size={24}
                                            color={gaColors.neutral1000}
                                        />
                                    </div>
                                    {showNotificationsModal ? (
                                        <EWNotificationsModal
                                            hideModal={closeNotificationsModal}
                                            notifications={ewNotificationsList}
                                            EWSelectedDid={EWSelectedDid}
                                            loadingNotifications={
                                                getNotificationsStatus ===
                                                ActionStatusTypes.loading
                                            }
                                        />
                                    ) : null}
                                </div>
                            ) : null}
                            <DropdownMenu
                                menuOptions={sideMenuExtraMobileList()}
                                icon={gaImages.settingsIcon}
                                iconComponent={() => (
                                    <ProfileIcon
                                        size={32}
                                        style="filled"
                                        color={brandColors.primaryColor}
                                    />
                                )}
                                iconClassName={PanelStyles.user__image}
                            ></DropdownMenu>
                        </div>
                    </header>
                ) : !routeIsCreateAppInt ? (
                    <div className={PanelStyles.topLink}>
                        <span
                            onClick={() =>
                                orgId
                                    ? navigateSubscription()
                                    : showGoBackStudioModal()
                            }
                        >
                            {/* <Link to={orgId ? 'subscription' : 'home'}> */}
                            <ChevronLeftIcon size={24} />{' '}
                            {t('public.goBackStudio')}
                            {/* </Link> */}
                        </span>
                    </div>
                ) : null}
                <div>
                    <section
                        className={cx(
                            PanelStyles.panelContainer,
                            routeIsCreateAppInt &&
                                PanelStyles.panelContainerWithoutMargin
                        )}
                    >
                        <Notification className={PanelStyles.extraPanel} />
                        {(orgId?.length ||
                            organizationId?.length ||
                            subscriptionInfo?.orgId?.length) &&
                        subscriptionInfo?.currentTierId &&
                        userIsOrgOwner &&
                        currentUrlRoute !== '/subscription' &&
                        !!subscriptionInfo?.cancelAtPeriodEnd ? (
                            <>
                                <ErrorPanelWithAction
                                    classname="marginTop15"
                                    title={t(
                                        'subscription.subscriptionCanceledTitle',
                                        {
                                            date: newLicenseDate,
                                        }
                                    )}
                                    label={t(
                                        'subscription.subscriptionCanceledLabel1',
                                        {
                                            date: newLicenseDate,
                                        }
                                    )}
                                    label2={
                                        userIsOrgOwner && userPaymnetByStripe
                                            ? 'subscription.havechangeMind'
                                            : ''
                                    }
                                    label3={
                                        userIsOrgOwner && userPaymnetByStripe
                                            ? t('subscription.stillTimeKeep', {
                                                  date: newLicenseDate,
                                              })
                                            : ''
                                    }
                                    extraTranslationParams={{
                                        date: '01/02/23',
                                    }}
                                    hasIcon={true}
                                    button={
                                        userIsOrgOwner && userPaymnetByStripe
                                            ? {
                                                  label: 'subscription.keep',
                                                  function: cancelDowngrade,
                                              }
                                            : undefined
                                    }
                                />
                            </>
                        ) : null}
                        {(orgId?.length ||
                            organizationId?.length ||
                            subscriptionInfo?.orgId?.length) &&
                        !noPaymentMethod &&
                        subscriptionInfo?.currentTierId &&
                        (!subscriptionInfo?.paymentMethod?.id ||
                            subscriptionInfo?.subscriptionStatus ===
                                'incomplete') &&
                        preselectLicense !== 'Starter' &&
                        !currentPriceHasTrialDays &&
                        userPaymnetByStripe &&
                        currentUrlRoute !== '/change-subscription' ? (
                            <ErrorFilledPanel
                                hasIcon={true}
                                className={`${PanelStyles.errorPanel} ${PanelStyles.extraPanel}`}
                                title={'subscription.missingPaymentMethod'}
                                label={t(
                                    'subscription.missingPaymentMethodDesc',
                                    {
                                        date: formatStringDate(
                                            subscriptionInfo?.subscriptionNextPaymentAttempt ||
                                                ''
                                        ),
                                    }
                                )}
                                hasTitle={true}
                                rightButton={
                                    (userIsOrgOwner || isNewUser) &&
                                    currentUrlRoute !== '/billing'
                                        ? {
                                              label: 'subscription.solveProblem',
                                              function: navigateBilling,
                                          }
                                        : undefined
                                }
                            />
                        ) : null}
                        {(orgId?.length || subscriptionInfo?.orgId?.length) &&
                        !noPaymentMethod &&
                        !subscriptionInfo?.paymentMethod?.id &&
                        subscriptionInfo?.subscriptionStatus === 'expired' &&
                        userIsOrgOwner ? (
                            <ErrorFilledPanel
                                hasIcon={true}
                                className={`${PanelStyles.errorPanel} ${PanelStyles.extraPanel}`}
                                title={'subscription.needToDeleteAccount'}
                                label={t(
                                    'subscription.needToDeleteAccountDesc',
                                    {
                                        date: formatStringDate(
                                            subscriptionInfo?.subscriptionNextPaymentAttempt ||
                                                ''
                                        ),
                                    }
                                )}
                                hasTitle={true}
                                rightButton={
                                    userIsOrgOwner || isNewUser
                                        ? {
                                              label: 'general.deleteOrganization',
                                              function: deleteOrg,
                                          }
                                        : undefined
                                }
                            />
                        ) : null}
                        {showFillOrgDataPanel && showHeaderAndNav ? (
                            <WarningFilledPanel
                                hasIcon={true}
                                className={`${PanelStyles.errorPanel} ${PanelStyles.extraPanel}`}
                                title={'subscription.activateYourAccount'}
                                titleExtraParams={{
                                    brand: brandLabels?.brand,
                                }}
                                label={t('subscription.mustFillOrgDetails')}
                                hasTitle={true}
                                rightButton={
                                    currentUrlRoute !== '/organization'
                                        ? {
                                              label: 'subscription.activateAccount',
                                              function: showOrganizationModal,
                                          }
                                        : undefined
                                }
                            />
                        ) : null}

                        {showCompleteOrgDataPanel && showHeaderAndNav ? (
                            <WarningFilledPanel
                                hasIcon={true}
                                className={`${cx('marginTop12')} ${
                                    PanelStyles.errorPanel
                                } ${PanelStyles.extraPanel}`}
                                title={
                                    'requestSubscription.whileWaitActivation'
                                }
                                titleExtraParams={{
                                    brand: brandLabels?.brand,
                                }}
                                label={t(
                                    'requestSubscription.whileWaitActivationDesc'
                                )}
                                hasTitle={true}
                                rightButton={
                                    currentUrlRoute !== '/organization'
                                        ? {
                                              label: 'requestSubscription.fillInfo',
                                              function: () => (
                                                  dispatch(getOrgAction()),
                                                  showOrganizationModal()
                                              ),
                                          }
                                        : undefined
                                }
                            />
                        ) : null}
                        {showActivatingSubPanel && showHeaderAndNav ? (
                            <ErrorFilledPanel
                                hasIcon={true}
                                className={`${PanelStyles.errorPanel} ${PanelStyles.extraPanel}`}
                                title={'requestSubscription.providerActivating'}
                                titleExtraTranslationParams={{
                                    brand: brandLabels?.brand,
                                }}
                                label={t(
                                    'requestSubscription.providerActivatingDesc'
                                )}
                                hasTitle={true}
                            />
                        ) : null}

                        {/* // ESTA ES */}
                        {showTrialModal &&
                        !trialModalSeen() &&
                        trialModalNotClosed() &&
                        !areSevenDaysBeforeTrialExpires() &&
                        currentUrlRoute !== '/change-subscription' ? (
                            <ErrorFilledPanel
                                className={`${PanelStyles.errorPanel} ${PanelStyles.extraPanel}`}
                                title={'subscription.freeTrialActivated'}
                                label={
                                    !!isDefaultBrandActive
                                        ? t(
                                              'subscription.freeTrialActivatedInfo',
                                              {
                                                  date: formatStringDate(
                                                      subscriptionInfo?.subscriptionNextPaymentAttempt ||
                                                          ''
                                                  ),
                                              }
                                          )
                                        : ''
                                }
                                hasTitle={true}
                                rightButton={
                                    showGoToBillingButton &&
                                    currentUrlRoute?.replace('/', '') !==
                                        'billing'
                                        ? {
                                              label: 'subscription.solveProblem',
                                              function: navigateBilling,
                                          }
                                        : undefined
                                }
                                closeButton={{
                                    action: closeFirstModal,
                                }}
                            />
                        ) : null}

                        {showTrialPaymentsModal &&
                        currentUrlRoute !== '/change-subscription' &&
                        trialModalNotClosed() &&
                        trialModalSeen() ? (
                            <ErrorFilledPanel
                                className={`${PanelStyles.errorPanel} ${PanelStyles.extraPanel}`}
                                title={'subscription.trialBillingDetails'}
                                label={t(
                                    'subscription.trialBillingDetailsInfo',
                                    {
                                        date: formatStringDate(
                                            trialStarterDate || ''
                                        ),
                                        brand: brandLabels?.brand,
                                    }
                                )}
                                hasTitle={true}
                                rightButton={
                                    showGoToBillingButton &&
                                    currentUrlRoute?.replace('/', '') !==
                                        'billing'
                                        ? {
                                              label: 'subscription.solveProblem',
                                              function: navigateBilling,
                                          }
                                        : undefined
                                }
                                closeButton={
                                    !areSevenDaysBeforeTrialExpires()
                                        ? { action: closeFirstModal }
                                        : undefined
                                }
                            />
                        ) : null}

                        {thereIsDue &&
                        subscriptionInfo?.subscriptionNextPaymentAttempt ? (
                            <ErrorFilledPanel
                                className={`${PanelStyles.errorPanel} ${PanelStyles.extraPanel}`}
                                title={'subscription.newPaymentNotProcessed'}
                                titleExtraTranslationParams={{
                                    brand: brandLabels?.brand,
                                }}
                                label={t(
                                    'subscription.newPaymentNotProcessedInfo',
                                    {
                                        date: formatStringDate(
                                            subscriptionInfo?.subscriptionNextPaymentAttempt ||
                                                ''
                                        ),
                                    }
                                )}
                                hasTitle
                                rightButton={
                                    showGoToBillingButton &&
                                    currentUrlRoute?.replace('/', '') !==
                                        'billing'
                                        ? {
                                              label: 'subscription.solveProblem',
                                              function: navigateBilling,
                                          }
                                        : undefined
                                }
                            />
                        ) : null}
                        {excededLimitsState?.length &&
                        excededLimitsState?.includes(
                            errorPanelReachedFeatures[
                                currentUrlRoute?.replace('/', '')
                            ]
                        ) &&
                        !(
                            thereIsDue &&
                            subscriptionInfo?.subscriptionNextPaymentAttempt
                        ) ? (
                            <ErrorFilledPanel
                                className={`${PanelStyles.errorPanel} ${PanelStyles.extraPanel}`}
                                title={'subscription.limitReached'}
                                label={t(
                                    'subscription.limitExcededDescription',
                                    {
                                        feature: t(
                                            errorPanelReachedFeatures[
                                                currentUrlRoute?.replace(
                                                    '/',
                                                    ''
                                                )
                                            ]
                                        ),
                                    }
                                )}
                                rightButton={{
                                    label: 'subscription.contactUs',
                                    function: () => {
                                        window.open(supportLink, '_blank')
                                    },
                                }}
                                leftButton={{
                                    label: 'subscription.upgradeNow',
                                    function: navigateSubscription,
                                }}
                            />
                        ) : null}
                        <div>{props.children}</div>
                        {showScanSandbox && (
                            <SandboxModal
                                hideModal={hideScanModal}
                                show={showScanSandbox}
                            ></SandboxModal>
                        )}
                        {showVerAgeModal ? (
                            <AgeVerification
                                setShowAgeVerificationModal={(e) =>
                                    dispatch(setShowAgeVerificationModal(e))
                                }
                            />
                        ) : null}
                        {showTenantsModal && (
                            <TenantModal
                                hideModal={hideTenantsModal}
                                show={showTenantsModal}
                                licenseTenants={userTenants}
                            ></TenantModal>
                        )}
                        {!!showReachedLimitsModal && (
                            <LimitReachedModal
                                hideModal={hideReachedLimitsModal}
                                item={showReachedLimitsModal}
                            />
                        )}
                        {showOrganizationDetailsModal ? (
                            <OrganizationModal
                                onlyUpdate={!!(noPaymentMethod && tokenOrg)}
                                show={!!showOrganizationDetailsModal}
                                hideModal={hideOrganizationModal}
                            />
                        ) : null}
                        {showSureGoBackStudioModal ? (
                            <WarningModal
                                title={'subscription.sureGoBackStudio'}
                                description={
                                    !organizationId?.length
                                        ? isDefaultBrandActive()
                                            ? 'subscription.willNeedToMakeASelection'
                                            : 'subscription.youWillLoseSubInfo'
                                        : 'subscription.notAbleToUseStudio'
                                }
                                primaryButton={{
                                    label: 'subscription.yesGoBack',
                                    function: !organizationId?.length
                                        ? !isDefaultBrandActive()
                                            ? selectStarter
                                            : goBackStudioFromGatacaOnboarding
                                        : goBackStudio,
                                }}
                                secondaryButton={{
                                    label: 'public.cancel',
                                }}
                                showBg
                                hideModal={hideGoBackStudioModal}
                                item={undefined}
                                icon={gaImages?.redErrorPng}
                            />
                        ) : null}
                    </section>
                </div>

                {showScreenLoader && <ScreenLoader />}
            </>
        </Suspense>
    )
}

PanelScafoldingPrivate.defaultProps = {}

export default PanelScafoldingPrivate
