import React from 'react'
import CirclePercentageBar from '../../../../../Components/molecules/progress/CirclePercentageBar/CirclePercentageBar'
import styles from '../licenses.module.scss'
import { brandColors, gaColors } from '../../../../../assets/globalConstants'

type ILimitsStatsProps = {
    id: string
    items: {
        id?: string
        quantity: number
        total: number | string | undefined
        label: string
        unlimitedLabel: string
    }[]
    errorGettingItems?: boolean
    isOnHome?: boolean
}

const LimitsStats: React.FC<ILimitsStatsProps> = (props) => {
    const { id, items, errorGettingItems, isOnHome } = props

    const useAlertColor = (item) => {
        return (
            item?.total &&
            typeof item?.total === 'number' &&
            item?.total !== -1 &&
            item?.quantity &&
            item?.total < item?.quantity
        )
    }
    return (
        <div className={styles.currentSubscriptionPanel__limits}>
            {items.map((item, index) => (
                <CirclePercentageBar
                    key={id + index}
                    quantity={item?.quantity}
                    total={item?.total}
                    width={80}
                    arcWidth={4}
                    color={
                        useAlertColor(item)
                            ? gaColors.alert300
                            : brandColors?.primaryColor
                    }
                    label={item?.label}
                    unlimitedLabel={item?.unlimitedLabel}
                    hideTotal={!!errorGettingItems}
                    isOnHome={isOnHome}
                />
            ))}
        </div>
    )
}

export default LimitsStats
