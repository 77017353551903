export const contentSP = `
    connect-src 'self' 
    ${process.env.REACT_APP_CONNECT_HOST} 
    ${process.env.REACT_APP_GAAS_HOST}  
    ${process.env.REACT_APP_NUCLEUS_HOST}  
    ${process.env.REACT_APP_CERTIFY_HOST} 
    ${process.env.REACT_APP_EW_HOST} 
    ${process.env.REACT_APP_VOUCH_API} 
    ${process.env.REACT_APP_SANDBOX_URL} 
    ${process.env.REACT_APP_DATA_AGREEMENT_CONTEXT_URL}
    *.google-analytics.com 
    *.analytics.google.com
    https://checkout.stripe.com
    https://api.stripe.com
    https://maps.googleapis.com
    https://connect-js.stripe.com
    https://hooks.stripe.com
    data: ;

    default-src 'self' 
    https://js.stripe.com/v3 
    https://js.stripe.com/
    https://checkout.stripe.com
    https://api.stripe.com
    https://maps.googleapis.com
    https://connect-js.stripe.com
    https://hooks.stripe.com
    https://www.googletagmanager.com/gtag/js
    https://www.gstatic.com/recaptcha/
    https://www.google.com/recaptcha/
    data: ;

    img-src 'self' 
    https://*.stripe.com
    *.google-analytics.com 
    *.analytics.google.com 
    https: data:
    data: ;


    style-src 'self' 
    https://fonts.googleapis.com/ 
    https://fonts.gstatic.com/ 
    'sha256-0hAheEzaMe6uXIKV4EehS9pu1am1lj/KnnzrOYqckXk='
    'sha256-JRf3eZJIF59VkE1tlxlBPiCQXjGJ1uE88pEYh49RtTY=' 
    'sha256-47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=' 
    'sha256-QY/6GviYBv5xW4TJv99CE0/yFPOlK5QYD7l6G6rYg0Q=' 
    'sha256-bPWo4yY7PXjU4PhHpw+sPjdAYzsmoHVUjYpWYp/ntFs=' 
    'sha256-ygl0w3TF0FzzBYR6M5hRvv9dzliFkmMPWWSFj7zxy9c=' 
    'sha256-YR20lStMLhgtyPiAynM/rCv232Dnq2UuPW6lQvI+upQ=' 
    'sha256-NvDb/aYogKASZWkuVkRetzA8pQtHFY3X6vqWIYsb4oo=' 
    'sha256-HvDbDgZBVAmoBdYFjv4QGQzcshshaGFIiZgBsxfR+xY=' 
    'sha256-teoDRSz2raQIy3J8EMhqVwr7VY5pHiRM9NsSNFcwsLA=' 
    'sha256-wy7vbzKaUR8MctgmtzPLT4edPOBAU7GBgvfDcmuHV9w=' 
    'sha256-jGsqEyLeF5+ougOU0EXdqMBPGU9ESIn56Yd/Hys/WqU=' 
    'sha256-UgXehjY+ycGsoPZAS854KYejOGD1xgQ74anHlqr4zvY=' 
    'sha256-edui/olLUKamTBBvmvk/iG1IVHtuVYJNXhI0X5G28HU=' 
    'sha256-Fob04U+5QfTfqzlHZ9xo9Wp/2eXE/LKy43Qxgdbpoxg=' 
    'sha256-X7LRRWaKus/dZ5x2V2lvOLdynld+cCJ/6KBHOzwL0wY=' 
    'sha256-xiySKBEgYfR18M9K3XbfGWzVP1Iy+uwCCHTpu7kE2nU=' 
    'sha256-hZKTVcObjapFQLjidX8zYvsJQBN7Kct2v759YyNo2R8=' 
    'sha256-hg9T683oLEAo+gI0WMqJaOcVWCoyxtTwk/i+/dP2WYU=' 
    'sha256-SgyTEIBvpBLaQLDraV4nFDkco/nS1h/BEry/q0t118Q=' 
    'sha256-N4/kB3jGpqL8hEA2n+fsoJfntvcRSIqbygj6/U7m0C8=' 
    'sha256-14lIQ6vhG576+M+scXaYbi8X/1BbR99Lb6QPT93GiOs=' 
    'sha256-Ueyzk8OzRLVwya3d5e21+xDbvkGwLRLRBBdhGf6XZm8=' 
    'sha256-WW8yuUQy/fDmf06M7tMcF6ReSX44CaA+o4eqxcK7TjU=' 
    'sha256-95gmHZ6nLAShZOd6YzOGrX6UsjpDO+9V4RekKrB7KT0=' 
    'sha256-5uXup5Q5V4bOBznGWeOrHThpjMaJYnUi54MieDVN1qY=' 
    'sha256-unCW5qBLsseHG0ql+zBY7mbOJVI7OyZrOnPfcRupFMA=' 
    'sha256-f8q2/WOy/X/qBb6W6Gq7Q/qaI6FqE+2u0um7kUC/m+k=' 
    'sha256-3jCoU6o9e3JiPylFPLKblMe9ffG6ekQ/0HBWLzc1tNI=' 
    'sha256-BObOMfja2kF60TyMIFQtD+qyKKrdvwc8sDpoQdpy6Hw=' 
    'sha256-wnDq/cTpIbfb4CbRUMyrKLIsAGRoiYE7I42fYXjxq4c=' 
    'sha256-pNDDEs3siAgZi1nv8cOlD3+cTgDURf7v6gI/W5EMpKQ=' 
    'sha256-7wDLue3jjS4eoBJ8Ac5qgof5cOCOPwYACuNXL+B9VdA=' 
    'sha256-uJF5h4Ib7oR/eJlgUMs/XA/yhJuZQIrz2o6JZao+fqU=' 
    'sha256-Qp9FwywjjMDbHfPG/pUTw8Yb1gPF7T6d+uDzJgHSB2k=' 
    'sha256-YfRw3z69XpvLBPRWK2EY1+J3Uqz8/bO9oOL1WE5cqVs=' 
    'sha256-prvW6cUMOHoDNKvzVCMmm1gS+pOiHKhxX/0gDEQwkto=' 
    'sha256-tukIG0SfBUi6bvCB5rqdDVZXq4GmZQjcR6Ehgw4oosk=' 
    'sha256-cGbsLrbHyELAyMPHQ4fuYJe+SOIRJ6Ls6+5qwpBzJZI=' 
    'sha256-5WtFwC7FN4dXBf5xG/ipP4Vve2642bbrX9yQabLVRPE=' 
    'sha256-ryPtaIHa4pUFLw3jkv6ENDl7jAZooW4Um+l0GWBVcjw=' 
    'sha256-MZaUM8EL1HdXk7esq3ko4VciXdLeHIYKWUIyaO6YYqU=' 
    'sha256-xX0mo0fZCwkw5DAw5Dq34nCPRJoNpue6SDMO7KpXXR4=' 
    'sha256-kM96E0yacUZDxQhs6Ipg8MeI0Pp4QL/bFy0xO2ZSYEw=' 
    'sha256-pZM2eoTmsOdSWTxOIzFnVtzocp3uq1lVyDhJhpGRDJQ=' 
    'sha256-xQUF262PAjEhlf2oFsFOoJJ4w/c838T0CcnCw8zzFwg=' 
    'sha256-fqjUfWdkxnLfpDlsoMuemMGndfTprz8s7heE7DBEgUk=' 
    'sha256-RlOxgBbDGecy50FB0M2bF+k7BqYXklynS5eXExpkEDU=' 
    'sha256-RFEZXMtB7YEJKo072O9ypvnKk7+nfqULaX4LiV/y9YQ=' 
    'sha256-0kMbPhX3OdWlD6TvQntl8etQWXxD4X3rTTKr3ZJq5Jo=' 
    'sha256-sjvOtiUYTIqDOFjU4AdipiNJ/oQhxY3H/scjLYE1V2c=' 
    'sha256-hGZVgl3BVPGMKDQmWOd5lV1+RYmFuHb5867ySbEO0RY=' 
    'sha256-qmtqjrlR8DGoCrNIW82kJiHFDWZnjVgfPhUXJ+3S2w8=' 
    'sha256-K3/o8K60lJKIr1hq2jheUrA4QmEsn+6A3vp5BloP5CM=' 
    'sha256-nbFC8d4vfLH8ETvNKiX2HnCBQpn8sOJ8TWiwcRcPRz8=' 
    'sha256-rBGPdpAtPJYxdeoRYqVMWhryR3oO257ld4WJSZnl+S8=' 
    'sha256-y1lc0iorv+h/kFVXXPDlMjIE7HBSl0QEvJWJjIjqDxw='
    'sha256-Tq6Xzzi+chnlpLk0MOHcJdpVGxplxr1AitEizLl2A8M=' 
    'sha256-MTDOWbTwZYSVWonOmdrAwrW8n+jJqM5qEKvQBLZtv3A=' 
    'sha256-PWjVauwFOTOBWgYaD/e16Hg/W/KKPlwqQwDVK4eTmnw=' 
    'sha256-3ciieMcZ0HwR2cPPeLHlGdTcv81ixT2uWOM9EJftcXk=' 
    'sha256-yGrVER26DUx/AXBx771CuCM3Brz/Ir0UMc0fz6HfbCQ=' 
    'sha256-11lng0JxLXJDkmnUul4DnWCjGYrYbbyPV0QJzEWomDU=' 
    'sha256-KLeKQL7vnqs+QsPgrPoxybJKsJE87MO6TB4SerA2IQM=' 
    'sha256-SjTlLcugvuC86H3TD2r4sqmYWxygqnhs6tgIHDcG9HQ=' 
    'sha256-/JNW77YI4WansPOjEsjSbK5kgcIW4CIwLMhGdxjYK9c=' 
    'sha256-wIokVR97Zd81c+uL407IlwNaAcDu3GgXcVaSrVepPKo=' 
    'sha256-2OJeGitrLQUGuaKwRpk3UWZ3BoCnlbIzTyp1rm+YvPk=' 
    'sha256-QpzcZAJVN6h1n9B2wz5zYPdrjRp+HY4OM1oWSo/BC/8=' 
    'sha256-8KtI/XiwtYJxT3WlPOrCrNFdUoJlhVOB1/vNU0c6aNY=' 
    'sha256-1/nSeAOIMkZfIM6hUsq5dEOC+iFb4XtTWJxw76kdr98=' 
    'sha256-836ZfOGIGkA38dMk/jLBkV/koZ181XL0bFS7/tQm6Po=' 
    'sha256-m4z5kxi5oszHzx88RjD4b2RbPbPazcZGDq8qrmuUeJc=' 
    'sha256-7C12L+KHLeSOHhQaOIR3YmVSINFOreifgm1epp8TxuI=' 
    'sha256-OLPD6clY8ckyh01g1Ouyy5jlu6Q2itWPFrxW7B7VWMw=' 
    'sha256-Ho96CT8uhUAwX8b1I63RwxiApr2Jwf7hrVOY2EvCbto=' 
    'sha256-v3FdA1l8O9pt4ioL97cBxpv4YPK0S17DG9mw2mRd6nQ=' 
    'sha256-izfANmT2UJKA/ggQU7bArFZwXzr0rl+G3ThXkz72qMs=' 
    'sha256-+NspLvxo1aHugaxt1HQQHXfIY+KuPqRoMY6UG8VPr1g=' 
    'sha256-1b6KzC3YkyNQ8Fp3IUpLZufjSdFXIserHre5iYr96/E=' 
    'sha256-ZfVFEGduCLo115j8xdP2su7wOT5YSgxwW3/84K4tjmc=' 
    'sha256-Dgdb7uqUOeL9ehyLmsYyLNxD4g4QlgGJZFD2uX+CBsM=' 
    'sha256-xEsCgKG45RuQdSVQvmEtKam0l9KwhVR7qDP28DK4rxo=' 
    'sha256-SE1xk1NA3iAe38QykHFj/efbWd/FgkodgrQ898Pcm4M=' 
    'sha256-PugkwFnx+0GpSo5tngs6cJyZYNipy8HmIvQB1++UoTk=' 
    'sha256-Fmd24w/nFLgUw8AuCOYTJgPdCe1avOsU8WYRPIBorEY=' 
    'sha256-hxyeoxNzkc9NQX0tu75CfDZpXYFAmkSAt2ePTMZiXMU=' 
    'sha256-97n476uBnhVOFMHzREcrpWAQZckDMCu2X2gkrg1028s=' 
    'sha256-6xoO5S6tuiUSKV6BbdeHH32GXH/np6SN5o6OC2PFlS0=' 
    'sha256-7s0Pvv4NBFUcrKEccoOuq9HyKO6p3wfY5tVuf5d1VO8=' 
    'sha256-29wKSCPB8pNPxKLcKDzeONvRPRkCIK1PPu63GEx/7K8=' 
    'sha256-E+mcxPyvXOgAf1NfByf3rPg0XV5wSQO1fY2CmfHB90g=' 
    'sha256-0MdpaxR/z87zoDFzstVnSvWJc9LOeW+QaS9Dzc+rx+w=' 
    'sha256-4tasmO1d3NEm+5RdeW0GhvMeG7ezzCnAoBfclArxIC0=' 
    'sha256-2MZgWfzlqoLzlHaEBfy02jtkOmpC6mezSSxPdKFBdAE=' 
    'sha256-avVgXZkRebdvSrTv0KMJEqFUsfJCFlK0qxm8Q7wE16A=' 
    'sha256-rmCNUsNVfyfv33ok9jXi2EnyOvw8Fstn+cTc2Q80Vi4=' 
    'sha256-8QrccVLejJgl1jJshW7p700r+B45eLVsoDpIsBNj+CI=' 
    'sha256-pWqnXhsUB4zMqRCGs/KvBN/UVIw547eXn/mhB7PRxWQ=' 
    'sha256-ggk4HDm7lttaq26SxO2C3vATNgVVPOJtE3uNg1f/KnE=' 
    'sha256-t+J7FrVKjevza4bZSKy0CVe5PUgwgk5eXMmUb9aj4LU=' 
    'sha256-REJpbLgqi+J25pJvpqcKbKiXoULmQNV5B1a2o0wg5Xk=' 
    'sha256-OuSxkXrcei70S/MVvmuPnTbU932ZkIQBewmGrY1jxoQ=' 
    'sha256-VsT+9p72DMZkRKulyKIDo/D3z2cgiaAId3kMW7aSCbk=' 
    'sha256-VNJzJMcKjKTxKwKdBq9Zn0t0oGgNFtV78UBNcNMR294=' 
    'sha256-bpEXFFbf9c990MIYOAI/J8GN1+xd5zal0OJ36gt5eyU=' 
    'sha256-wXlxlItsr6NOU3gEX+LszIuQI8R6eyMuoIniXt5bRm4=' 
    'sha256-99BSnXhbluhvFQhDgN6zaYq47tOLQb4aiDRNzpASoYw=' 
    'sha256-oubgcU2M3D8gSGwF6Hm0K2SLDWwmbHajurZH38kqceM=' 
    'sha256-UKHF7/TLR7njdVd7xNk3RFvW1uvXU0JxcdEoqLl7azo=' 
    'sha256-KmnfzsSnKlWHiPlpE7EBPSEQxcSjqfdqVJ0MVKWxdMA=' 
    'sha256-/v6OIAb3CY7RG5/pZMSkXMSbShmeUFZvG56zhmnVjUs=' 
    'sha256-CKy1oX5VQH9jvaTyPB7J8hrQ1M3qLl5rEPhBrpycNPI=' 
    'sha256-uzXcW1XS7dfsOhBEXXGhjMSzfBQNERg/4pHZZW8PEHY=' 
    'sha256-J6BdTPDIszsz8GAaIzQcQxcy8EW4fABwsqegb3IQDP8=' 
    'sha256-L9Q/Be6XViaNQTE5GDqwmDWENc/iiOAsQn0Ixe5DD6U=' 
    'sha256-tuEVR4+ggNG9Kt7BpEbGPdGO3pxTg3U4tRJ7jOCSqMY=' 
    'sha256-OtJCJIISYllpQiSJM60zIiVXXXBoFXwZnqkxF3Bx/kQ=' 
    'sha256-5elnwv2p9ONvfCX6uWQiViNPKo0M0PoHZIQSPGT6dbw=' 
    'sha256-IxjKvzfKjP5d8sC+8Q6/ZNKrDcC2Bqr0Z+b8xfG9Sng=' 
    'sha256-XoTflZ/QEEPeRBeH4pqOXb87s2peCBHrxZzWgsWogNQ=' 
    'sha256-ITY/4tHxNufM5j8+KTFQ2IqSiMAJii3Dhk7vtAN5pbs=' 
    'sha256-Jdxq5nr1WXMi8H1i6Chov14ilKW1rp5l1D74UGvxzkE=' 
    'sha256-NfxwWZ/ytZowxMKG69lq5Ez7bs1W3N331/O37rszvE8=' 
    'sha256-fz217NeATppSaUPz+tnEs8e4tgIVDYKHaLYiXK1ySN4=' 
    'sha256-J2CQ0ra9U/s/lIkX1Cab9plV4PihzyKg8SYHl/HHYUk=' 
    'sha256-WzPki9v40wdU9KjyR2EkJnRF4jRqez4zGjkyUtxD8KE=' 
    'sha256-Ahe5qmhNJ7v8KYQ0bMzdwl9YfeomCHmO08v5NE2Xx1c=' 
    'sha256-8QVoM0o+rApZVVvrC+mmTBHir9Kr2+yAfgLhMrOUP5g=' 
    'sha256-KyBsg0QxGcWwNluPP7nzXKsjeR8S/XaB6juKeL4cDtc=' 
    'sha256-k1PItByU70A0Vqvdvt/XEVWhEGWlTan7fA0qpXCpZuc=' 
    'sha256-MaNKFGjMSxMQC9kcnbcZZFXesQxqnNO3lNThgXiD2X0=' 
    'sha256-FGGud4NiOY2EyEQDLplmUCrNJsNUw3jI9EFxpoTu3Ng=' 
    'sha256-o4qYcITVpLOmhZAwT6bqXmsBiGnepAiLpztZAS7PnF4=' 
    'sha256-jgrDhV9ovVD/k69hAjHWyYpv2bNk6fLQ6AWzysnInxs=' 
    'sha256-U1tP+mFP7MyCg9m/kwUg7G+ACXKtcH2SfSuFc+ej/gw=' 
    'sha256-xVMAKRyflhN0KoI+LdAg6WGUhAQbH5gX7u/Y/dfpcN8=' 
    'sha256-MkN2UwcsMRUitI20RuWugV0R7R1/gO22vPo3PTB63MQ=' 
    'sha256-u4TxMY0B/cY+Ortp9wRiufYsCc+LY0LaGnCzZBZRVuc=' 
    'sha256-mzM2QOdMAm/yFF38YakPgSTcftKU1NL9T6aupaZLO8s=' 
    'sha256-sKWwvpCrKv5muMKlDXOfYx+7aO4PhOT/450YpjZ1jRE=' 
    'sha256-Zm4oA6EOnIxr6WerguBN7AyxhRJfmvajcroAAyhQUh8=' 
    'sha256-Fu8ho4LKZiRPY0ZksTUPamsZYJnoEWBXHXqmN0EVz2c=' 
    'sha256-Rpgo/XUL9htiaMwyFtQ44YJEbymr24vGxuIaUc6EdSU=' 
    'sha256-Fnm0O0Bcyw9co82v3YabdNfxLm4UxPGGQlBusWI8+9Q=' 
    'sha256-vpFlyeZv8j3NSr9zW+OVST/8WnMMZJLiBb9+WLy8YII=' 
    'sha256-96DG+hcPmCCcEFKTamfjXglBN1SK1R0cgKS/zasSAd0=' 
    'sha256-Eyw0618kwBP962OZbhG7q1Pa+bTb91Y4NzM6JmDHERI=' 
    'sha256-AXo9jYd1Djo5n9lMWWgLzoSVFV/gTG2BzW1qtqnpAD0=' 
    'sha256-XBA1gjTi+FYJR38dGqXWSL0pXofJXzt2R9aQT3ztx78=' 
    'sha256-j9xe9poUQzdvNgrkFyv5W5X7ju46iFWDH3kHxYpRhcc=' 
    'sha256-Cri18iWcV0YDvXRuuhmBHxOIC90P+ggtIdFva7kvX2w=' 
    'sha256-K8Lg0Aaz9BV4dWH5YPrRSbBvBj7r75pQfHYXnsIfyZI=' 
    'sha256-sM9O5gT+QNZWqH/1tTCVzQINnm4FrsI35W5JCMAz3K0=' 
    'sha256-X0/hEo8QGZwNMVyaRtxzFJtViGwPet+8xOQBQTxvQk4=' 
    'sha256-FcMDtF/9R6kgs6kGRcfi/DFw+zc2pMkWqQReCkZyxMs=' 
    'sha256-NsxID2ivN42+2Tr8TSobpuYg11NvbO1w/kAH1quy+Dc=' 
    'sha256-2VYyQQWPftLIa3lx/Ynr55deOgx0V0DalgjbIToDCuU='
    'sha256-HFX4l8vDnyhOFiCWDQyKQUJbiXsrC6TvRwAPQKVh5a0='
    'sha256-wnsu7tumeQuArWj1iaDSr0UgT0NMc/7aDanYtF84KJo='
    'sha256-pLZ8/rg9rno/JInY73Qd6CgBs0JP2mdwpYfeA0AL1zM='
    'sha256-YGYivw9KKOP5Y1WVfWoh1iScdJcuzb06Dxgm5wXtNgU='
    'sha256-eWiLR02alr9ujoX+9/VD/w4h2Dwr3+92BY/jAwVyNqE='
    'sha256-QzXwYWb4wKpTwDztTbqo/zd1rgk0DcU3M4PZTvbiTRY='
    'sha256-3T/GEPdWhZsGo+zmXYyYDafhttCnVMchlrS7HXFoMUY='
    'sha256-QLpYPqLERlo4BnVXHtjLgnEzx1KEL018z6W2AUMbPZ8='
    'sha256-FYtkOW4jhpdPma1lP0hNStPYkOhPD5qWh188pWOKohQ='
    'sha256-aYuhPFn8WLvhuLXXCyNUA+Wic4lB1O9zQ9yYSbRoQ6k='
    'sha256-JjC8OrRNFLlc7pNHsJp1NlqIeFJVOBFodEKSoh+XGMI='
    'sha256-Sc82gAcoxwCnZhdXVJSNqnYOt/t2Auh/lU4v1EsQHXw='
    'sha256-5rrIXeow+E0YRjnuO61jqA79gJsuXMHBv2Fmex3/2OY='
    'sha256-EUhHbJRgSsb7KCZ0s9+RzhnnuqQTLH7XmEoAzR9uda0='
    'sha256-YpFQjUO7cKsP59mRyzM2zdxwqPIcnrkI/Lav0jT+9qo='
    'sha256-Q3Km+GnYTFmc2Ah9uvPgrp4SVhoSMQuV+MnB2cJaTDs='
    'sha256-HDCvAaUTnA/7UikdVdjHCQRV08+94a5lXEaw4Nstdls='
    'sha256-ijnDqScqvcJaTvW3hXIrerm9WkuPl0syIC+j5N1tT7c='
    'sha256-HFX4l8vDnyhOFiCWDQyKQUJbiXsrC6TvRwAPQKVh5a0='
    'sha256-wnsu7tumeQuArWj1iaDSr0UgT0NMc/7aDanYtF84KJo='
    'sha256-pLZ8/rg9rno/JInY73Qd6CgBs0JP2mdwpYfeA0AL1zM='
    'sha256-YGYivw9KKOP5Y1WVfWoh1iScdJcuzb06Dxgm5wXtNgU='
    'sha256-eWiLR02alr9ujoX+9/VD/w4h2Dwr3+92BY/jAwVyNqE='
    'sha256-QzXwYWb4wKpTwDztTbqo/zd1rgk0DcU3M4PZTvbiTRY='
    'sha256-3T/GEPdWhZsGo+zmXYyYDafhttCnVMchlrS7HXFoMUY='
    'sha256-QLpYPqLERlo4BnVXHtjLgnEzx1KEL018z6W2AUMbPZ8='
    'sha256-2VYyQQWPftLIa3lx/Ynr55deOgx0V0DalgjbIToDCuU='
    'sha256-fWMZWhOCcgTibzAvyPuhBp48OhJBLjI2e1MBzTauoI0='
    'sha256-VvguK34k3gka79MlGWU9vwxWpd1OaXxtDPI8MCikW5U=' ;

    font-src 'self' 
    https://fonts.googleapis.com/ 
    https://fonts.gstatic.com/;
`
