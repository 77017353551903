import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../../views/credentialsList/credentialsList.module.scss'
import BasicCard from '../../../../../Components/organisms/Cards/BasicCard'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import PermissionsPanel from '../../../../../Components/molecules/notification/panels/permissionsPanel/PermissionsPanel'
import {
    ICredentialsItemModel,
    IIssuanceRequestModel,
} from '../../../../../interfaces/interfaces'
import {
    getIsActiveSession,
    userHasScopes,
} from '../../../../../utils/permissionsUtil'
import { useDispatch, useSelector } from 'react-redux'
import { sessionSelectors } from '../../../../common/session'
import { issuancesScopes } from '../../../Issuance/issuance.constants'
import { getIssuances } from '../../../Issuance/issuanceActions'
import {
    getTotalPageItems,
    paginate,
} from '../../../../../utils/paginationUtil'
import TableSkeleton from '../../../../../Components/molecules/skeletons/tableSkeleton/TableSkeleton'
import {
    credentialsScopes,
    headersIssuedCredentialsTable,
} from '../../views/credentialsList/credentialsList.constants'
import WarningModal from '../../../../../Components/molecules/table/dynamicTable/warninModal/WarningModal'
import { CredentialsDataModal } from '../../views/dataModal/DataModal'
import { shortValue } from '../../../../../utils/formatDataUtil'
import CredentialsTable from '../../views/credentialsTable/CredentialsTable'
import {
    changeCredentialStatus,
    deleteCredential,
    getCredentials,
    getPendingCredentials,
    selectCredential,
} from '../../credentialsActions'
import { getCatalog } from '../../../../common/catalog/catalogActions'
import { getStatistics } from '../../../../common/Statistics/statisticsActions'
import { getLimitsValues } from '../../../../../utils/licensesUtil'
import { getCatalogTypes } from '../../../../common/catalog/catalogSelectors'
import { credentialsSelectors } from '../..'
import { statsSelectors } from '../../../../common/Statistics'
import { licensesSelectors } from '../../../../common/licenses'
import { onPremise } from '../../../../../data/globalVar'
import { defaultPageSize } from '../../../../../Components/Pagination/paginationConstants'

type ISearchState = {
    searched: string
    searchedIssuedResult: any
    searchedPendingResult: any
}

const credentialSelectedTab = {
    issued: 'issued',
    pending: 'pending',
}

export type IssuedCredsProps = {}

const IssuedCreds: React.FC<IssuedCredsProps> = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const currentLicense = useSelector(licensesSelectors.getCurrentProduct)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const hasReadScope = !!(
        availableScopes &&
        userHasScopes(credentialsScopes?.read, availableScopes)
    )
    const canReadIssuances = !!(
        availableScopes && userHasScopes(issuancesScopes?.read, availableScopes)
    )
    const usedFeatures = useSelector(statsSelectors.getUsedFeaturesNumber)
    const statisticsIsLoading = useSelector(statsSelectors.getStatisticsLoading)

    const isLoading = useSelector(credentialsSelectors.isLoading)
    const isIssuingCredential = useSelector(
        credentialsSelectors.isIssuingCredentialLoading
    )
    const licenseCustomLimits = useSelector(
        sessionSelectors.getLicenseCustomLimits
    )
    const credentials = useSelector(
        credentialsSelectors.getCredentials
    )?.credentials

    const issuedCredentialsData = useSelector(
        credentialsSelectors.getCredentials
    )?.data

    let selectedCredential = useSelector(
        credentialsSelectors.getSelectedCredential
    )
    const issuedCredentials = useSelector(
        credentialsSelectors.getIssuedCredentialsList
    )
    const pendingCredentials = useSelector(
        credentialsSelectors.getPendingCredentialsList
    )

    const credentialTypes = useSelector(getCatalogTypes)

    const [pageIndex, setPageIndex] = useState(1)
    const [totalItems, setTotalItems] = useState(
        issuedCredentialsData?.total || 0
    )

    const [paginatedIssuedCredentials, setPaginatedIssuedCredentials] =
        React.useState<ICredentialsItemModel[]>()
    const [paginatedPendingCredentials, setPaginatedPendingCredentials] =
        React.useState<ICredentialsItemModel[]>()

    const [creds, setCredentials] = React.useState<{
        issued: ICredentialsItemModel[] | undefined
        pending: ICredentialsItemModel[]
    }>()

    const initialPurposeListState = {
        searched: '',
        searchedIssuedResult: paginatedIssuedCredentials || [''],
        searchedPendingResult: paginatedPendingCredentials || [''],
    }

    const limitsAreReached =
        usedFeatures?.issuedCredentials ===
        getLimitsValues(currentLicense, licenseCustomLimits)?.issuedCredentials

    const [state, setState] = React.useState<ISearchState>(
        initialPurposeListState
    )
    const [pendingHover, setPendingHover] = React.useState(false)

    useEffect(() => {
        // Just in cloud for now
        if (!statisticsIsLoading && getIsActiveSession() && !onPremise) {
            dispatch(getStatistics())
        }
        hasReadScope &&
            (canReadIssuances && dispatch(getIssuances()),
            dispatch(getCatalog()),
            dispatch(getCredentials(1, defaultPageSize)),
            dispatch(getPendingCredentials(1, defaultPageSize)))
    }, [])

    useEffect(() => {}, [selectedCredential])
    useEffect(() => {}, [isIssuingCredential])

    useEffect(() => {
        setCredentials({
            issued: issuedCredentials,
            pending: pendingCredentials,
        })
        setState(initialPurposeListState)
    }, [
        credentials,
        pendingCredentials,
        defaultPageSize,
        pageIndex,
        credentialTypes,
    ])

    useEffect(() => {
        if (!!creds?.issued) {
            setPaginatedIssuedCredentials(
                paginate(creds?.issued, defaultPageSize, pageIndex)
            )
        }
        if (!!creds?.pending) {
            setPaginatedPendingCredentials(
                paginate(creds?.pending, defaultPageSize, pageIndex)
            )
        }
    }, [creds])

    useEffect(() => {
        if (!!creds) {
            setState(initialPurposeListState)
        }
    }, [paginatedIssuedCredentials, paginatedPendingCredentials])

    useEffect(() => {
        setTotalItems(issuedCredentialsData?.total || 0)
    }, [issuedCredentialsData])

    const handlePageChange = (page) => {
        dispatch(getCredentials(page, defaultPageSize))
        // : dispatch(getPendingCredentials(page, defaultPageSize))
        setPageIndex(page)

        /* { TODO: Uncomment when BE Search is available */
        // setPaginatedIssuedCredentials(
        //     paginate(creds?.issued, defaultPageSize, page)
        // )
        // setPaginatedPendingCredentials(
        //     paginate(creds?.pending, defaultPageSize, page)
        // )

        // setState(initialPurposeListState)
    }

    const [showDetail, setShowDetailModal] = React.useState(false)
    const showDetailModal = () => setShowDetailModal(true)
    const hideDetailModal = () => setShowDetailModal(false)

    const [showDelete, setShowWarningModal] = React.useState(false)
    const showWarningModal = () => setShowWarningModal(true)
    const hideWarningModal = () => setShowWarningModal(false)

    /* { TODO: Uncomment when BE Search is available */
    // const handleSearchedChange = (items) => {
    //   if(!!items) {
    //     setState({
    //       searched: state.searched,
    //       searchedResult: paginate(items, defaultPageSize, pageIndex)
    //     })
    //     setTotalItems(items.length)
    //   }
    // }

    const selectCredentialHandler = (item) => {
        dispatch(selectCredential(item))
        showDetailModal()
    }

    const deleteSelectedCredential = (item) => {
        dispatch(deleteCredential(item))
        hideWarningModal()
        dispatch(getCredentials(pageIndex, defaultPageSize))
    }

    const openDetailsModal = (id: string) => {
        /* { TODO: Add functionality to show modal for issue a credential */
        console.log('modal opened')
    }

    const handleStatusType = (ir: IIssuanceRequestModel, newStatus) => {
        dispatch(
            changeCredentialStatus(
                ir?.id,
                newStatus.value,
                pageIndex,
                defaultPageSize
            )
        )
    }

    const handleOptions = (item, selection?, y?) => {
        selectedCredential = item
        dispatch(selectCredential(item))
        showWarningModal()
    }

    const getStatusColorAndText = (status: string) => {
        let colorAndText
        if (status === 'REVOKED') {
            colorAndText = {
                color: 'red',
                text: 'credentials.revoked',
            }
        } else if (status === 'SUSPENDED') {
            colorAndText = {
                color: 'yellow',
                text: 'credentials.suspended',
            }
        } else if (status === 'ACTIVE') {
            colorAndText = {
                color: 'green',
                text: 'credentials.active',
            }
        } else if (status === 'ISSUED') {
            colorAndText = {
                color: 'green',
                text: 'credentials.issued',
            }
        }
        return colorAndText
    }

    const warningModalButtons = {
        primary: { label: 'public.delete', function: deleteSelectedCredential },
        secondary: { label: 'public.cancel', function: hideWarningModal },
    }

    const paginationData = {
        currentPage: pageIndex,
        totalPages: getTotalPageItems(totalItems || 0, defaultPageSize) || 0,
        onChange: handlePageChange,
        totalItems: totalItems || 0,
        pageSize: defaultPageSize,
        page: pageIndex,
        className: 'marginTop32',
    }

    return (
        <>
            {!isLoading ? (
                hasReadScope ? (
                    !!creds && creds?.issued?.length && !!creds?.issued[0] ? (
                        <div className={styles.tabIssuedContent}>
                            {limitsAreReached && (
                                <div
                                    className={`${
                                        pendingHover
                                            ? styles.infoReachedLimit
                                            : styles.infoReachedLimitHidden
                                    }`}
                                >
                                    {t('subscription.limitReachedDescription', {
                                        feature: t(
                                            'subscription.limitedFeatures.issuedCredentials'
                                        ),
                                    })}
                                </div>
                            )}

                            <CredentialsTable
                                tableData={issuedCredentials}
                                pending={false}
                                headerData={headersIssuedCredentialsTable}
                                mobileItem={(item) => (
                                    <BasicCard
                                        item={item}
                                        name={item?.data?.group}
                                        description={{
                                            text: shortValue(item.id, 15, 5),
                                        }}
                                        rightDate={item?.createdAt}
                                        tag={{
                                            text: getStatusColorAndText(
                                                item?.status
                                            )?.text,
                                            color: getStatusColorAndText(
                                                item?.status
                                            )?.color,
                                        }}
                                        buttonFunction={openDetailsModal}
                                    />
                                )}
                                paginationData={paginationData}
                                selectItem={selectCredentialHandler}
                                deleteItem={handleOptions}
                                noResults={
                                    !(
                                        creds?.issued ||
                                        creds?.issued?.length > 0
                                    )
                                    /* { TODO: Uncomment when BE Search is available */
                                    // &&
                                    // !(
                                    //     state.searchedIssuedResult &&
                                    //     state.searchedIssuedResult
                                    //         ?.length
                                    // )
                                }
                                handleDropdownChange={handleStatusType}
                                handleOptions={handleOptions}
                            />
                            {showDetail ? (
                                <CredentialsDataModal
                                    show={true}
                                    hideModal={hideDetailModal}
                                />
                            ) : null}
                            {showDelete ? (
                                <WarningModal
                                    hideModal={hideWarningModal}
                                    item={selectedCredential}
                                    primaryButton={warningModalButtons.primary}
                                    secondaryButton={
                                        warningModalButtons.secondary
                                    }
                                />
                            ) : null}
                        </div>
                    ) : (
                        <Description
                            text={t('credentials.noIssuedCredentials')}
                        />
                    )
                ) : (
                    <PermissionsPanel
                        scopes={credentialsScopes?.read}
                        readScope
                    />
                )
            ) : (
                <TableSkeleton
                    columnsNumber={3}
                    rowsNumber={defaultPageSize}
                    responsiveCards={true}
                />
            )}
        </>
    )
}

export default IssuedCreds
