import * as React from 'react'
import cx from 'classnames'
import styles from './subcategoryCell.module.scss'
import { t } from 'i18next'
import GaHoverTooltip from '../../../../../../../../../../Components/molecules/tooltips/GaHoverTooltip/GaHoverTooltip'
import { TooltipPosition } from '../../../../../../../../../../data/globalVar'

export type ISubcategoryCellProps = {
    subcategory: string
    information?: string
    extraTranslationParams?: Object
}

const SubcategoryCell: React.FC<ISubcategoryCellProps> = (props) => {
    const { subcategory, information, extraTranslationParams } = props
    return (
        <td className={`${styles?.subcategoryCell} ${cx('bodyRegularSM')}`}>
            <span>
                <p className={`${cx('bodyRegularSM')}`}>{t(subcategory)}</p>
                {information?.length && information?.length > 0 ? (
                    <GaHoverTooltip
                        className={styles.hoverTooltipTable}
                        position={TooltipPosition.right}
                        label={''}
                        info={information}
                    />
                ) : null}
            </span>
        </td>
    )
}

export default SubcategoryCell
