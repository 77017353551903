import cx from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { gaImages } from '../../../../../assets/globalConstants'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import SearchableListHeader from '../../../../../Components/molecules/headers/SearchableListHeader/SearchableListHeader'
import PermissionsPanel from '../../../../../Components/molecules/notification/panels/permissionsPanel/PermissionsPanel'
import TableSkeleton from '../../../../../Components/molecules/skeletons/tableSkeleton/TableSkeleton'
import WarningModal from '../../../../../Components/molecules/table/dynamicTable/warninModal/WarningModal'
import BasicCard from '../../../../../Components/organisms/Cards/BasicCard'
import {
    IApiKeyModel,
    IVerifierConfigModel,
    VerifierModel,
} from '../../../../../interfaces/interfaces'
import {
    getIsActiveSession,
    userHasScopes,
} from '../../../../../utils/permissionsUtil'
import { getCatalog } from '../../../../common/catalog/catalogActions'
import { getDids } from '../../../Dids/didActions'
import { didsScopes } from '../../../Dids/views/didList/didList.constants'
import { licensesSelectors } from '../../../../common/licenses'
import { errorPanelReachedFeatures } from '../../../../common/PanelScafolding/private/panelScafoldingPrivateConstants'
import { sessionSelectors } from '../../../../common/session'
import { statsSelectors } from '../../../../common/Statistics'
import { getStatistics } from '../../../../common/Statistics/statisticsActions'
import { configVerifierSelectors } from '../../../Verifier'
import {
    clearFormState,
    deleteVerifier,
    editVerifier,
    getVerifierConfigs,
    getVerifierPurposes,
} from '../../../Verifier/verifierActions'
import { verifierScopes } from '../../../Verifier/data/verifier.constants'
import {
    headerVerifiersButton,
    propertiesToSearchVerifers,
} from '../../services.constants'
import { onPremise } from '../../../../../data/globalVar'
import dataService from '../../../../../services/dataService'
import { apiKeysSelectors } from '../../../ApiKeys'
import { didsSelectors } from '../../../Dids'
import { getLimitsValues } from '../../../../../utils/licensesUtil'

type IVerifiersListProps = {}

const VerifiersList: React.FC<IVerifiersListProps> = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const isLoading = useSelector(configVerifierSelectors.getIsLoading)
    const verifierConfs = useSelector(configVerifierSelectors.getVerifiers)
    const currentLicense = useSelector(licensesSelectors.getCurrentProduct)
    const didsLoading = useSelector(didsSelectors.isLoading)

    const [verifiers, setVerifiers] = React.useState<IVerifierConfigModel[]>()
    const [state, setState] = React.useState<{
        searchedResult: IVerifierConfigModel[]
    }>({ searchedResult: verifiers?.slice() || [] })

    const [showDeleteWarning, setShowDeleteModal] = React.useState(false)
    const [selectedVerifier, setSelectedVerifier] = React.useState(undefined)

    const showDeleteModal = (item) => {
        setSelectedVerifier(item)
        setShowDeleteModal(true)
    }
    const hideDeleteModal = () => {
        setSelectedVerifier(undefined)
        setShowDeleteModal(false)
    }

    const excededLimitsState = useSelector(licensesSelectors.excededLimits)
    const usedFeatures = useSelector(statsSelectors.getUsedFeaturesNumber)
    const apiKeysListUnfiltered = useSelector(apiKeysSelectors.getApiKeys)
    const deletedConfig = useSelector(
        configVerifierSelectors.getDeletedConfigId
    )
    const statisticsIsLoading = useSelector(statsSelectors.getStatisticsLoading)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const licenseCustomLimits = useSelector(
        sessionSelectors.getLicenseCustomLimits
    )
    const limitsAreReached =
        usedFeatures?.verificationTemplates ===
        getLimitsValues(currentLicense, licenseCustomLimits)
            ?.verificationTemplates
    const hasReadScope = !!(
        availableScopes && userHasScopes(verifierScopes?.read, availableScopes)
    )
    const canReadDids = !!(
        availableScopes && userHasScopes(didsScopes?.read, availableScopes)
    )
    const canEdit = !!(
        availableScopes && userHasScopes(verifierScopes?.edit, availableScopes)
    )
    const canDelete = !!(
        availableScopes &&
        userHasScopes(verifierScopes?.delete, availableScopes)
    )
    const limitsAreExceded =
        excededLimitsState?.length &&
        excededLimitsState?.includes(
            errorPanelReachedFeatures.verification_templates
        )

    const apiKeysList = deletedConfig
        ? apiKeysListUnfiltered?.filter((ak: IApiKeyModel) =>
              ak.ssi_operations?.includes(deletedConfig)
          )
        : []

    useEffect(() => {
        if (hasReadScope) {
            dispatch(getVerifierConfigs())
        }
        if (!statisticsIsLoading && getIsActiveSession()) {
            // Just in cloud for now
            !onPremise && dispatch(getStatistics())
        }
    }, [])
    useEffect(() => {
        setVerifiers(verifierConfs || [])
    }, [verifierConfs?.length])
    useEffect(() => {
        setState({ searchedResult: verifiers?.slice() || [] })
    }, [verifiers])
    useEffect(() => {
        deletedConfig && updateApiKeys(deletedConfig)
    }, [deletedConfig])

    const selectVerifier = (verifier: IVerifierConfigModel) => {
        const verifierList = verifiers?.filter((i) => i.id === verifier.id)
        if (!!verifierList) {
            if (
                !!verifierList[0]?.vmethodId &&
                !verifierList[0]?.vmethodId.includes('#keys-1')
            ) {
                verifierList[0].vmethodId =
                    verifierList[0]?.vmethodId + '#keys-1'
            }
            clearVerifierState(), dispatch(editVerifier(verifierList[0]))
            getFormsData()
            history.push('/config-verifier')
        }
    }

    const clearVerifierState = () => {
        dispatch(clearFormState())
    }
    const getFormsData = () => {
        dispatch(getCatalog())
        dispatch(getVerifierPurposes())
        canReadDids && !didsLoading && dispatch(getDids())
    }
    const handleSearchedChange = (items) => {
        if (!!items) {
            setState({ searchedResult: items })
        }
    }

    const deleteItem = (item: VerifierModel) => {
        dispatch(deleteVerifier(item?.id))
    }

    const deleteModalButtons = {
        primary: { label: 'public.delete', function: deleteItem },
        secondary: { label: 'public.cancel', function: hideDeleteModal },
    }

    const itemActions = [
        {
            label: 'public.edit',
            action: selectVerifier,
            hasScope: canEdit,
        },
        {
            label: 'public.delete',
            action: showDeleteModal,
            hasScope: canDelete,
            color: 'red',
        },
    ]

    // Change Api Keys SSI Configs
    let apiKeysWithSSIConfigRemovedQtty = 0
    const apiKeysWithMoreSSIConfigs = apiKeysList?.filter(
        (ak: IApiKeyModel) => ak.ssi_operations?.length !== 1
    )

    const removeConfigFromApiKey = async (
        apiKey: any,
        apiKeyId: string,
        configID: string
    ) => {
        let updating = false
        const newApyKey = apiKey
        const newSSIConfigs = newApyKey.ssi_operations?.filter(
            (e) => e !== configID
        )
        newApyKey.ssi_operations = newSSIConfigs
        const simplifiedData = newApyKey as IApiKeyModel
        if (!updating) {
            updating = true
            while (
                apiKeysWithSSIConfigRemovedQtty <
                apiKeysWithMoreSSIConfigs?.length
            ) {
                apiKeysWithSSIConfigRemovedQtty =
                    apiKeysWithSSIConfigRemovedQtty + 1
                const callDone =
                    await dataService?.updateApiKeyAccordingSSIConfig(
                        simplifiedData,
                        apiKeyId
                    )
                if (callDone !== undefined) {
                    updating = false
                }
            }
        }
    }

    const updateApiKeys = (config: string) => {
        if (apiKeysWithMoreSSIConfigs?.length) {
            apiKeysWithMoreSSIConfigs?.map((apiKey: IApiKeyModel) => {
                removeConfigFromApiKey(apiKey, apiKey?.id, config)
            })
        }
    }

    return (
        <>
            <ScreenHeader
                title={'navigation.verifiers'}
                subText="services.listVerifiersDescription"
                titleClassname="heading5"
                button={{
                    ...headerVerifiersButton,
                    disabled:
                        (!!limitsAreExceded || limitsAreReached) && !onPremise,
                    disabledTooltip: limitsAreReached && !onPremise,
                }}
                buttonScopes={verifierScopes?.create}
                buttonFunction={() => {
                    clearVerifierState(),
                        getFormsData(),
                        history.push('/create-verifier')
                }}
            />

            {hasReadScope ? (
                <>
                    <SearchableListHeader
                        title={''}
                        isLoading={isLoading}
                        options={verifiers}
                        propertiesToSearch={propertiesToSearchVerifers}
                        handleSearchedChange={handleSearchedChange}
                    />

                    {!isLoading ? (
                        verifiers && verifiers?.length > 0 ? (
                            <div className={cx('rowLayout')}>
                                {state.searchedResult?.map((e, index) => {
                                    return (
                                        <BasicCard
                                            item={e}
                                            name={e.id || ''}
                                            key={index + e.id!}
                                            description={{
                                                text: 'services.credentialVerification',
                                            }}
                                            actions={itemActions}
                                            buttonFunction={selectVerifier}
                                        />
                                    )
                                })}
                            </div>
                        ) : (
                            <Description
                                text={t('services.noVerifiersCreated')}
                            />
                        )
                    ) : (
                        <TableSkeleton columnsNumber={3} rowsNumber={10} />
                    )}
                    {showDeleteWarning ? (
                        <WarningModal
                            primaryButton={deleteModalButtons.primary}
                            secondaryButton={deleteModalButtons.secondary}
                            hideModal={hideDeleteModal}
                            item={selectedVerifier}
                        />
                    ) : null}
                </>
            ) : (
                <PermissionsPanel scopes={verifierScopes?.read} readScope />
            )}
        </>
    )
}

export default VerifiersList
