import { Statistics } from '../../../interfaces/interfaces'
import { RootState } from '../../../store/indexReducers'

export const getStatistics = (state: RootState): Statistics | undefined =>
    state.statisticsReducer.statistics

export const getStatisticsLoading = (state: RootState): boolean =>
    state.statisticsReducer.isLoading

export const getStatisticsHasFailed = (state: RootState): boolean =>
    state.statisticsReducer.isLoading

export const getUsedFeaturesNumber = (state: RootState) =>
    getFeaturesNumber(
        state.statisticsReducer.statistics,
        state.statisticsReducer.isLoading
    )

export const getStatisticHasFailed = (state: RootState) =>
    state.statisticsReducer.statisticsError

export const getActiveUsersNumber = (state: RootState) =>
    formatDataFotGraphic(state.statisticsReducer.statistics?.Cnt2?.values)
const getFeaturesNumber = (statistics, isLoading) => {
    if (!isLoading) {
        return {
            verificationTemplates: getLastValue(statistics?.Cnt1) || 0,
            issuanceTemplates: getLastValue(statistics?.Ctf1) || 0,
            activeUsers: getLastValue(statistics?.Cnt2) || 0,
            issuedCredentials: getLastValue(statistics?.Ctf2) || 0,
            dids: getLastValue(statistics?.Cmn1) || 0,
            apiKeys: getLastValue(statistics?.Cmn2) || 0,
        }
    }
}

const getLastValue = (data) => {
    const lastData = data?.values?.filter((value) => value?.time === data?.end)
    return lastData?.length > 0 ? lastData[0]?.value : 0
}

const removeDuplicateDays = (data) => {
    const filteredData = new Array()
    const lastData = data?.values?.filter((value) => value?.time === data?.end)
    return lastData?.length > 0 ? lastData[0]?.value : 0
}

const formatDataFotGraphic = (data) => {
    const dataFiltered = trim(data)
    dataFiltered?.reverse()
    const formatted = dataFiltered?.map((el) => {
        return {
            label: el.time?.split('-')[1],
            value: el.value,
        }
    })
    return formatted
}

const getDataForMonth = (data) => {
    const today = new Date()
    let month = today.getMonth() + 1

    const dataCurrentMonth = data?.filter((el) => {
        const itemMonth = el?.time?.split('-')[1]
        const itemMonthFormated = removeLeftZero(itemMonth)
        return itemMonthFormated == month
    })

    return dataCurrentMonth
}
const removeLeftZero = (month) => month?.replace(/^(0+)/g, '')
function trim(data) {
    const daraR = data?.reverse()

    var values = {}
    return daraR?.filter(function (item) {
        var val = item['time']?.split('T')[0]
        var exists = values[val]
        values[val] = true
        return !exists
    })
}
