import React from 'react'
import cx from 'classnames'
import IconDataCell from '../elements/cells/iconDataCell/IconDataCell'
import CardHeader from '../elements/cardHeader/CardHeader'
import CardLeftColumn from '../elements/cardLeftColumn/CardLeftColumn'
import styles from './gatacaLicensesTableMobile.module.scss'
import Tag from '../../../../../../../../Components/atoms/tag/Tag'
import { InfoTogglesPricingModel } from '../../../../../../../../interfaces/interfaces'
import { gaColors } from '../../../../../../../../assets/globalConstants'
import { t } from 'i18next'
import { getPrice } from '../../../../../../../../utils/licensesUtil'
import CheckIcon from '../../../../../../../../assets/iconsLibrary/simple/check/checkIcon'
import CloseIcon from '../../../../../../../../assets/iconsLibrary/simple/close/closeIcon'

type IGatacaLicensesTableMobileProps = {
    tiers?: any[]
    license?: any
    licenseIndex: number
    switchPeriodValue: string
    infoToggles: InfoTogglesPricingModel
    selectLicense: (x: any) => void
    setPeriodChecked: (x) => void
}

const GatacaLicensesTableMobile: React.FC<IGatacaLicensesTableMobileProps> = (
    props
) => {
    const {
        tiers,
        license,
        switchPeriodValue,
        licenseIndex,
        infoToggles,
        selectLicense,
        setPeriodChecked,
    } = props

    return (
        <>
            <div className={styles?.tableContainer}>
                <div>
                    <div className={styles.periodToggle}>
                        <input
                            checked={switchPeriodValue === 'year'}
                            className={styles.periodCheckbox}
                            onChange={() => {}}
                            type="checkbox"
                            id="switch"
                        />
                        <label
                            onClick={(e) =>
                                setPeriodChecked(
                                    switchPeriodValue === 'month'
                                        ? 'year'
                                        : 'month'
                                )
                            }
                            className={styles.periodCheckboxLabel}
                        ></label>
                        <span className={cx('buttonMD primary700')}>
                            {t('subscription.payYearly')}
                        </span>
                    </div>
                    <select
                        className={`${styles?.selector} ${cx('bodyRegularMD')}`}
                        defaultValue={licenseIndex || 0}
                        onChange={(event) => {
                            selectLicense(event?.target?.value)
                        }}
                    >
                        {' '}
                        {tiers?.map((tier, index) => {
                            return (
                                <option
                                    defaultChecked={licenseIndex === 0}
                                    className={`${cx(
                                        'bodyRegularMD neutral1000'
                                    )}`}
                                    value={index}
                                >
                                    {tier?.name}
                                </option>
                            )
                        })}
                    </select>
                    <div className={styles?.header__container}>
                        <div>
                            {!license?.name
                                ?.toLowerCase()
                                ?.includes('enterprise') ? (
                                <>
                                    <p
                                        className={`${cx(
                                            ' bodyRegularXL neutral700'
                                        )}`}
                                    >
                                        <span
                                            className={`${cx(
                                                'heading4 neutral1000'
                                            )}`}
                                        >
                                            {getPrice(
                                                license?.prices,
                                                switchPeriodValue
                                            )}
                                            €
                                        </span>
                                        {' /'}
                                        &nbsp;
                                        {t('subscription.' + switchPeriodValue)}
                                    </p>
                                    {switchPeriodValue === 'month'
                                        ? license?.subPriceMonthLabel && (
                                              <span
                                                  className={`${cx(
                                                      'neutral700 bodyRegularXS marginTop2'
                                                  )}`}
                                              >
                                                  {t(
                                                      license?.subPriceMonthLabel
                                                  )}
                                              </span>
                                          )
                                        : license?.subPriceYearLabel && (
                                              <span
                                                  className={`${cx(
                                                      'neutral700 bodyRegularXS marginTop2'
                                                  )}`}
                                              >
                                                  {t(
                                                      license?.subPriceYearLabel
                                                  )}
                                              </span>
                                          )}
                                </>
                            ) : (
                                <>
                                    <p
                                        className={`${cx(
                                            'heading5 neutral1000'
                                        )}`}
                                    >
                                        {t('subscription.customPricing')}
                                    </p>
                                </>
                            )}
                        </div>

                        <div>
                            {license?.type === 'Professional' && (
                                <Tag
                                    text={'Popular'}
                                    color="#00999E"
                                    className={styles?.popularTag}
                                />
                            )}
                        </div>
                    </div>
                </div>

                {/* Verifiable Credentials */}
                <div className={styles?.card}>
                    <CardHeader title={'subscription.verificableCreds'} />

                    <div key={0} className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.verificationTemplates'}
                            information={t('subscription.verTemplatesInfo')}
                        />
                        <div className={`${cx('bodyBoldSM')}`}>
                            <p className={cx(styles.paragraphCell)}>
                                {license?.features?.verificationTemplates}
                            </p>
                        </div>
                    </div>
                    <div key={0} className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.issuanceTemplates'}
                            information={t(
                                'subscription.issuanceTemplatesInfo'
                            )}
                        />
                        <div className={`${cx('bodyBoldSM')}`}>
                            <p className={cx(styles.paragraphCell)}>
                                {license?.features?.issuanceTemplates}
                            </p>
                        </div>
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.activeUsers'}
                            information={t('subscription.activeUsersInfo')}
                        />
                        <div className={`${cx('bodyBoldSM')}`}>
                            <p className={cx(styles.paragraphCell)}>
                                {license?.features?.activeUsers}
                            </p>
                        </div>
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.issuedCredentials'}
                            information={t('subscription.issuedCredsInfo')}
                        />
                        <div className={`${cx('bodyBoldSM')}`}>
                            <p className={cx(styles.paragraphCell)}>
                                {license?.features?.issuedCredentials}
                            </p>
                        </div>
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'ew.connections.dataAgreements'}
                            information={t('subscription.dataAgreementsInfo')}
                        />
                        <IconDataCell
                            key={'SE__' + license?.type}
                            data={license?.features?.dataAgreements}
                        />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.verifiableIds'}
                            information={t('subscription.verifiableIdsInfo')}
                        />
                        <IconDataCell
                            key={'SE__' + license?.type}
                            data={license?.features?.verifiableIds}
                        />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.customSchemas'}
                            information={t('subscription.customSchemasInfo')}
                        />
                        <IconDataCell
                            key={'SE__' + license?.type}
                            data={license?.features?.customSchemas}
                        />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.sandboxTest'}
                            information={t('subscription.sandboxInfo')}
                        />
                        <IconDataCell
                            key={'SE__' + license?.type}
                            data={license?.features?.sandboxTesting}
                        />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.extraCreds'}
                            information={t('subscription.extraCredsInfo')}
                        />
                        <IconDataCell
                            key={'SE__' + license?.type}
                            data={license?.features?.extraCredentials}
                        />
                    </div>
                </div>

                {/* Single Sign on */}
                <div className={styles?.card}>
                    <CardHeader title={'subscription.singleSignOn'} />

                    <div key={0} className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.ageVerification'}
                            information={t('subscription.ageVerInfo')}
                        />
                        <div className={`${cx('bodyBoldSM')}`}>
                            {typeof license?.features?.ageVerification ==
                            'boolean' ? (
                                license?.features?.ageVerification ? (
                                    <CheckIcon
                                        size={16}
                                        color={gaColors.success300}
                                    />
                                ) : (
                                    <CloseIcon
                                        size={16}
                                        color={gaColors.neutral1000}
                                    />
                                )
                            ) : (
                                <p className={cx(styles.paragraphCell)}>
                                    {t(license?.features?.ageVerification)}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn text={'subscription.appInt'} />
                        <div className={`${cx('bodyBoldSM')}`}>
                            {typeof license?.features?.appIntegrations ==
                            'boolean' ? (
                                license?.features?.appIntegrations ? (
                                    <CheckIcon
                                        size={16}
                                        color={gaColors.success300}
                                    />
                                ) : (
                                    <CloseIcon
                                        size={16}
                                        color={gaColors.neutral1000}
                                    />
                                )
                            ) : (
                                <p className={cx(styles.paragraphCell)}>
                                    {t(license?.features?.appIntegrations)}
                                </p>
                            )}
                        </div>
                    </div>
                </div>

                {/* Enterprise */}
                <div className={styles?.card}>
                    <CardHeader title={'subscription.enterprise'} />

                    <div key={0} className={styles?.card__row}>
                        <CardLeftColumn text={'subscription.didMethods'} />
                        <div className={`${cx('bodyBoldSM')}`}>
                            <p>{license?.features?.didMethods}</p>
                        </div>
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn text={'subscription.trustRegistries'} />
                        <div className={`${cx('bodyBoldSM')}`}>
                            <p>{t(license?.features?.trustRegistries)}</p>
                        </div>
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.qualifiedCerts'}
                            information={t('subscription.qualifiedCertsInfo')}
                        />
                        <IconDataCell
                            data={license?.features?.qualifiedCertificates}
                        />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.entWallet'}
                            information={t('subscription.entWalletInfo')}
                        />
                        <div className={`${cx('bodyBoldSM')}`}>
                            {typeof license?.features?.enterpriseWallet ==
                            'boolean' ? (
                                license?.features?.enterpriseWallet ? (
                                    <CheckIcon
                                        size={16}
                                        color={gaColors.success300}
                                    />
                                ) : (
                                    <CloseIcon
                                        size={16}
                                        color={gaColors.neutral1000}
                                    />
                                )
                            ) : (
                                <p className={cx(styles.paragraphCell)}>
                                    {t(license?.features?.enterpriseWallet)}
                                </p>
                            )}
                        </div>
                    </div>
                </div>

                {/* Administration */}
                <div className={styles?.card}>
                    <CardHeader title={'subscription.administration'} />
                    <div className={styles?.card__row}>
                        <CardLeftColumn text={'subscription.stats'} />
                        <IconDataCell data={license?.features?.statistics} />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn text={'subscription.roleBasedAccess'} />
                        <IconDataCell data={license?.features?.roleAccess} />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn text={'subscription.customRoles'} />
                        <IconDataCell data={license?.features?.customRoles} />
                    </div>
                </div>
                {/* Support Services */}
                <div className={styles?.card}>
                    <CardHeader title={'subscription.supportServices'} />
                    <div className={styles?.card__row}>
                        <CardLeftColumn text={'subscription.ticketingSystem'} />
                        <IconDataCell data={license?.features?.emailSupport} />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn text={'subscription.slackSupport'} />
                        <IconDataCell data={license?.features?.slackSupport} />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn text={'subscription.accountTeam'} />
                        <IconDataCell data={license?.features?.accountTeam} />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn text={'24x7'} />
                        <div className={`${cx('bodyBoldSM')}`}>
                            {typeof license?.features?.available24x7 ==
                            'boolean' ? (
                                license?.features?.available24x7 ? (
                                    <CheckIcon
                                        size={16}
                                        color={gaColors.success300}
                                    />
                                ) : (
                                    <CloseIcon
                                        size={16}
                                        color={gaColors.neutral1000}
                                    />
                                )
                            ) : (
                                <p className={cx(styles.paragraphCell)}>
                                    {t(license?.features?.available24x7)}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn text={'subscription.slas'} />
                        <div className={`${cx('bodyBoldSM')}`}>
                            {typeof license?.features?.slas == 'boolean' ? (
                                license?.features?.slas ? (
                                    <CheckIcon
                                        size={16}
                                        color={gaColors.success300}
                                    />
                                ) : (
                                    <CloseIcon
                                        size={16}
                                        color={gaColors.neutral1000}
                                    />
                                )
                            ) : (
                                <p className={cx(styles.paragraphCell)}>
                                    {t(license?.features?.slas)}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.onboardingAndTraining'}
                            information={t(
                                infoToggles?.integrationCustomInfraestructure
                            )}
                        />
                        <div className={`${cx('bodyBoldSM')}`}>
                            {typeof license?.features?.onboardAndTraining ==
                            'boolean' ? (
                                license?.features?.onboardAndTraining ? (
                                    <CheckIcon
                                        size={16}
                                        color={gaColors.success300}
                                    />
                                ) : (
                                    <CloseIcon
                                        size={16}
                                        color={gaColors.neutral1000}
                                    />
                                )
                            ) : (
                                <p className={cx(styles.paragraphCell)}>
                                    {t(license?.features?.onboardAndTraining)}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GatacaLicensesTableMobile
