import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { apiKeysSelectors } from '../..'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import SearchableListHeader from '../../../../../Components/molecules/headers/SearchableListHeader/SearchableListHeader'
import PermissionsPanel from '../../../../../Components/molecules/notification/panels/permissionsPanel/PermissionsPanel'
import CardListSkeleton from '../../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
import WarningModal from '../../../../../Components/molecules/table/dynamicTable/warninModal/WarningModal'
import BasicCard from '../../../../../Components/organisms/Cards/BasicCard'
import { ActionStatusTypes } from '../../../../../assets/globalConstants'
import { onPremise } from '../../../../../data/globalVar'
import { IApiKeyModel } from '../../../../../interfaces/interfaces'
import {
    getTotalPageItems,
    paginate,
} from '../../../../../utils/paginationUtil'
import { userHasScopes } from '../../../../../utils/permissionsUtil'
import { getIssuances } from '../../../Issuance/issuanceActions'
import { errorPanelReachedFeatures } from '../../../../common/PanelScafolding/private/panelScafoldingPrivateConstants'
import { statsSelectors } from '../../../../common/Statistics'
import { getStatistics } from '../../../../common/Statistics/statisticsActions'
import { getVerifierConfigs } from '../../../Verifier/verifierActions'
import { licensesSelectors } from '../../../../common/licenses'
import { sessionSelectors } from '../../../../common/session'
import {
    clearActionStatus,
    clearApiKeysList,
    clearFormState,
    deleteApiKey,
    editApiKey,
    getApiKeys,
    regenerateApiKey as rgApiKey,
} from '../../apiKeysActions'
import ApiKeyPassModal from '../apiKeyPassword/ApiKeyPassModal'
import {
    apiKeysScopes,
    headerApyKeyListButton,
    propertiesToSearchApiKeys,
} from './apiKeysList.constants'
import { issuanceSelectors } from '../../../Issuance'
import { configVerifierSelectors } from '../../../Verifier'
import { getLimitsValues } from '../../../../../utils/licensesUtil'
import { GaPagination } from '../../../../../Components/Pagination/Pagination'
import { defaultPageSize } from '../../../../../Components/Pagination/paginationConstants'

type ISearchState = {
    searched: string
    searchedResult: any
}

export const ApiKeysList: React.FC = () => {
    const history = useHistory()
    const apiKeysList = useSelector(apiKeysSelectors.getApiKeys)
    const isLoading = useSelector(apiKeysSelectors.loadingStatus)
    const selectedApiKey = useSelector(apiKeysSelectors.getSelectedApiKey)
    const regenerateStatus = useSelector(apiKeysSelectors.getStatus)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [pageIndex, setPageIndex] = useState(1)
    const [paginatedApiKeys, setPaginatedApiKeys] = useState([])
    const [apiKeys, setApiKeys] = React.useState<IApiKeyModel[]>()
    const [totalItems, setTotalItems] = useState(apiKeys?.length || 0)

    const excededLimitsState = useSelector(licensesSelectors.excededLimits)
    const currentLicense = useSelector(licensesSelectors.getCurrentProduct)
    const usedFeatures = useSelector(statsSelectors.getUsedFeaturesNumber)
    const apiKeysLoading = useSelector(apiKeysSelectors.loadingStatus)
    const licenseCustomLimits = useSelector(
        sessionSelectors.getLicenseCustomLimits
    )

    const initialPurposeListState = {
        searched: '',
        searchedResult: paginatedApiKeys?.slice() || [],
    }

    const [state, setState] = React.useState<ISearchState>(
        initialPurposeListState
    )
    const [showDeleteWarning, setShowDeleteModal] = React.useState(false)
    const [selectedApiKeyToDelete, setSelectedpselectedApiKey] =
        React.useState(undefined)

    const showDeleteModal = (item) => {
        setSelectedpselectedApiKey(item)
        setShowDeleteModal(true)
    }
    const hideDeleteModal = () => {
        setSelectedpselectedApiKey(undefined)
        setShowDeleteModal(false)
    }
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const statisticsIsLoading = useSelector(statsSelectors.getStatisticsLoading)
    const issuancesAreLoading = useSelector(issuanceSelectors.getIsLoading)
    const verifiersAreLoading = useSelector(
        configVerifierSelectors.getIsLoading
    )
    const hasReadScope = !!(
        availableScopes && userHasScopes(apiKeysScopes?.read, availableScopes)
    )
    const canEdit = !!(
        availableScopes && userHasScopes(apiKeysScopes?.edit, availableScopes)
    )
    const canDelete = !!(
        availableScopes && userHasScopes(apiKeysScopes?.delete, availableScopes)
    )
    const canReadSSIConfigs = !!(
        availableScopes &&
        userHasScopes(['readSSIConfigs'], availableScopes, true)
    )

    const limitsAreReached =
        usedFeatures?.apiKeys ===
        getLimitsValues(currentLicense, licenseCustomLimits)?.apiKeys

    const limitsAreExceded =
        excededLimitsState?.length &&
        excededLimitsState?.includes(errorPanelReachedFeatures.api_keys)

    useEffect(() => {
        dispatch(clearActionStatus())
        dispatch(clearFormState())
        hasReadScope && !apiKeysLoading && dispatch(getApiKeys())
        // Just in cloud for now
        if (!statisticsIsLoading && !onPremise && !usedFeatures?.apiKeys) {
            dispatch(getStatistics())
        }
        if (canReadSSIConfigs) {
            issuancesAreLoading && dispatch(getIssuances())
            verifiersAreLoading && dispatch(getVerifierConfigs())
        }
    }, [])

    useEffect(() => {
        setApiKeys(apiKeysList)
        setState(initialPurposeListState)
    }, [apiKeysList, pageIndex])
    useEffect(() => {
        setPaginatedApiKeys(paginate(apiKeys, defaultPageSize, pageIndex))
    }, [apiKeys?.length])

    useEffect(() => {
        setState(initialPurposeListState)
        setTotalItems(apiKeys?.length || 0)
    }, [paginatedApiKeys])

    const handlePageChange = (page) => {
        setPageIndex(page)
        setPaginatedApiKeys(paginate(apiKeys, defaultPageSize, page))
        setState(initialPurposeListState)
    }

    const selectUserHandler = (item) => {
        dispatch(editApiKey(item))
        history.push('config-apiKey')
    }

    const handleSearchedChange = (items) => {
        if (!!items) {
            setState({
                searched: state.searched,
                searchedResult: paginate(items, defaultPageSize, pageIndex),
            })
            setTotalItems(items.length)
        }
    }

    const goToCreateApiKey = () => {
        dispatch(clearApiKeysList())
        history.push('/create-apikey')
    }

    const regenerateApiKey = (item: IApiKeyModel) => {
        dispatch(rgApiKey(item))
    }

    const deleteItem = (item: IApiKeyModel) => {
        dispatch(deleteApiKey(item?.id))
    }

    const showModal = (): boolean => {
        return (
            regenerateStatus &&
            (regenerateStatus as ActionStatusTypes) !==
                ActionStatusTypes.neutral
        )
    }

    const handleCloseModal = () => dispatch(clearActionStatus())

    const deleteModalButtons = {
        primary: { label: 'public.delete', function: deleteItem },
        secondary: { label: 'public.cancel', function: hideDeleteModal },
    }

    const itemActions = [
        {
            label: 'public.edit',
            action: selectUserHandler,
            hasScope: canEdit,
        },
        {
            label: 'apiKeys.regeneratePassword',
            action: regenerateApiKey,
            hasScope: canEdit,
        },
        {
            label: 'public.delete',
            action: showDeleteModal,
            hasScope: canDelete,
            color: 'red',
        },
    ]

    return (
        <>
            <ScreenHeader
                title={'apiKeys.screenTitle'}
                subText="apiKeys.screenDescription"
                buttonScopes={apiKeysScopes?.create}
                button={{
                    ...headerApyKeyListButton,
                    disabled:
                        (!!limitsAreExceded || limitsAreReached) && !onPremise,
                    disabledTooltip: limitsAreReached && !onPremise,
                }}
                buttonFunction={goToCreateApiKey}
            />
            {hasReadScope ? (
                <>
                    <SearchableListHeader
                        title={''}
                        isLoading={isLoading}
                        options={apiKeys}
                        propertiesToSearch={propertiesToSearchApiKeys}
                        handleSearchedChange={handleSearchedChange}
                    />

                    {!isLoading ? (
                        apiKeys && apiKeys?.length > 0 ? (
                            <>
                                <div className={cx('marginBottom32')}>
                                    {state?.searchedResult?.map(
                                        (e: IApiKeyModel, index) => {
                                            return (
                                                <div
                                                    key={
                                                        'api-key' +
                                                        index.toString()
                                                    }
                                                    className={cx(
                                                        'margin-bottom'
                                                    )}
                                                >
                                                    <BasicCard
                                                        item={e}
                                                        name={
                                                            e.alias
                                                                ? e.alias
                                                                : e.id
                                                        }
                                                        description={{
                                                            text: 'apiKeys.connectedTenants',
                                                            extraTranslationParams:
                                                                {
                                                                    quantity:
                                                                        e.ssi_operations?.join(
                                                                            ', '
                                                                        ) + '.',
                                                                },
                                                        }}
                                                        actions={itemActions}
                                                        buttonFunction={() => {}}
                                                    />
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                                {apiKeys?.length > defaultPageSize ? (
                                    <GaPagination
                                        currentPage={pageIndex}
                                        totalPages={
                                            getTotalPageItems(
                                                totalItems || 0,
                                                defaultPageSize
                                            ) || 0
                                        }
                                        onChange={handlePageChange}
                                        totalItems={totalItems || 0}
                                        pageSize={defaultPageSize}
                                    />
                                ) : null}
                            </>
                        ) : (
                            <Description text={t('users.noUsersCreated')} />
                        )
                    ) : (
                        <CardListSkeleton cardsNumber={5} />
                    )}
                    {showModal() ? (
                        <ApiKeyPassModal
                            show={showModal()}
                            hideModal={handleCloseModal}
                        />
                    ) : null}
                    {showDeleteWarning ? (
                        <WarningModal
                            primaryButton={deleteModalButtons.primary}
                            secondaryButton={deleteModalButtons.secondary}
                            hideModal={hideDeleteModal}
                            item={selectedApiKeyToDelete}
                        />
                    ) : null}
                </>
            ) : (
                <PermissionsPanel scopes={apiKeysScopes?.read} readScope />
            )}
        </>
    )
}
