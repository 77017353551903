import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cardStatuses } from '../../../../features/common/licenses/licenses.constants'
import { hideScreenLoaderAction } from '../../../../features/common/Loader/loaderActions'
import { showNotification } from '../../../../features/common/Notification/notificationActions'
import {
    CreateOrgPayloadModel,
    ImageModel,
    StepModel,
} from '../../../../interfaces/interfaces'
import ErrorPanel from '../../../molecules/notification/panels/error/ErrorPanel'
import WarningPanel from '../../../molecules/notification/panels/warning/warningPanel'
import DarkButton from '../../buttons/DarkButton/DarkButton'
import LightButton from '../../buttons/LightButton/LightButton'
import styles from './stripeCardForm.module.scss'
import cx from 'classnames'
import { Trans } from 'react-i18next'
import { brandLabels } from '../../../../assets/globalConstants'
import {
    getClientSecretAction,
    removeClientSecretAction,
    setActivatedAccount,
} from '../../../../features/common/licenses/licensesActions'
import { isSendingPaymentMethod } from '../../../../features/common/licenses/licensesSelectors'
import { sessionSelectors } from '../../../../features/common/session'
import { createOrgAction } from '../../../../features/common/organization/organizationActions'
import GaCheckbox from '../checkbox/GaCheckbox'
import { LabelPosition, TextSizes } from '../../../../data/globalVar'
import Loading from '../../../loader/loader/Loader'

type StripeCardFormProps = {
    validForm: boolean
    clientSecret: string
    statusPanel?: boolean
    status: { icon: ImageModel; label: string; description: string }
    formattedOrgToCreate?: CreateOrgPayloadModel
    organizationCreated?: boolean
    loadingScreen?: boolean
    flowSteps?: StepModel[]
    setPreviousStepsInactive?: () => void
    setLoader: (x?) => void
    successAction: (x?) => void
    failedAction?: (x?) => void
    hideForm: (x?) => void
}

const useOptions = () => {
    const fontSize = useResponsiveFontSize()
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: '#424770',
                    padding: '20px',
                    height: '40px !important',
                    letterSpacing: '0.025em',
                    fontFamily: 'Work Sans", Arial, sans-serif',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                },

                invalid: {
                    color: '#9e2146',
                },
            },
        }),
        [fontSize]
    )

    return options
}

function useResponsiveFontSize() {
    const getFontSize = () => (window.innerWidth < 450 ? '13px' : '16px')
    const [fontSize, setFontSize] = useState(getFontSize)

    useEffect(() => {
        const onResize = () => {
            setFontSize(getFontSize())
        }

        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    })

    return fontSize
}

export const StripeCardForm: React.FC<StripeCardFormProps> = (props) => {
    const {
        clientSecret,
        statusPanel,
        status,
        validForm,
        formattedOrgToCreate,
        loadingScreen,
        organizationCreated,
        flowSteps,
        setPreviousStepsInactive,
        setLoader,
        successAction,
        failedAction,
        hideForm,
    } = props

    const stripe = useStripe()
    const elements = useElements()
    const options = useOptions()
    const dispatch = useDispatch()

    const orgId = useSelector(sessionSelectors.getUserOrganization)
    // const sendingPaymentMethod = useSelector(isSendingPaymentMethod)

    const [cardData, setCardData] = useState('')
    const [aceptedConditions, setConditionsState] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)

    const [dataError, setDataError] = useState(false)

    const validCardForm =
        validForm && !!cardData?.trim() && !!aceptedConditions && !!stripe
    !!elements && !loadingSubmit && !dataError

    useEffect(() => {}, [validForm, orgId, organizationCreated])

    useEffect(() => {
        if (clientSecret?.length) {
            sendCard()
        }
    }, [clientSecret])

    useEffect(() => {
        flowSteps?.length === 3 &&
            setPreviousStepsInactive &&
            setPreviousStepsInactive()
    })

    const handleSubmit = async (ev) => {
        ev?.preventDefault() && ev?.preventDefault()
        setLoadingSubmit(true)
        setLoader(true)
        dispatch(getClientSecretAction())
    }

    const sendCard = async () => {
        setLoader(true)
        const carC = elements?.getElement(CardElement)!

        if (stripe && carC) {
            const { setupIntent, error } = await stripe
                ?.confirmCardSetup(clientSecret, {
                    payment_method: {
                        card: carC,
                        billing_details: {
                            name: '',
                        },
                    },
                })
                .then(function (result) {
                    if (result?.error) {
                        failedAction && failedAction()
                        setLoader(false)
                        dispatch(removeClientSecretAction())
                        setLoadingSubmit(false)
                        dispatch(
                            showNotification(
                                result?.error.message,
                                'error',
                                'billing.setup_intent_unexpected_state'
                            )
                        )
                    } else {
                        if (result?.setupIntent?.status === 'succeeded') {
                            setTimeout(() => {
                                successAction(
                                    result.setupIntent?.payment_method
                                )
                            }, 2000)
                        } else {
                            setLoader(false)
                            setLoadingSubmit(false)
                            dispatch(setActivatedAccount(false))
                        }
                    }
                    return result
                })
                .catch((err) => {
                    setLoader(false)
                    setLoadingSubmit(false)
                    dispatch(setActivatedAccount(false))
                    dispatch(hideScreenLoaderAction())
                    return err
                })
        }
    }
    return (
        <form
            onSubmit={(e) => {
                handleSubmit(e)
            }}
            className={styles.stripeForm}
        >
            <CardElement
                options={options}
                onReady={(e) => {}}
                onChange={(e) => {
                    setCardData(e.value?.postalCode)
                }}
                onBlur={() => {}}
                onFocus={() => {}}
            />
            <div className={styles.terms}>
                <GaCheckbox
                    index={'0'}
                    name="accept_terms"
                    checked={aceptedConditions}
                    onChange={() => setConditionsState(!aceptedConditions)}
                    className={styles.required}
                    id="accept-terms"
                    itemText={[
                        <Trans
                            i18nKey={'billing.authorizationSaveCard'}
                            values={{
                                brand: brandLabels?.brand,
                            }}
                        />,
                    ]}
                    position={LabelPosition.right}
                    textSize={TextSizes.LG}
                />
                {statusPanel ? (
                    status === cardStatuses.unavailable ? (
                        <WarningPanel
                            hasIcon
                            hasTitle
                            label={status.description}
                        />
                    ) : status === cardStatuses.fail ? (
                        <ErrorPanel
                            hasIcon
                            hasTitle
                            label={'subscription.reviewCardData'}
                        />
                    ) : null
                ) : null}
            </div>
            <div className={styles.stripeForm__buttons}>
                <LightButton
                    className={styles.stripeForm__buttons__item}
                    text={'public.close'}
                    disabled={loadingSubmit}
                    functionality={hideForm}
                />
                <DarkButton
                    className={
                        validCardForm ? styles.stripeForm__buttons__item : ''
                    }
                    text={'public.save'}
                    disabled={!validCardForm || loadingSubmit}
                    functionality={(e) => {
                        handleSubmit(e)
                    }}
                />
            </div>
            {loadingScreen ? <Loading /> : null}
        </form>
    )
}
