import * as React from 'react'
import cx from 'classnames'
import { gaColors } from '../../../../../../../../../../assets/globalConstants'
import CheckIcon from '../../../../../../../../../../assets/iconsLibrary/simple/check/checkIcon'
import CloseIcon from '../../../../../../../../../../assets/iconsLibrary/simple/close/closeIcon'

export type IIconDataCellProps = {
    data: any
}

const IconDataCell: React.FC<IIconDataCellProps> = (props) => {
    const { data } = props
    return (
        <>
            <div className={`${cx('bodyBoldSM')}`}>
                {data ? (
                    <CheckIcon size={16} color={gaColors.success300} />
                ) : (
                    <CloseIcon size={16} color={gaColors.neutral1000} />
                )}
            </div>
        </>
    )
}

export default IconDataCell
