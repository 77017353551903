import { t } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { brandLabels, gaColors } from '../../../../../assets/globalConstants'
import { licensesSelectors } from '../../../../../features/common/licenses'
import { ButtonModel } from '../../../../../interfaces/interfaces'
import DarkButton from '../../../../atoms/buttons/DarkButton/DarkButton'
import LightPanel from '../../../../atoms/panels/LightPanel/LightPanel'
import Description from '../../../../atoms/texts/Description/Description'
import styles from './gatacaOnboardingLicenseCard.module.scss'
import cx from 'classnames'
import {
    getIfProductHasAmount,
    getPrice,
    getTierLimitToDisplay,
    getTierLimitsValues,
} from '../../../../../utils/licensesUtil'
import { isDefaultBrandActive } from '../../../../../utils/brandThemeUtil'
import GaHoverTooltip from '../../../../molecules/tooltips/GaHoverTooltip/GaHoverTooltip'
import {
    TooltipArrowPosition,
    TooltipPosition,
} from '../../../../../data/globalVar'
import CheckIcon from '../../../../../assets/iconsLibrary/simple/check/checkIcon'

type IOnboardingLicenseCardProps = {
    license: any
    isEnterpriseTier?: boolean
    showAllDetails?: boolean
    firstButton?: ButtonModel
    period: string
    isNewLicense?: boolean
    newLicensePeriod: string
    buttonExtraTranslationParams?: Object
}

type IFeatureProps = {
    feature: any
    label: string
    info?: string
    tooltipArrowPosition?: TooltipArrowPosition
}

export const Feature: React.FC<IFeatureProps> = (props) => {
    const { feature, label, info, tooltipArrowPosition } = props
    let formattedFeatureLimit = getTierLimitToDisplay(feature)

    return !info ? (
        <li className={`${styles.license__features__list__item}`}>
            <>
                <CheckIcon size={16} color={gaColors.success300} />
            </>

            <div className={styles.license__features__list__item__content}>
                <span
                    className={cx(
                        'bodyRegularMD neutral1000',
                        styles.featureName
                    )}
                >
                    {formattedFeatureLimit && (
                        <span
                            className={cx(
                                'bodyBoldMD neutral1000 marginRight4'
                            )}
                        >
                            {formattedFeatureLimit}
                        </span>
                    )}
                    {t(label)}
                </span>
            </div>
        </li>
    ) : (
        <li className={styles.license__features__list__item}>
            <GaHoverTooltip
                position={TooltipPosition.bottom}
                positionArrowDirection={
                    tooltipArrowPosition || TooltipArrowPosition.center
                }
                label={
                    <>
                        <CheckIcon size={16} color={gaColors.success300} />
                        &nbsp;&nbsp;
                        <span
                            className={cx(
                                'bodyBoldSM neutral1000',
                                styles.featureQuantity
                            )}
                        >
                            {feature}
                        </span>
                        <p
                            className={cx(
                                'bodyRegularSM neutral1000',
                                styles.featureName
                            )}
                        >
                            &nbsp;
                            {t(label)}
                        </p>
                    </>
                }
                info={info || ''}
            />
        </li>
    )
}

const OnboardingLicenseCard: React.FC<IOnboardingLicenseCardProps> = (
    props
) => {
    const {
        license,
        isEnterpriseTier,
        firstButton,
        period,
        isNewLicense,
        buttonExtraTranslationParams,
    } = props

    const { t } = useTranslation()
    const selectedPeriod = useSelector(licensesSelectors.getSelectedPeriod)
    const licenseIsEnterprise = !getIfProductHasAmount(license, selectedPeriod)

    return (
        <LightPanel className={`${styles.license} `}>
            {isNewLicense && (
                <div
                    className={cx(
                        styles.license__tag,
                        styles.license__tag__newLicense
                    )}
                >
                    <p className={'bodyBoldCap primary700'}>
                        {t('subscription.newPlan')}
                    </p>
                </div>
            )}
            <div className={styles.license__header}>
                <p
                    className={cx(
                        'heading3 neutral1000',
                        styles.license__header__title
                    )}
                >
                    {license?.type}
                </p>
                {!!license?.isPopular && (
                    <div className={styles.license__header__popularTag}>
                        <p className={cx('buttonSM')}>
                            {t('subscription.popular')}
                        </p>
                    </div>
                )}
            </div>
            {license?.description ? (
                <Description
                    className={cx(
                        'bodyRegularMD neutral700',
                        styles.license__description
                    )}
                    text={license?.description}
                />
            ) : (
                <Description
                    className={cx(
                        'bodyRegularMD neutral700',
                        styles.license__description
                    )}
                    text={''}
                />
            )}
            {!licenseIsEnterprise ? (
                <>
                    <p
                        className={cx(
                            'bodyRegularMD neutral700',
                            styles.license__price
                        )}
                    >
                        <span className={cx('bodyRegularSM neutral1000')}>
                            {getPrice(license?.prices, selectedPeriod)}€
                        </span>{' '}
                        &nbsp;
                        {'/ ' + t('subscription.' + period)}
                    </p>

                    {period === 'year' && (
                        <span
                            className={cx(
                                'bodyRegularXS neutral1000',
                                styles.license__save
                            )}
                        >
                            {t('subscription.saveTwoMonths')}
                        </span>
                    )}
                </>
            ) : (
                <p
                    className={`${cx(
                        'heading5 neutral1000',
                        styles.license__customPrice
                    )} ${
                        period === 'year'
                            ? styles.license__customPriceLarge
                            : ''
                    }`}
                >
                    {t('subscription.customPricing')}
                </p>
            )}
            <div className={styles.license__features}>
                <div>
                    {!isEnterpriseTier ? (
                        <ul className={styles.license__features__list}>
                            <Feature
                                feature={
                                    getTierLimitsValues(license)?.activeUsers
                                }
                                label={'subscription.activeUsers'}
                            />
                            <Feature
                                feature={
                                    getTierLimitsValues(license)
                                        ?.issuedCredentials
                                }
                                label={'subscription.issuedCredentials'}
                            />
                            <Feature
                                feature={''}
                                label={'subscription.standardSupport'}
                            />
                        </ul>
                    ) : (
                        <ul className={styles.license__features__list}>
                            <Feature
                                feature={''}
                                label={'subscription.customLimits'}
                            />
                            <Feature
                                feature={''}
                                label={'subscription.advanceFunctionalities'}
                            />
                            <Feature
                                feature={''}
                                label={'subscription.premiumSupport'}
                            />
                        </ul>
                    )}
                </div>
            </div>

            {firstButton ? (
                <DarkButton
                    className={cx('buttonMD', styles.license__button)}
                    text={firstButton?.label}
                    buttonExtraTranslationParams={buttonExtraTranslationParams}
                    disabled={false}
                    functionality={() => {
                        !licenseIsEnterprise || !isDefaultBrandActive()
                            ? firstButton.function()
                            : window?.open(brandLabels.contactlink, '_blank')
                    }}
                />
            ) : null}
        </LightPanel>
    )
}

export default OnboardingLicenseCard
