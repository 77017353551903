import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { licensesSelectors } from '../../..'
import { ReactComponent as CloseIcon } from '../../../../../../assets/images/close.svg'
import DarkButton from '../../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import LightButton from '../../../../../../Components/atoms/buttons/LightButton/LightButton'
import { formatStringDate } from '../../../../../../utils/formatDataUtil'
import styles from './downGradeInfoModal.module.scss'
import {
    ITierModel,
    IUsedFeatures,
} from '../../../../../../interfaces/interfaces'
import cx from 'classnames'
import { substring } from '../../../../../../utils/operationsUtil'
import { t } from 'i18next'
import { brandLabels } from '../../../../../../assets/globalConstants'
import { getTierLimitsValues } from '../../../../../../utils/licensesUtil'

type IDownGradeInfoModalProps = {
    show: boolean
    currentLicense: ITierModel
    usedFeatures?: IUsedFeatures | undefined
    showAllFeaturesModal: (x?) => void
    backFunction: (x?) => void
    continueFunction: (x?) => void
}

type IFeaturesGroupProps = {
    label: string
    quantity: string | number | undefined
    label2?: string
    quantity2?: string | number | undefined
    type: string
}

const FeatureInfo: React.FC<IFeaturesGroupProps> = (props) => {
    const { label, quantity, label2, quantity2, type } = props
    return (
        <div
            className={`
                    ${styles.downGradeInfoModal__container__feature__info}
                   
                    ${
                        type === 'current'
                            ? styles.currentFeature
                            : type === 'new'
                            ? styles.newFeature
                            : styles.toDeleteFeatures
                    }
                `}
        >
            <p
                className={`
                    ${styles.downGradeInfoModal__container__feature__info__tag}
                    ${cx('bodyBoldCap')}
                    
                `}
            >
                {type === 'current'
                    ? t('subscription.currentPlan')
                    : type === 'new'
                    ? t('subscription.newPlan')
                    : t('subscription.toDelete')}
            </p>
            <p
                className={`
                    ${
                        styles.downGradeInfoModal__container__feature__info__quantity
                    }
                    ${cx('heading5 neutral1000')}
                `}
            >
                {quantity}
            </p>

            <p
                className={`
                    ${
                        styles.downGradeInfoModal__container__feature__info__description
                    }
                    ${cx('bodyRegularXS neutral700')}
                    
                `}
            >
                {label}
            </p>
            {label2 ? (
                <>
                    <p
                        className={`
                    ${
                        styles.downGradeInfoModal__container__feature__info__quantity
                    }
                    ${cx('heading5 neutral1000 marginTop18')}
                `}
                    >
                        {quantity2 || 0}
                    </p>

                    <p
                        className={`
                    ${
                        styles.downGradeInfoModal__container__feature__info__description
                    }
                    ${cx('bodyRegularXS neutral700')}
                    
                `}
                    >
                        {label2}
                    </p>
                </>
            ) : null}
        </div>
    )
}

const DownGradeInfoModal: React.FC<IDownGradeInfoModalProps> = (props) => {
    const {
        backFunction,
        continueFunction,
        showAllFeaturesModal,
        show,
        currentLicense,
        usedFeatures,
    } = props
    const { t } = useTranslation()
    const newLicense = useSelector(licensesSelectors.getNewLicense)
    const subscriptionInfo = useSelector(licensesSelectors.getSubscriptionInfo)
    const newLicenseDate = formatStringDate(
        subscriptionInfo?.currentPeriodEnd,
        'DD/MM/YYYY - HH:mm:ss'
    )

    const getFeaturesToDelete = (usedFeatureName: string, newFeatureName) => {
        const currentFeaturesQty =
            (usedFeatures && usedFeatures[usedFeatureName]) || 0
        const newFeaturesQty =
            (getTierLimitsValues(newLicense) &&
                getTierLimitsValues(newLicense)[newFeatureName]) ||
            0

        const substringResult = substring(currentFeaturesQty, newFeaturesQty)
        return substringResult > 0 ? substringResult : 0
    }

    return (
        <div
            className={`${styles.backgroundLayer} ${
                show ? styles.visibleModal : styles.hiddenModal
            }`}
        >
            <div className={styles.downGradeInfoModal}>
                <div className={styles.downGradeInfoModal__header}>
                    <div
                        className={styles.downGradeInfoModal__header__close}
                        onClick={() => backFunction()}
                    >
                        <CloseIcon />
                    </div>
                </div>
                <div className={styles.downGradeInfoModal__container}>
                    <h2
                        className={`${
                            styles.downGradeInfoModal__container__title
                        } ${cx('heading4 neutral1000')}`}
                    >
                        {t('subscription.downgradeInfoModalTitle1')}
                    </h2>
                    <h2
                        className={`${
                            styles.downGradeInfoModal__container__title
                        } ${cx('heading4 neutral1000')}`}
                    >
                        {t('subscription.downgradeInfoModalTitle2')}
                    </h2>
                    <p
                        className={`${
                            styles.downGradeInfoModal__container__description
                        } ${cx('bodyRegularLG neutral700')}`}
                    >
                        <Trans
                            i18nKey={'subscription.ifYouDowngrade'}
                            values={{
                                type: newLicense?.name,
                                date: newLicenseDate,
                            }}
                        />
                    </p>
                    <p
                        className={`${
                            styles.downGradeInfoModal__container__description
                        } ${cx('bodyRegularLG neutral700')}`}
                    >
                        <Trans i18nKey={'subscription.deleteItems'} />
                    </p>

                    <p
                        className={`${
                            styles.downGradeInfoModal__container__description
                        } ${cx('bodyRegularLG neutral700')}`}
                    >
                        <Trans
                            i18nKey={'subscription.ifNotdeleteItems'}
                            values={{ brand: brandLabels?.brand }}
                        />
                    </p>

                    <p
                        className={`${
                            styles.downGradeInfoModal__container__features
                        } ${cx('heading6 neutral1000 marginTop40')}`}
                    >
                        {t('subscription.features')}
                    </p>
                    <div
                        className={
                            styles.downGradeInfoModal__container__feature
                        }
                    >
                        <FeatureInfo
                            type="current"
                            label={t('subscription.verificationTemplates2')}
                            quantity={
                                getTierLimitsValues(newLicense)
                                    ?.verificationTemplates || 0
                            }
                        />
                        <FeatureInfo
                            type="new"
                            label={t('subscription.verificationTemplates2')}
                            quantity={
                                getTierLimitsValues(newLicense)
                                    ?.verificationTemplates || 0
                            }
                        />
                        <FeatureInfo
                            type="delete"
                            label={t('subscription.verificationTemplates2')}
                            quantity={getFeaturesToDelete(
                                'verificationTemplates',
                                'verificationTemplates'
                            )}
                        />
                    </div>

                    <p
                        className={`${
                            styles.downGradeInfoModal__container__features
                        } ${cx('heading6 neutral1000 marginTop40')}`}
                    >
                        {t('subscription.issuanceFeatures')}
                    </p>
                    <div
                        className={
                            styles.downGradeInfoModal__container__feature
                        }
                    >
                        <FeatureInfo
                            type="current"
                            label={t('subscription.issuanceTemplates2')}
                            quantity={
                                getTierLimitsValues(newLicense)
                                    ?.issuanceTemplates || 0
                            }
                        />
                        <FeatureInfo
                            type="new"
                            label={t('subscription.issuanceTemplates2')}
                            quantity={
                                getTierLimitsValues(newLicense)
                                    ?.issuanceTemplates || 0
                            }
                        />
                        <FeatureInfo
                            type="delete"
                            label={t('subscription.verificationTemplates2')}
                            quantity={getFeaturesToDelete(
                                'issuanceTemplates',
                                'issuanceTemplates'
                            )}
                        />
                    </div>

                    <p
                        className={`${
                            styles.downGradeInfoModal__container__features
                        } ${cx('heading6 neutral1000 marginTop40')}`}
                    >
                        {t('subscription.otherFeatures')}
                    </p>
                    <div
                        className={
                            styles.downGradeInfoModal__container__feature
                        }
                    >
                        <FeatureInfo
                            type="current"
                            label={t('subscription.dids')}
                            label2={t('subscription.apiKeys')}
                            quantity={
                                getTierLimitsValues(newLicense)?.dids || 0
                            }
                            quantity2={
                                getTierLimitsValues(newLicense)?.apiKeys || 0
                            }
                        />
                        <FeatureInfo
                            type="new"
                            label={t('subscription.dids')}
                            label2={t('subscription.apiKeys')}
                            quantity={
                                getTierLimitsValues(newLicense)?.dids || 0
                            }
                            quantity2={
                                getTierLimitsValues(newLicense)?.apiKeys || 0
                            }
                        />
                        <FeatureInfo
                            type="delete"
                            label={t('subscription.dids')}
                            label2={t('subscription.apiKeys')}
                            quantity={getFeaturesToDelete('dids', 'dids')}
                            quantity2={getFeaturesToDelete(
                                'apiKeys',
                                'apiKeys'
                            )}
                        />
                    </div>
                    <div
                        className={
                            styles.downGradeInfoModal__container__buttons
                        }
                    >
                        <LightButton
                            text={'public.back'}
                            disabled={false}
                            functionality={backFunction}
                        />
                        <DarkButton
                            text={'public.confirm'}
                            disabled={false}
                            functionality={continueFunction}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownGradeInfoModal
