import { PaymentMethodOption } from '../interfaces/interfaces'

import add from './images/add.svg'
import angleDown from './images/angle-down.svg'
import apiKey from './images/api-keys.svg'
import arrowCircleDown from './images/arrowCircleDown.svg'
import arrowForward from './images/arrow_forward.svg'
import gatacaFavicon from './images/branding/gataca/favicon.svg'
import incmFavicon from './images/branding/incm/favicon.png'
import incmLogoPNG from './images/branding/incm/incm-logo.png'
import incmLogoSVG from './images/branding/incm/incm-logo.svg'
import incmIcon from './images/branding/incm/incm.png'
import checkBadge from './images/check-badge.svg'
import checkCircle from './images/check-circle.svg'
import check from './images/check.svg'
import checkbox from './images/checkbox.svg'
import chevronLeft from './images/chevron-left.svg'
import chevronRight from './images/chevron-right.svg'
import clock from './images/clock.svg'
import closeLight from './images/close-light.svg'
import whiteClose from './images/close-white.svg'
import close from './images/close.svg'
import credential from './images/credential.svg'
import credentialsLight from './images/credentials-light.svg'
import credentials from './images/credentials.svg'
import did from './images/did.svg'
import editCard from './images/edit-card.svg'
import errorCircle from './images/errorCircle.svg'
import failCircle from './images/failCircle.svg'
import helpCircle from './images/helpCircle.svg'
import info from './images/info.svg'
import infoCircle from './images/infoCircle.svg'
import infoTable from './images/infoTable.svg'
import issuance from './images/issuance.svg'
import lightPlus from './images/light-plus.svg'
import link from './images/link.svg'
import loadingCircle from './images/loadingCircle.svg'
import logoPlatform from './images/logo-gataca-v2.png' //'./images/logo-gataca-v2.svg'
import logoGataca from './images/logo_gataca.svg'
import number from './images/number.svg'
import plus from './images/plus.svg'
import redWarning from './images/redWarning.svg'
import rightArrow from './images/rightArrow.svg'
import searchBar from './images/search-bar.svg'
import settings from './images/settingsMenuIcon.svg'
import textFile from './images/textFile.svg'
import verifier from './images/verifier.svg'
import warningFlag from './images/warning-flag.svg'
import warning from './images/warning.svg'
import warning1 from './newImages/warning1.png'
import appleBrandButton from './newImages/appleBrandButton.svg'
import blackTrashPng from './newImages/blackTrash.png'
import blackTrashSvg from './newImages/blackTrash.svg'
import googleBrandButton from './newImages/googleBrandButton.svg'
import greenArrowsPng from './newImages/greenArrows.png'
import greenArrowsSvg from './newImages/greenArrows.svg'
import greenCheckPng from './newImages/greenCheck.png'
import greenCheckSvg from './newImages/greenCheck.svg'
import greenMailCloudPng from './newImages/greenMailCloud.png'
import greenMailCloudSvg from './newImages/greenMailCloud.svg'
import greyTrashPng from './newImages/greyTrash.png'
import greyTrashSvg from './newImages/greyTrash.svg'
import redArrowsPng from './newImages/redArrows.png'
import redArrowsSvg from './newImages/redArrows.svg'
import redErrorPng from './newImages/redError.png'
import redErrorSvg from './newImages/redError.svg'
import unlockImgBlue from './images/branding/incm/unlock-img-blue.png'
import entityLogoIcon from './iconsLibrary/gatacaLogo/gatacaLogoIcon.svg'
import chipIcon from './iconsLibrary/simple/chip/chipIcon.svg'
import credentialIconDef from './iconsLibrary/credential/default/credentialIcon.svg'
import moreVerticalIcon from './iconsLibrary/simple/moreVertical/moreVerticalIcon.svg'
import pencilIcon from './iconsLibrary/simple/pencil/pencilIcon.svg'
import checkIcon from './iconsLibrary/simple/check/checkIcon.svg'
import chevronUp from './iconsLibrary/simple/chevronUp/chevronUpIcon.svg'
import chevronDown from './iconsLibrary/simple/chevronDown/chevronDownIcon.svg'
import warningIcon from './iconsLibrary/simple/warning/warningIcon.svg'
import unlockImg from './images/branding/gataca/unlock-img.png'
import updatePng from './newImages/update.png'
import updateSvg from './newImages/update.svg'
import yellowWarningPng from './newImages/yellowWarning.png'
import yellowWarningSvg from './newImages/yellowWarning.svg'
import folderFindEmptyPng from './newImages/folderFindEmpty.png'
import sandClockPng from './newImages/sandClock.png'
import downloadPurple from './newImages/downloadPurple.png'
import gatacaCredExample from './images/branding/gataca/cred-example.png'
import incmCredExample from './images/branding/incm/cred-example.png'
import walletExampleConnection from './newImages/walletExampleConnection.png'
import qrCodeExample from './newImages/qrCodeExample.png'
import saveBox from './newImages/saveBox.png'
import securityObjects from './newImages/securityObjects.png'
import urlLost from './newImages/urlLost.png'
import usersAge from './newImages/usersAge.png'
import scanToVerificate from './images/scanToVerificate.png'
import qrExample from './images/qr-example.png'
import iphoneStatusBar from './images/iphoneStatusBar.png'
import iphoneBottomIndicator from './images/iphoneBottomIndicator.png'
import iphoneToolbar from './images/iphoneToolbar.png'
import iphoneStatusBarSmall from './images/iphoneStatusBarSmall.png'
import iphoneBottomIndicatorSmall from './images/iphoneBottomIndicatorSmall.png'
import iphoneBrowserIcons from './images/iphoneBrowserIcons.png'
import downloadWallet from './images/downloadWallet.png'
import shareYourDataEn from './images/shareYourDataEn.png'
import shareYourDataEs from './images/shareYourDataEs.png'
import shareYourDataPt from './images/shareYourDataPt.png'
import getVerified from './images/getVerified.png'
import googleBrandButtonColor from './images/googleStoreColor.png'
import appleStoreBordered from './images/appleStoreBordered.png'

// CREDENTIAL ICONS
import emailCredIcon from './CredentialIcons/gat-icon-at.svg'
import phoneCredIcon from './CredentialIcons/gat-icon-smartphone.svg'
import addressCredIcon from './CredentialIcons/gat-icon-map-pin.svg'
import governmentCredIcon from './CredentialIcons/gat-icon-government.svg'
import universityCredIcon from './CredentialIcons/gat-icon-education.svg'
import studentIDCredIcon from './CredentialIcons/gat-icon-education.svg'
import studentCredIcon from './CredentialIcons/gat-icon-education.svg'
import facePhotoCredIcon from './CredentialIcons/gat-icon-profile-photo.svg'
import deviceInfoCredIcon from './CredentialIcons/gat-icon-chip.svg'
import vidCredIcon from './CredentialIcons/gat-icon-id.svg'
import diplomaCredIcon from './CredentialIcons/gat-icon-award.svg'
import credentialCardIcon from './CredentialIcons/gat-icon-credential.svg'
import userPlusIcon from './CredentialIcons/gat-icon-user-plus.svg'

import { getBrand } from '../utils/brandThemeUtil'

export const gaImages = {
    arrowForward: { src: arrowForward, alt: 'public.arrowForwardIcon' },
    angleDown: { src: angleDown, alt: 'public.angleDownIcon' },
    credential: { src: credentials, alt: 'public.credentialIcon' },
    credentials: { src: credential, alt: 'public.credentialIcon' },
    verifier: { src: verifier, alt: 'public.verifierIcon' },
    close: { src: close, alt: 'public.closeIcon' },
    plus: { src: plus, alt: 'public.plusIcon' },
    searchBar: { src: searchBar, alt: 'public.searchIcon' },
    checkCircle: { src: checkCircle, alt: 'public.circleWidthCheckIcon' },
    checkBadge: { src: checkBadge, alt: 'public.badgeCheckIcon' },
    issuance: { src: issuance, alt: 'public.issuanceIcon' },
    apiKeys: { src: apiKey, alt: 'public.keyIcon' },
    did: { src: did, alt: 'public.didIcon' },
    info: { src: info, alt: 'public.infoIcon' },
    helpCircle: { src: helpCircle, alt: 'public.supportIcon' },
    editCard: { src: editCard, alt: 'public.editCardIcon' },
    number: { src: number, alt: 'public.numberIcon' },
    link: { src: link, alt: 'public.linkIcon' },
    arrowCircleDown: {
        src: arrowCircleDown,
        alt: 'public.arrowCircleDownIcon',
    },
    checkbox: { src: checkbox, alt: 'public.checkboxIcon' },
    clock: { src: clock, alt: 'public.clockIcon' },
    textFile: { src: textFile, alt: 'public.textFileIcon' },
    closeLight: { src: closeLight, alt: 'public.closeIcon' },
    loadingCircle: { src: loadingCircle, alt: 'public.loading' },
    infoTable: { src: infoTable, alt: 'public.menu' },
    credentialsLight: { src: credentialsLight, alt: 'public.credentialsIcon' },
    warning: { src: warning, alt: 'public.error' },
    lightPlus: { src: lightPlus, alt: 'public.plusIcon' },
    whiteClose: { src: whiteClose, alt: 'public.closeIcon' },
    check: { src: check, alt: 'public.checkIcon' },
    rightArrow: { src: rightArrow, alt: 'public.arrowForwardIcon' },
    warningFlag: { src: warningFlag, alt: 'public.warning' },
    failCircle: { src: failCircle, alt: 'public.warning' },
    infoCircle: { src: infoCircle, alt: 'public.warning' },
    settingsIcon: { src: settings, alt: 'public.appsIcon' },
    chevronRight: { src: chevronRight, alt: 'Right Icon' },
    chevronLeft: { src: chevronLeft, alt: 'Left Icon' },
    addIcon: { src: add, alt: 'Add Icon' },
    errorCircleIcon: { src: errorCircle, alt: 'Error Icon' },
    redWarning: { src: redWarning, alt: 'Warning icon' },
    // New images
    greenCheckPng: { src: greenCheckPng, alt: '' },
    greenCheckSvg: { src: greenCheckSvg, alt: '' },
    redErrorPng: { src: redErrorPng, alt: '' },
    redErrorSvg: { src: redErrorSvg, alt: '' },
    updatePng: { src: updatePng, alt: '' },
    updateSvg: { src: updateSvg, alt: '' },
    greenArrowsPng: { src: greenArrowsPng, alt: '' },
    greenArrowsSvg: { src: greenArrowsSvg, alt: '' },
    redArrowsPng: { src: redArrowsPng, alt: '' },
    redArrowsSvg: { src: redArrowsSvg, alt: '' },
    greenMailCloudPng: { src: greenMailCloudPng, alt: '' },
    greenMailCloudSvg: { src: greenMailCloudSvg, alt: '' },
    blackTrashPng: { src: blackTrashPng, alt: '' },
    blackTrashSvg: { src: blackTrashSvg, alt: '' },
    yellowWarningPng: { src: yellowWarningPng, alt: '' },
    yellowWarningSvg: { src: yellowWarningSvg, alt: '' },
    greyTrashPng: { src: greyTrashPng, alt: '' },
    greyTrashSvg: { src: greyTrashSvg, alt: '' },
    unlockImg: { src: unlockImg, alt: '' },
    unlockImgBlue: { src: unlockImgBlue, alt: '' },
    googleBrandButton: { src: googleBrandButton, alt: '' },
    appleBrandButton: { src: appleBrandButton, alt: '' },
    chipIcon: { src: chipIcon, alt: '' },
    credentialIconDefault: { src: credentialIconDef, alt: '' },
    moreVerticalIcon: { src: moreVerticalIcon, alt: '' },
    pencilIcon: { src: pencilIcon, alt: '' },
    checkIcon: { src: checkIcon, alt: '' },
    logoGataca: { src: logoGataca, alt: '' },
    chevronUpIcon: { src: chevronUp, alt: '' },
    chevronDownIcon: { src: chevronDown, alt: '' },
    warningIcon: { src: warningIcon, alt: '' },
    incmIcon: { src: incmIcon, alt: '' },
    folderFindEmpty: { src: folderFindEmptyPng, alt: '' },
    sandClock: { src: sandClockPng, alt: '' },
    warning1: { src: warning1, alt: '' },
    walletExampleConnection: {
        src: walletExampleConnection,
        alt: 'public.walletExampleConnection',
    },
    qrCodeExample: { src: qrCodeExample, alt: '' },
    saveBox: { src: saveBox, alt: '' },
    securityObjects: { src: securityObjects, alt: '' },
    urlLost: { src: urlLost, alt: '' },
    usersAge: { src: usersAge, alt: '' },
    scanToVerificate: { src: scanToVerificate, alt: '' },
    qrExample: { src: qrExample, alt: '' },
    iphoneStatusBar: { src: iphoneStatusBar, alt: '' },
    iphoneBottomIndicator: { src: iphoneBottomIndicator, alt: '' },
    iphoneToolbar: { src: iphoneToolbar, alt: '' },
    iphoneStatusBarSmall: { src: iphoneStatusBarSmall, alt: '' },
    iphoneBottomIndicatorSmall: { src: iphoneBottomIndicatorSmall, alt: '' },
    iphoneBrowserIcons: { src: iphoneBrowserIcons, alt: '' },
    downloadWallet: { src: downloadWallet, alt: '' },
    shareYourDataEn: { src: shareYourDataEn, alt: '' },
    shareYourDataEs: { src: shareYourDataEs, alt: '' },
    shareYourDataPt: { src: shareYourDataPt, alt: '' },
    getVerified: { src: getVerified, alt: '' },
    downloadPurple: { src: downloadPurple, alt: '' },
    googleBrandButtonColor: { src: googleBrandButtonColor, alt: '' },
    appleStoreBordered: { src: appleStoreBordered, alt: '' },
}

/*
 * To understand the correspondence between imagesByBrand[property] and brands,
 * see data used for brands in brandThemUtil
 */
const imagesByBrand = {
    Z2F0YWNh: {
        logoPlatform: { src: logoPlatform, alt: 'public.gatacaLogo' },
        entityLogoIcon: { src: entityLogoIcon, alt: '' },
        brandLogo: { src: logoGataca, alt: 'public.gatacaLogo' },
        unlock: { src: unlockImg, alt: '' },
        favicon: { src: gatacaFavicon },
        credExample: { src: gatacaCredExample },
    },
    aW5jbQ: {
        logoPlatform: { src: incmIcon, alt: 'INCM logo' },
        entityLogoIcon: { src: incmLogoPNG, alt: 'INCM logo' },
        brandLogo: { src: incmLogoSVG, alt: 'INCM logo' },
        unlock: { src: unlockImgBlue, alt: '' },
        favicon: { src: incmFavicon },
        credExample: { src: incmCredExample },
    },
}

const brand = getBrand()
export const brandImages = imagesByBrand[brand]

const colorsByBrand = {
    Z2F0YWNh: {
        primaryColor: '#4745B7',
    },
    aW5jbQ: {
        primaryColor: '#0094E7',
    },
}

export const brandColors = colorsByBrand[brand]

const paymentsMethodByBrand = {
    Z2F0YWNh: ['One'],
    aW5jbQ: ['None'],
}

export const brandPaymentMethod = paymentsMethodByBrand[brand]

const labelsByBrand = {
    Z2F0YWNh: {
        brand: 'Gataca',
        walletName: 'Gataca Wallet',
        studioName: 'Gataca Studio',
        favicon: '',
        documentationLink: 'https://gataca.atlassian.net/wiki/spaces/DOCS',
        termsOfServiceLink: 'https://gataca.io/terms-of-service-studio',
        privacypolicylink: 'https://gataca.io/privacy-policy',
        marketAndroidLink:
            'https://play.google.com/store/apps/details?id=com.gataca.identity',
        marketIoslink: 'https://apps.apple.com/us/app/gataca/id1498607616',
        contactlink: 'https://gataca.io/company/contact',
        gtm: 'https://www.googletagmanager.com/gtag/js?id=G-LSRNQ0MKEY',
        defaultCountry: 54, // Spain
    },
    aW5jbQ: {
        brand: 'INCM',
        walletName: 'Carteira INCM',
        studioName: 'INCM Studio',
        // TODO Change by INCM Links when they are available
        documentationLink: 'https://gataca.atlassian.net/wiki/spaces/DOCS',
        termsOfServiceLink: 'https://gataca.io/terms-of-service-studio',
        privacypolicylink: 'https://gataca.io/privacy-policy',
        marketAndroidLink:
            'https://play.google.com/store/apps/details?id=pt.incm.carteiradigital&hl=pt&gl=pt',
        marketIoslink:
            'https://apps.apple.com/pt/app/carteira-incm/id6463626242',
        contactlink: 'https://gataca.io/company/contact',
        gtm: undefined,
        defaultCountry: 131, // Portugal
    },
}

export const brandLabels = labelsByBrand[brand]

export const CredentialIcons: any = {
    email: emailCredIcon,
    phone: phoneCredIcon,
    address: addressCredIcon,
    government: governmentCredIcon,
    academicInstitution: universityCredIcon,
    university: universityCredIcon,
    gatacaEmployee: brandImages?.entityLogoIcon?.src,
    gatacaRole: brandImages?.entityLogoIcon?.src,
    studentId: studentIDCredIcon,
    student: studentCredIcon,
    facePhoto: facePhotoCredIcon,
    deviceInfo: deviceInfoCredIcon,
    vid: vidCredIcon,
    diploma: diplomaCredIcon,
    credential: credentialCardIcon,
    userPlus: userPlusIcon,
}

export const OptionsIcons: any = {
    userPlus: userPlusIcon,
}

export const dataAgreementPurposeOptions = [
    'Identify verification',
    'Fraud detection and prevention',
    'Access control',
    'Service Provision',
    'Service Optimization',
    'Service Personalisation',
    'Marketing',
    'Commercial Interests',
    'Research & Development',
    'Unknown',
]

export enum DataAgreementPurpose {
    'Identify verification' = 'Identify verification',
    'Fraud detection and prevention' = 'Fraud detection and prevention',
    'Access control' = 'Access control',
    'Service Provision' = 'Service Provision',
    'Service Optimization' = 'Service Optimization',
    'Service Personalisation' = 'Service Personalisation',
    'Marketing' = 'Marketing',
    'Commercial Interests' = 'Commercial Interests',
    'Research & Development' = 'Research & Development',
    'Unknown' = 'Unknown',
}

export enum LegalBasis {
    'consent' = 'consent',
    'legal_obligation' = 'legal_obligation',
    'contract' = 'contract',
    'vital_interest' = 'vital_interest',
    'public_task' = 'public_task',
    'legitimate_interest' = 'legitimate_interest',
}

export enum MethodOfUse {
    'none' = 'none',
    'data-source' = 'data-source',
    'data-using-service' = 'data-using-service',
}

export const legalBasisOptions = [
    'consent',
    'legal_obligation',
    'contract',
    'vital_interest',
    'public_task',
    'legitimate_interest',
]

export const methodOfUseOptions = ['none', 'data-source', 'data-using-service']

export const dataPolicyEnabledOptions = [
    {
        label: 'public.yes',
        value: 'Y',
    },
    {
        label: 'public.no',
        value: 'N',
    },
]

export enum PrivateKeyTypes {
    DB = 'DB',
    FILE = 'FILE',
    PKCS11 = 'PKCS11',
}

export enum TextAlignTypes {
    left = 'left',
    center = 'center',
    right = 'right',
}

export enum CellContentTypes {
    text = 'text',
    array = 'array',
    name = 'name',
    number = 'number',
    date = 'date',
    icon = 'icon',
    checkbox = 'checkbox',
    action = 'action',
    detail = 'detail',
    tag = 'tag',
    modal = 'modal',
}

export enum ActionStatusTypes {
    loading = 'loading',
    success = 'success',
    failed = 'failed',
    neutral = 'neutral',
}

export enum ApiKeyStatusTypes {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export const privateKeyTypeOptions = [
    { text: 'createDid.step2.database', value: 'DB' },
    { text: 'createDid.step2.filesystem', value: 'FILE' },
    { text: 'createDid.step2.hsm', value: 'PKCS11' },
]

export const ledgerOptions = [
    { text: 'createDid.step4.ethereum', value: 'ETH' },
    { text: 'createDid.step4.hyperLedgerFabric', value: 'GHLF' },
    { text: 'createDid.step4.hyperLedgerBesu', value: 'GHLB' },
    { text: 'createDid.step4.bitcoin', value: 'BTC', disabled: true },
    { text: 'createDid.step4.alastria', value: 'ALA', disabled: true },
    { text: 'createDid.step4.ebsi', value: 'EBSI', disabled: true },
]

export const apiKeysStatusOptions = [
    { text: 'public.active', value: 'ACTIVE' },
    { text: 'public.inactive', value: 'INACTIVE' },
]

export const purposesTypes = {
    authentication: 'Authentication',
    nonRepudiation: 'Non Repudiation',
    regulation: 'Regulation',
    application: 'Application Logic',
    analysis: 'Analysis',
    sharing: 'Third Party Sharing',
}

export const purposesTypesList = [
    {
        text: 'public.authentication',
        value: 'authentication',
        id: 'authentication',
    },
    {
        text: 'public.nonRepudiation',
        value: 'nonRepudiation',
        id: 'nonRepudiation',
    },
    { text: 'public.regulation', value: 'regulation', id: 'regulation' },
    { text: 'public.application', value: 'application', id: 'application' },
    { text: 'public.analysis', value: 'analysis', id: 'analysis' },
    { text: 'public.sharing', value: 'sharing', id: 'sharing' },
]

export const securityFactorsList = [
    { text: 'public.bimetric', value: 'biometric' },
    { text: 'public.email', value: 'email' },
    { text: 'public.silent', value: 'silent' },
    { text: 'public.sms', value: 'sms' },
    { text: 'public.remoteFaceId', value: 'remoteFaceId' },
    { text: 'public.faceSdk', value: 'faceSDK' },
]

export const activeCredentialActions = [
    { text: 'credentials.suspend', value: 'SUSPENDED', color: 'yellow' },
    { text: 'credentials.revoke', value: 'REVOKED', color: 'red' },
]

export const suspendedCredentialActions = [
    { text: 'credentials.activate', value: 'ISSUED', color: 'green' },
    { text: 'credentials.revoke', value: 'REVOKED', color: 'red' },
]

export const didTypeOptions = [
    { text: 'dids.issuer', value: 'ISSUER', color: 'salmon' },
    { text: 'dids.verifier', value: 'VERIFIER', color: 'gaBlue' },
]

export const didMethodOptions = [
    { text: 'Gataca', value: 'gatc' },
    { text: 'EBSI', value: 'ebsi' },
]

export const countriesAndStates = [
    {
        name_es: 'Afganistán',
        name_en: 'Afghanistan',
        name_pt: 'Afeganistão',
        states: [
            'Badakhshan',
            'Badghis',
            'Baghlan',
            'Balkh',
            'Bamian',
            'Daykondi',
            'Farah',
            'Faryab',
            'Ghazni',
            'Ghowr',
            'Helmand',
            'Herat',
            'Jowzjan',
            'Kabul',
            'Kandahar',
            'Kapisa',
            'Khost',
            'Konar',
            'Kondoz',
            'Laghman',
            'Lowgar',
            'Nangarhar',
            'Nimruz',
            'Nurestan',
            'Oruzgan',
            'Paktia',
            'Paktika',
            'Panjshir',
            'Parvan',
            'Samangan',
            'Sar-e Pol',
            'Takhar',
            'Vardak',
            'Zabol',
        ],
    },
    {
        name_es: 'Albania',
        name_en: 'Albania',
        name_pt: 'Albânia',
        states: [
            'Berat',
            'Dibres',
            'Durres',
            'Elbasan',
            'Fier',
            'Gjirokastre',
            'Korce',
            'Kukes',
            'Lezhe',
            'Shkoder',
            'Tirane',
            'Vlore',
        ],
    },
    {
        name_es: 'Alemania',
        name_en: 'Germany',
        name_pt: 'Alemanha',
        states: [
            'Baden-Wuerttemberg',
            'Bayern',
            'Berlin',
            'Brandenburg',
            'Bremen',
            'Hamburg',
            'Hessen',
            'Mecklenburg-Vorpommern',
            'Niedersachsen',
            'Nordrhein-Westfalen',
            'Rheinland-Pfalz',
            'Saarland',
            'Sachsen',
            'Sachsen-Anhalt',
            'Schleswig-Holstein',
            'Thueringen',
        ],
    },
    {
        name_es: 'Andorra',
        name_en: 'Andorra',
        name_pt: 'Andorra',
        states: [
            'Andorra la Vella',
            'Canillo',
            'Encamp',
            'Escaldes-Engordany',
            'La Massana',
            'Ordino',
            'Sant Julia de Loria',
        ],
    },
    {
        name_es: 'Angola',
        name_en: 'Angola',
        name_pt: 'Angola',
        states: [
            'Bengo',
            'Benguela',
            'Bie',
            'Cabinda',
            'Cuando Cubango',
            'Cuanza Norte',
            'Cuanza Sul',
            'Cunene',
            'Huambo',
            'Huila',
            'Luanda',
            'Lunda Norte',
            'Lunda Sul',
            'Malanje',
            'Moxico',
            'Namibe',
            'Uige',
            'Zaire',
        ],
    },
    {
        name_es: 'Antigua y Barbuda',
        name_en: 'Antigua and Barbuda',
        name_pt: 'Antígua e Barbuda',
        states: [
            'Barbuda',
            'Redonda',
            'Saint George',
            'Saint John',
            'Saint Mary',
            'Saint Paul',
            'Saint Peter',
            'Saint Philip',
        ],
    },
    {
        name_es: 'Antártida',
        name_en: 'Antarctica',
        name_pt: 'Antártica',
        states: ['Antarctica'],
    },
    {
        name_es: 'Arabia Saudita',
        name_en: 'Saudi Arabia',
        name_pt: 'Arábia Saudita',
        states: [
            'Al Bahah',
            'Al Hudud ash Shamaliyah',
            'Al Jawf',
            'Al Madinah',
            'Al Qasim',
            'Ar Riyad',
            'Ash Sharqiyah',
            "'Asir",
            "Ha'il",
            'Jizan',
            'Makkah',
            'Najran',
            'Tabuk',
        ],
    },
    {
        name_es: 'Argelia',
        name_en: 'Algeria',
        name_pt: 'Argélia',
        states: [
            'Adrar',
            'Ain Defla',
            'Ain Temouchent',
            'Alger',
            'Annaba',
            'Batna',
            'Bechar',
            'Bejaia',
            'Biskra',
            'Blida',
            'Bordj Bou Arreridj',
            'Bouira',
            'Boumerdes',
            'Chlef',
            'Constantine',
            'Djelfa',
            'El Bayadh',
            'El Oued',
            'El Tarf',
            'Ghardaia',
            'Guelma',
            'Illizi',
            'Jijel',
            'Khenchela',
            'Laghouat',
            'Muaskar',
            'Medea',
            'Mila',
            'Mostaganem',
            "M'Sila",
            'Naama',
            'Oran',
            'Ouargla',
            'Oum el Bouaghi',
            'Relizane',
            'Saida',
            'Setif',
            'Sidi Bel Abbes',
            'Skikda',
            'Souk Ahras',
            'Tamanghasset',
            'Tebessa',
            'Tiaret',
            'Tindouf',
            'Tipaza',
            'Tissemsilt',
            'Tizi Ouzou',
            'Tlemcen',
        ],
    },
    {
        name_es: 'Argentina',
        name_en: 'Argentina',
        name_pt: 'Argentina',
        states: [
            'Buenos Aires',
            'Buenos Aires Capital',
            'Catamarca',
            'Chaco',
            'Chubut',
            'Cordoba',
            'Corrientes',
            'Entre Rios',
            'Formosa',
            'Jujuy',
            'La Pampa',
            'La Rioja',
            'Mendoza',
            'Misiones',
            'Neuquen',
            'Rio Negro',
            'Salta',
            'San Juan',
            'San Luis',
            'Santa Cruz',
            'Santa Fe',
            'Santiago del Estero',
            'Tierra del Fuego',
            'Tucuman',
        ],
    },
    {
        name_es: 'Armenia',
        name_en: 'Armenia',
        name_pt: 'Armênia',
        states: [
            'Aragatsotn',
            'Ararat',
            'Armavir',
            "Geghark'unik'",
            "Kotayk'",
            'Lorri',
            'Shirak',
            "Syunik'",
            'Tavush',
            "Vayots' Dzor",
            'Yerevan',
        ],
    },
    {
        name_es: 'Australia',
        name_en: 'Australia',
        name_pt: 'Austrália',
        states: ['Australia'],
    },
    {
        name_es: 'Austria',
        name_en: 'Austria',
        name_pt: 'Áustria',
        states: [
            'Burgenland',
            'Kaernten',
            'Niederoesterreich',
            'Oberoesterreich',
            'Salzburg',
            'Steiermark',
            'Tirol',
            'Vorarlberg',
            'Wien',
        ],
    },
    {
        name_es: 'Azerbaiyán',
        name_en: 'Azerbaijan',
        name_pt: 'Azerbaijão',
        states: [
            'Abseron Rayonu',
            'Agcabadi Rayonu',
            'Agdam Rayonu',
            'Agdas Rayonu',
            'Agstafa Rayonu',
            'Agsu Rayonu',
            'Astara Rayonu',
            'Balakan Rayonu',
            'Barda Rayonu',
            'Beylaqan Rayonu',
            'Bilasuvar Rayonu',
            'Cabrayil Rayonu',
            'Calilabad Rayonu',
            'Daskasan Rayonu',
            'Davaci Rayonu',
            'Fuzuli Rayonu',
            'Gadabay Rayonu',
            'Goranboy Rayonu',
            'Goycay Rayonu',
            'Haciqabul Rayonu',
            'Imisli Rayonu',
            'Ismayilli Rayonu',
            'Kalbacar Rayonu',
            'Kurdamir Rayonu',
            'Lacin Rayonu',
            'Lankaran Rayonu',
            'Lerik Rayonu',
            'Masalli Rayonu',
            'Neftcala Rayonu',
            'Oguz Rayonu',
            'Qabala Rayonu',
            'Qax Rayonu',
            'Qazax Rayonu',
            'Qobustan Rayonu',
            'Quba Rayonu',
            'Qubadli Rayonu',
            'Qusar Rayonu',
            'Saatli Rayonu',
            'Sabirabad Rayonu',
            'Saki Rayonu',
            'Salyan Rayonu',
            'Samaxi Rayonu',
            'Samkir Rayonu',
            'Samux Rayonu',
            'Siyazan Rayonu',
            'Susa Rayonu',
            'Tartar Rayonu',
            'Tovuz Rayonu',
            'Ucar Rayonu',
            'Xacmaz Rayonu',
            'Xanlar Rayonu',
            'Xizi Rayonu',
            'Xocali Rayonu',
            'Xocavand Rayonu',
            'Yardimli Rayonu',
            'Yevlax Rayonu',
            'Zangilan Rayonu',
            'Zaqatala Rayonu',
            'Zardab Rayonu',
            'Ali Bayramli Sahari',
            'Baki Sahari',
            'Ganca Sahari',
            'Lankaran Sahari',
            'Mingacevir Sahari',
            'Naftalan Sahari',
            'Saki Sahari',
            'Sumqayit Sahari',
            'Susa Sahari',
            'Xankandi Sahari',
            'Yevlax Sahari',
            'Naxcivan Muxtar',
        ],
    },
    {
        name_es: 'Bahamas',
        name_en: 'Bahamas',
        name_pt: 'Bahamas',
        states: [
            'Acklins and Crooked Islands',
            'Bimini',
            'Cat Island',
            'Exuma',
            'Freeport',
            'Fresh Creek',
            "Governor's Harbour",
            'Green Turtle Cay',
            'Harbour Island',
            'High Rock',
            'Inagua',
            'Kemps Bay',
            'Long Island',
            'Marsh Harbour',
            'Mayaguana',
            'New Providence',
            'Nichollstown and Berry Islands',
            'Ragged Island',
            'Rock Sound',
            'Sandy Point',
            'San Salvador and Rum Cay',
        ],
    },
    {
        name_es: 'Banglades',
        name_en: 'Bangladesh',
        name_pt: 'Bangladesh',
        states: [
            'Barisal',
            'Chittagong',
            'Dhaka',
            'Khulna',
            'Rajshahi',
            'Sylhet',
        ],
    },
    {
        name_es: 'Barbados',
        name_en: 'Barbados',
        name_pt: 'Barbados',
        states: [
            'Christ Church',
            'Saint Andrew',
            'Saint George',
            'Saint James',
            'Saint John',
            'Saint Joseph',
            'Saint Lucy',
            'Saint Michael',
            'Saint Peter',
            'Saint Philip',
            'Saint Thomas',
        ],
    },
    {
        name_es: 'Baréin',
        name_en: 'Bahrain',
        name_pt: 'Bahrein',
        states: [
            'Al Hadd',
            'Al Manamah',
            'Al Mintaqah al Gharbiyah',
            'Al Mintaqah al Wusta',
            'Al Mintaqah ash Shamaliyah',
            'Al Muharraq',
            "Ar Rifa' wa al Mintaqah al Janubiyah",
            'Jidd Hafs',
            'Madinat Hamad',
            "Madinat 'Isa",
            'Juzur Hawar',
            'Sitrah',
        ],
    },
    {
        name_es: 'Belice',
        name_en: 'Belize',
        name_pt: 'Belize',
        states: [
            'Belize',
            'Cayo',
            'Corozal',
            'Orange Walk',
            'Stann Creek',
            'Toledo',
        ],
    },
    {
        name_es: 'Benin',
        name_en: 'Benin',
        name_pt: 'Benim',
        states: [
            'Alibori',
            'Atakora',
            'Atlantique',
            'Borgou',
            'Collines',
            'Donga',
            'Kouffo',
            'Littoral',
            'Mono',
            'Oueme',
            'Plateau',
            'Zou',
        ],
    },
    {
        name_es: 'Bermudas',
        name_en: 'Bermuda',
        name_pt: 'Bermudas',
        states: [
            'Devonshire',
            'Hamilton',
            'Hamilton',
            'Paget',
            'Pembroke',
            'Saint George',
            "Saint George's",
            'Sandys',
            "Smith's",
            'Southampton',
            'Warwick',
        ],
    },
    {
        name_es: 'Bielorrusia',
        name_en: 'Belarus',
        name_pt: 'Bielorrússia',
        states: [
            'Brest',
            'Homyel',
            'Horad Minsk',
            'Hrodna',
            'Mahilyow',
            'Minsk',
            'Vitsyebsk',
        ],
    },
    {
        name_es: 'Bolivia',
        name_en: 'Bolivia',
        name_pt: 'Bolívia',
        states: [
            'Chuquisaca',
            'Cochabamba',
            'Beni',
            'La Paz',
            'Oruro',
            'Pando',
            'Potosi',
            'Santa Cruz',
            'Tarija',
        ],
    },
    {
        name_es: 'Bosnia-Herzegovina',
        name_en: 'Bosnia and Herzegovina',
        name_pt: 'Bósnia e Herzegovina',
        states: [
            'Una-Sana [Federation]',
            'Posavina [Federation]',
            'Tuzla [Federation]',
            'Zenica-Doboj [Federation]',
            'Bosnian Podrinje [Federation]',
            'Central Bosnia [Federation]',
            'Herzegovina-Neretva [Federation]',
            'West Herzegovina [Federation]',
            'Sarajevo [Federation]',
            ' West Bosnia [Federation]',
            'Banja Luka [RS]',
            'Bijeljina [RS]',
            'Doboj [RS]',
            'Fo?a [RS]',
            'Sarajevo-Romanija [RS]',
            'Trebinje [RS]',
            'Vlasenica [RS]',
        ],
    },
    {
        name_es: 'Botsuana',
        name_en: 'Botswana',
        name_pt: 'Botsuana',
        states: [
            'Central',
            'Ghanzi',
            'Kgalagadi',
            'Kgatleng',
            'Kweneng',
            'North East',
            'North West',
            'South East',
            'Southern',
        ],
    },
    {
        name_es: 'Brasil',
        name_en: 'Brazil',
        name_pt: 'Brasil',
        states: [
            'Acre',
            'Alagoas',
            'Amapa',
            'Amazonas',
            'Bahia',
            'Ceara',
            'Distrito Federal',
            'Espirito Santo',
            'Goias',
            'Maranhao',
            'Mato Grosso',
            'Mato Grosso do Sul',
            'Minas Gerais',
            'Para',
            'Paraiba',
            'Parana',
            'Pernambuco',
            'Piaui',
            'Rio de Janeiro',
            'Rio Grande do Norte',
            'Rio Grande do Sul',
            'Rondonia',
            'Roraima',
            'Santa Catarina',
            'Sao Paulo',
            'Sergipe',
            'Tocantins',
        ],
    },
    {
        name_es: 'Bulgaria',
        name_en: 'Bulgaria',
        name_pt: 'Bulgária',
        states: [
            'Blagoevgrad',
            'Burgas',
            'Dobrich',
            'Gabrovo',
            'Khaskovo',
            'Kurdzhali',
            'Kyustendil',
            'Lovech',
            'Montana',
            'Pazardzhik',
            'Pernik',
            'Pleven',
            'Plovdiv',
            'Razgrad',
            'Ruse',
            'Shumen',
            'Silistra',
            'Sliven',
            'Smolyan',
            'Sofiya',
            'Sofiya-Grad',
            'Stara Zagora',
            'Turgovishte',
            'Varna',
            'Veliko Turnovo',
            'Vidin',
            'Vratsa',
            'Yambol',
        ],
    },
    {
        name_es: 'Burkina Faso',
        name_en: 'Burkina Faso',
        name_pt: 'Burkina Faso',
        states: [
            'Bale',
            'Bam',
            'Banwa',
            'Bazega',
            'Bougouriba',
            'Boulgou',
            'Boulkiemde',
            'Comoe',
            'Ganzourgou',
            'Gnagna',
            'Gourma',
            'Houet',
            'Ioba',
            'Kadiogo',
            'Kenedougou',
            'Komondjari',
            'Kompienga',
            'Kossi',
            'Koulpelogo',
            'Kouritenga',
            'Kourweogo',
            'Leraba',
            'Loroum',
            'Mouhoun',
            'Namentenga',
            'Nahouri',
            'Nayala',
            'Noumbiel',
            'Oubritenga',
            'Oudalan',
            'Passore',
            'Poni',
            'Sanguie',
            'Sanmatenga',
            'Seno',
            'Sissili',
            'Soum',
            'Sourou',
            'Tapoa',
            'Tuy',
            'Yagha',
            'Yatenga',
            'Ziro',
            'Zondoma',
            'Zoundweogo',
        ],
    },
    {
        name_es: 'Burundi',
        name_en: 'Burundi',
        name_pt: 'Burundi',
        states: [
            'Bubanza',
            'Bujumbura Mairie',
            'Bujumbura Rural',
            'Bururi',
            'Cankuzo',
            'Cibitoke',
            'Gitega',
            'Karuzi',
            'Kayanza',
            'Kirundo',
            'Makamba',
            'Muramvya',
            'Muyinga',
            'Mwaro',
            'Ngozi',
            'Rutana',
            'Ruyigi',
        ],
    },
    {
        name_es: 'Butan',
        name_en: 'Bhutan',
        name_pt: 'Butão',
        states: [
            'Bumthang',
            'Chukha',
            'Dagana',
            'Gasa',
            'Haa',
            'Lhuntse',
            'Mongar',
            'Paro',
            'Pemagatshel',
            'Punakha',
            'Samdrup Jongkhar',
            'Samtse',
            'Sarpang',
            'Thimphu',
            'Trashigang',
            'Trashiyangste',
            'Trongsa',
            'Tsirang',
            'Wangdue Phodrang',
            'Zhemgang',
        ],
    },
    {
        name_es: 'Bélgica',
        name_en: 'Belgium',
        name_pt: 'Bélgica',
        states: [
            'Antwerpen',
            'Brabant Wallon',
            'Brussels',
            'Flanders',
            'Hainaut',
            'Liege',
            'Limburg',
            'Luxembourg',
            'Namur',
            'Oost-Vlaanderen',
            'Vlaams-Brabant',
            'Wallonia',
            'West-Vlaanderen',
        ],
    },
    {
        name_es: 'Cabo Verde',
        name_en: 'Cape Verde',
        name_pt: 'Cabo Verde',
        states: ['Cape Verde'],
    },
    {
        name_es: 'Camboya',
        name_en: 'Cambodia',
        name_pt: 'Camboja',
        states: [
            'Banteay Mean Chey',
            'Batdambang',
            'Kampong Cham',
            'Kampong Chhnang',
            'Kampong Spoe',
            'Kampong Thum',
            'Kampot',
            'Kandal',
            'Koh Kong',
            'Kracheh',
            'Mondol Kiri',
            'Otdar Mean Chey',
            'Pouthisat',
            'Preah Vihear',
            'Prey Veng',
            'Rotanakir',
            'Siem Reab',
            'Stoeng Treng',
            'Svay Rieng',
            'Takao',
            'Keb',
            'Pailin',
            'Phnom Penh',
            'Preah Seihanu',
        ],
    },
    {
        name_es: 'Camerún',
        name_en: 'Cameroon',
        name_pt: 'Camarões',
        states: [
            'Adamaoua',
            'Centre',
            'Est',
            'Extreme-Nord',
            'Littoral',
            'Nord',
            'Nord-Ouest',
            'Ouest',
            'Sud',
            'Sud-Ouest',
        ],
    },
    {
        name_es: 'Canadá',
        name_en: 'Canada',
        name_pt: 'Canadá',
        states: [
            'Alberta',
            'British Columbia',
            'Manitoba',
            'New Brunswick',
            'Newfoundland and Labrador',
            'Northwest Territories',
            'Nova Scotia',
            'Nunavut',
            'Ontario',
            'Prince Edward Island',
            'Quebec',
            'Saskatchewan',
            'Yukon Territory',
        ],
    },
    {
        name_es: 'Chad',
        name_en: 'Chad',
        name_pt: 'Chade',
        states: [
            'Batha',
            'Biltine',
            'Borkou-Ennedi-Tibesti',
            'Chari-Baguirmi',
            'Guéra',
            'Kanem',
            'Lac',
            'Logone Occidental',
            'Logone Oriental',
            'Mayo-Kebbi',
            'Moyen-Chari',
            'Ouaddaï',
            'Salamat',
            'Tandjile',
        ],
    },
    {
        name_es: 'Chile',
        name_en: 'Chile',
        name_pt: 'Chile',
        states: [
            'Aysen',
            'Antofagasta',
            'Araucania',
            'Atacama',
            'Bio-Bio',
            'Coquimbo',
            "O'Higgins",
            'Los Lagos',
            'Magallanes y la Antartica Chilena',
            'Maule',
            'Santiago Region Metropolitana',
            'Tarapaca',
            'Valparaiso',
        ],
    },
    {
        name_es: 'China',
        name_en: 'China',
        name_pt: 'China',
        states: [
            'Anhui',
            'Fujian',
            'Gansu',
            'Guangdong',
            'Guizhou',
            'Hainan',
            'Hebei',
            'Heilongjiang',
            'Henan',
            'Hubei',
            'Hunan',
            'Jiangsu',
            'Jiangxi',
            'Jilin',
            'Liaoning',
            'Qinghai',
            'Shaanxi',
            'Shandong',
            'Shanxi',
            'Sichuan',
            'Yunnan',
            'Zhejiang',
            'Guangxi',
            'Nei Mongol',
            'Ningxia',
            'Xinjiang',
            'Xizang (Tibet)',
            'Beijing',
            'Chongqing',
            'Shanghai',
            'Tianjin',
        ],
    },
    {
        name_es: 'Chipre',
        name_en: 'Cyprus',
        name_pt: 'Chipre',
        states: [
            'Famagusta',
            'Kyrenia',
            'Larnaca',
            'Limassol',
            'Nicosia',
            'Paphos',
        ],
    },
    {
        name_es: 'Colombia',
        name_en: 'Colombia',
        name_pt: 'Colômbia',
        states: [
            'Amazonas',
            'Antioquia',
            'Arauca',
            'Atlantico',
            'Bogota District Capital',
            'Bolivar',
            'Boyaca',
            'Caldas',
            'Caqueta',
            'Casanare',
            'Cauca',
            'Cesar',
            'Choco',
            'Cordoba',
            'Cundinamarca',
            'Guainia',
            'Guaviare',
            'Huila',
            'La Guajira',
            'Magdalena',
            'Meta',
            'Narino',
            'Norte de Santander',
            'Putumayo',
            'Quindio',
            'Risaralda',
            'San Andres & Providencia',
            'Santander',
            'Sucre',
            'Tolima',
            'Valle del Cauca',
            'Vaupes',
            'Vichada',
        ],
    },
    {
        name_es: 'Comoras',
        name_en: 'Comoros',
        name_pt: 'Comores',
        states: [
            'Grande Comore (Njazidja)',
            'Anjouan (Nzwani)',
            'Moheli (Mwali)',
        ],
    },
    {
        name_es: 'Costa de Marfil',
        name_en: "Cote d'Ivoire",
        name_pt: 'Costa do Marfim',
        states: ["Cote d'Ivoire"],
    },
    {
        name_es: 'Costa Rica',
        name_en: 'Costa Rica',
        name_pt: 'Costa Rica',
        states: [
            'Alajuela',
            'Cartago',
            'Guanacaste',
            'Heredia',
            'Limon',
            'Puntarenas',
            'San Jose',
        ],
    },
    {
        name_es: 'Croacia',
        name_en: 'Croatia',
        name_pt: 'Croácia',
        states: [
            'Bjelovarsko-Bilogorska',
            'Brodsko-Posavska',
            'Dubrovacko-Neretvanska',
            'Istarska',
            'Karlovacka',
            'Koprivnicko-Krizevacka',
            'Krapinsko-Zagorska',
            'Licko-Senjska',
            'Medimurska',
            'Osjecko-Baranjska',
            'Pozesko-Slavonska',
            'Primorsko-Goranska',
            'Sibensko-Kninska',
            'Sisacko-Moslavacka',
            'Splitsko-Dalmatinska',
            'Varazdinska',
            'Viroviticko-Podravska',
            'Vukovarsko-Srijemska',
            'Zadarska',
            'Zagreb',
            'Zagrebacka',
        ],
    },
    {
        name_es: 'Cuba',
        name_en: 'Cuba',
        name_pt: 'Cuba',
        states: [
            'Camaguey',
            'Ciego de Avila',
            'Cienfuegos',
            'Ciudad de La Habana',
            'Granma',
            'Guantanamo',
            'Holguin',
            'Isla de la Juventud',
            'La Habana',
            'Las Tunas',
            'Matanzas',
            'Pinar del Rio',
            'Sancti Spiritus',
            'Santiago de Cuba',
            'Villa Clara',
        ],
    },
    {
        name_es: 'Dinamarca',
        name_en: 'Denmark',
        name_pt: 'Dinamarca',
        states: [
            'Arhus',
            'Bornholm',
            'Frederiksberg',
            'Frederiksborg',
            'Fyn',
            'Kobenhavn',
            'Kobenhavns',
            'Nordjylland',
            'Ribe',
            'Ringkobing',
            'Roskilde',
            'Sonderjylland',
            'Storstrom',
            'Vejle',
            'Vestsjalland',
            'Viborg',
        ],
    },
    {
        name_es: 'Dominica',
        name_en: 'Dominica',
        name_pt: 'Domínica',
        states: [
            'Saint Andrew',
            'Saint David',
            'Saint George',
            'Saint John',
            'Saint Joseph',
            'Saint Luke',
            'Saint Mark',
            'Saint Patrick',
            'Saint Paul',
            'Saint Peter',
        ],
    },
    {
        name_es: 'Ecuador',
        name_en: 'Ecuador',
        name_pt: 'Equador',
        states: [
            'Azuay',
            'Bolivar',
            'Canar',
            'Carchi',
            'Chimborazo',
            'Cotopaxi',
            'El Oro',
            'Esmeraldas',
            'Galapagos',
            'Guayas',
            'Imbabura',
            'Loja',
            'Los Rios',
            'Manabi',
            'Morona-Santiago',
            'Napo',
            'Orellana',
            'Pastaza',
            'Pichincha',
            'Sucumbios',
            'Tungurahua',
            'Zamora-Chinchipe',
        ],
    },
    {
        name_es: 'Egipto',
        name_en: 'Egypt',
        name_pt: 'Egito',
        states: [
            'Ad Daqahliyah',
            'Al Bahr al Ahmar',
            'Al Buhayrah',
            'Al Fayyum',
            'Al Gharbiyah',
            'Al Iskandariyah',
            "Al Isma'iliyah",
            'Al Jizah',
            'Al Minufiyah',
            'Al Minya',
            'Al Qahirah',
            'Al Qalyubiyah',
            'Al Wadi al Jadid',
            'Ash Sharqiyah',
            'As Suways',
            'Aswan',
            'Asyut',
            'Bani Suwayf',
            "Bur Sa'id",
            'Dumyat',
            "Janub Sina'",
            'Kafr ash Shaykh',
            'Matruh',
            'Qina',
            "Shamal Sina'",
            'Suhaj',
        ],
    },
    {
        name_es: 'El Salvador',
        name_en: 'El Salvador',
        name_pt: 'El Salvador',
        states: [
            'Ahuachapan',
            'Cabanas',
            'Chalatenango',
            'Cuscatlan',
            'La Libertad',
            'La Paz',
            'La Union',
            'Morazan',
            'San Miguel',
            'San Salvador',
            'Santa Ana',
            'San Vicente',
            'Sonsonate',
            'Usulutan',
        ],
    },
    {
        name_es: 'Emiratos Árabes Unidos',
        name_en: 'United Arab Emirates',
        name_pt: 'Emirados Árabes Unidos',
        states: [
            'Abu Dhabi',
            "'Ajman",
            'Al Fujayrah',
            'Sharjah',
            'Dubai',
            "Ra's al Khaymah",
            'Umm al Qaywayn',
        ],
    },
    {
        name_es: 'Eritrea',
        name_en: 'Eritrea',
        name_pt: 'Eritreia',
        states: [
            'Anseba',
            'Debub',
            "Debubawi K'eyih Bahri",
            'Gash Barka',
            "Ma'akel",
            'Semenawi Keyih Bahri',
        ],
    },
    {
        name_es: 'Eslovaquia',
        name_en: 'Slovakia',
        name_pt: 'Eslováquia',
        states: [
            'Banskobystricky',
            'Bratislavsky',
            'Kosicky',
            'Nitriansky',
            'Presovsky',
            'Trenciansky',
            'Trnavsky',
            'Zilinsky',
        ],
    },
    {
        name_es: 'Eslovenia',
        name_en: 'Slovenia',
        name_pt: 'Eslovênia',
        states: [
            'Ajdovscina',
            'Beltinci',
            'Benedikt',
            'Bistrica ob Sotli',
            'Bled',
            'Bloke',
            'Bohinj',
            'Borovnica',
            'Bovec',
            'Braslovce',
            'Brda',
            'Brezice',
            'Brezovica',
            'Cankova',
            'Celje',
            'Cerklje na Gorenjskem',
            'Cerknica',
            'Cerkno',
            'Cerkvenjak',
            'Crensovci',
            'Crna na Koroskem',
            'Crnomelj',
            'Destrnik',
            'Divaca',
            'Dobje',
            'Dobrepolje',
            'Dobrna',
            'Dobrova-Horjul-Polhov Gradec',
            'Dobrovnik-Dobronak',
            'Dolenjske Toplice',
            'Dol pri Ljubljani',
            'Domzale',
            'Dornava',
            'Dravograd',
            'Duplek',
            'Gorenja Vas-Poljane',
            'Gorisnica',
            'Gornja Radgona',
            'Gornji Grad',
            'Gornji Petrovci',
            'Grad',
            'Grosuplje',
            'Hajdina',
            'Hoce-Slivnica',
            'Hodos-Hodos',
            'Horjul',
            'Hrastnik',
            'Hrpelje-Kozina',
            'Idrija',
            'Ig',
            'Ilirska Bistrica',
            'Ivancna Gorica',
            'Izola-Isola',
            'Jesenice',
            'Jezersko',
            'Jursinci',
            'Kamnik',
            'Kanal',
            'Kidricevo',
            'Kobarid',
            'Kobilje',
            'Kocevje',
            'Komen',
            'Komenda',
            'Koper-Capodistria',
            'Kostel',
            'Kozje',
            'Kranj',
            'Kranjska Gora',
            'Krizevci',
            'Krsko',
            'Kungota',
            'Kuzma',
            'Lasko',
            'Lenart',
            'Lendava-Lendva',
            'Litija',
            'Ljubljana',
            'Ljubno',
            'Ljutomer',
            'Logatec',
            'Loska Dolina',
            'Loski Potok',
            'Lovrenc na Pohorju',
            'Luce',
            'Lukovica',
            'Majsperk',
            'Maribor',
            'Markovci',
            'Medvode',
            'Menges',
            'Metlika',
            'Mezica',
            'Miklavz na Dravskem Polju',
            'Miren-Kostanjevica',
            'Mirna Pec',
            'Mislinja',
            'Moravce',
            'Moravske Toplice',
            'Mozirje',
            'Murska Sobota',
            'Muta',
            'Naklo',
            'Nazarje',
            'Nova Gorica',
            'Novo Mesto',
            'Odranci',
            'Oplotnica',
            'Ormoz',
            'Osilnica',
            'Pesnica',
            'Piran-Pirano',
            'Pivka',
            'Podcetrtek',
            'Podlehnik',
            'Podvelka',
            'Polzela',
            'Postojna',
            'Prebold',
            'Preddvor',
            'Prevalje',
            'Ptuj',
            'Puconci',
            'Race-Fram',
            'Radece',
            'Radenci',
            'Radlje ob Dravi',
            'Radovljica',
            'Ravne na Koroskem',
            'Razkrizje',
            'Ribnica',
            'Ribnica na Pohorju',
            'Rogasovci',
            'Rogaska Slatina',
            'Rogatec',
            'Ruse',
            'Salovci',
            'Selnica ob Dravi',
            'Semic',
            'Sempeter-Vrtojba',
            'Sencur',
            'Sentilj',
            'Sentjernej',
            'Sentjur pri Celju',
            'Sevnica',
            'Sezana',
            'Skocjan',
            'Skofja Loka',
            'Skofljica',
            'Slovenj Gradec',
            'Slovenska Bistrica',
            'Slovenske Konjice',
            'Smarje pri Jelsah',
            'Smartno ob Paki',
            'Smartno pri Litiji',
            'Sodrazica',
            'Solcava',
            'Sostanj',
            'Starse',
            'Store',
            'Sveta Ana',
            'Sveti Andraz v Slovenskih Goricah',
            'Sveti Jurij',
            'Tabor',
            'Tisina',
            'Tolmin',
            'Trbovlje',
            'Trebnje',
            'Trnovska Vas',
            'Trzic',
            'Trzin',
            'Turnisce',
            'Velenje',
            'Velika Polana',
            'Velike Lasce',
            'Verzej',
            'Videm',
            'Vipava',
            'Vitanje',
            'Vodice',
            'Vojnik',
            'Vransko',
            'Vrhnika',
            'Vuzenica',
            'Zagorje ob Savi',
            'Zalec',
            'Zavrc',
            'Zelezniki',
            'Zetale',
            'Ziri',
            'Zirovnica',
            'Zuzemberk',
            'Zrece',
        ],
    },
    {
        name_es: 'España',
        name_en: 'Spain',
        name_pt: 'Espanha',
        states: [
            'Andalucia',
            'Aragon',
            'Asturias',
            'Baleares',
            'Ceuta',
            'Canarias',
            'Cantabria',
            'Castilla-La Mancha',
            'Castilla y Leon',
            'Cataluna',
            'Comunidad Valenciana',
            'Extremadura',
            'Galicia',
            'La Rioja',
            'Madrid',
            'Melilla',
            'Murcia',
            'Navarra',
            'Pais Vasco',
        ],
    },
    {
        name_es: 'Estados Unidos',
        name_en: 'United States',
        name_pt: 'Estados Unidos',
        states: [
            'Alabama',
            'Alaska',
            'Arizona',
            'Arkansas',
            'California',
            'Colorado',
            'Connecticut',
            'Delaware',
            'District of Columbia',
            'Florida',
            'Georgia',
            'Hawaii',
            'Idaho',
            'Illinois',
            'Indiana',
            'Iowa',
            'Kansas',
            'Kentucky',
            'Louisiana',
            'Maine',
            'Maryland',
            'Massachusetts',
            'Michigan',
            'Minnesota',
            'Mississippi',
            'Missouri',
            'Montana',
            'Nebraska',
            'Nevada',
            'New Hampshire',
            'New Jersey',
            'New Mexico',
            'New York',
            'North Carolina',
            'North Dakota',
            'Ohio',
            'Oklahoma',
            'Oregon',
            'Pennsylvania',
            'Rhode Island',
            'South Carolina',
            'South Dakota',
            'Tennessee',
            'Texas',
            'Utah',
            'Vermont',
            'Virginia',
            'Washington',
            'West Virginia',
            'Wisconsin',
            'Wyoming',
        ],
    },
    {
        name_es: 'Estonia',
        name_en: 'Estonia',
        name_pt: 'Estônia',
        states: [
            'Harjumaa (Tallinn)',
            'Hiiumaa (Kardla)',
            'Ida-Virumaa (Johvi)',
            'Jarvamaa (Paide)',
            'Jogevamaa (Jogeva)',
            'Laanemaa (Haapsalu)',
            'Laane-Virumaa (Rakvere)',
            'Parnumaa (Parnu)',
            'Polvamaa (Polva)',
            'Raplamaa (Rapla)',
            'Saaremaa (Kuressaare)',
            'Tartumaa (Tartu)',
            'Valgamaa (Valga)',
            'Viljandimaa (Viljandi)',
            'Vorumaa (Voru)',
        ],
    },
    {
        name_es: 'Etiopía',
        name_en: 'Ethiopia',
        name_pt: 'Etiópia',
        states: [
            'Addis Ababa',
            'Afar',
            'Amhara',
            'Binshangul Gumuz',
            'Dire Dawa',
            'Gambela Hizboch',
            'Harari',
            'Oromia',
            'Somali',
            'Tigray',
            'Southern Nations, Nationalities, and Peoples Region',
        ],
    },
    {
        name_es: 'Filipinas',
        name_en: 'Philippines',
        name_pt: 'Filipinas',
        states: [
            'Abra',
            'Agusan del Norte',
            'Agusan del Sur',
            'Aklan',
            'Albay',
            'Antique',
            'Apayao',
            'Aurora',
            'Basilan',
            'Bataan',
            'Batanes',
            'Batangas',
            'Biliran',
            'Benguet',
            'Bohol',
            'Bukidnon',
            'Bulacan',
            'Cagayan',
            'Camarines Norte',
            'Camarines Sur',
            'Camiguin',
            'Capiz',
            'Catanduanes',
            'Cavite',
            'Cebu',
            'Compostela',
            'Davao del Norte',
            'Davao del Sur',
            'Davao Oriental',
            'Eastern Samar',
            'Guimaras',
            'Ifugao',
            'Ilocos Norte',
            'Ilocos Sur',
            'Iloilo',
            'Isabela',
            'Kalinga',
            'Laguna',
            'Lanao del Norte',
            'Lanao del Sur',
            'La Union',
            'Leyte',
            'Maguindanao',
            'Marinduque',
            'Masbate',
            'Mindoro Occidental',
            'Mindoro Oriental',
            'Misamis Occidental',
            'Misamis Oriental',
            'Mountain Province',
            'Negros Occidental',
            'Negros Oriental',
            'North Cotabato',
            'Northern Samar',
            'Nueva Ecija',
            'Nueva Vizcaya',
            'Palawan',
            'Pampanga',
            'Pangasinan',
            'Quezon',
            'Quirino',
            'Rizal',
            'Romblon',
            'Samar',
            'Sarangani',
            'Siquijor',
            'Sorsogon',
            'South Cotabato',
            'Southern Leyte',
            'Sultan Kudarat',
            'Sulu',
            'Surigao del Norte',
            'Surigao del Sur',
            'Tarlac',
            'Tawi-Tawi',
            'Zambales',
            'Zamboanga del Norte',
            'Zamboanga del Sur',
            'Zamboanga Sibugay',
        ],
    },
    {
        name_es: 'Finlandia',
        name_en: 'Finland',
        name_pt: 'Finlândia',
        states: [
            'Aland',
            'Etela-Suomen Laani',
            'Ita-Suomen Laani',
            'Lansi-Suomen Laani',
            'Lappi',
            'Oulun Laani',
        ],
    },
    {
        name_es: 'Fiyi',
        name_en: 'Fiji',
        name_pt: 'Fiji',
        states: [
            'Central (Suva)',
            'Eastern (Levuka)',
            'Northern (Labasa)',
            'Rotuma',
            'Western (Lautoka)',
        ],
    },
    {
        name_es: 'Francia',
        name_en: 'France',
        name_pt: 'França',
        states: [
            'Alsace',
            'Aquitaine',
            'Auvergne',
            'Basse-Normandie',
            'Bourgogne',
            'Bretagne',
            'Centre',
            'Champagne-Ardenne',
            'Corse',
            'Franche-Comte',
            'Haute-Normandie',
            'Ile-de-France',
            'Languedoc-Roussillon',
            'Limousin',
            'Lorraine',
            'Midi-Pyrenees',
            'Nord-Pas-de-Calais',
            'Pays de la Loire',
            'Picardie',
            'Poitou-Charentes',
            "Provence-Alpes-Cote d'Azur",
            'Rhone-Alpes',
        ],
    },
    {
        name_es: 'Gabón',
        name_en: 'Gabon',
        name_pt: 'Gabão',
        states: [
            'Estuaire',
            'Haut-Ogooue',
            'Moyen-Ogooue',
            'Ngounie',
            'Nyanga',
            'Ogooue-Ivindo',
            'Ogooue-Lolo',
            'Ogooue-Maritime',
            'Woleu-Ntem',
        ],
    },
    {
        name_es: 'Gambia',
        name_en: 'Gambia',
        name_pt: 'Gâmbia',
        states: [
            'Banjul',
            'Central River',
            'Lower River',
            'North Bank',
            'Upper River',
            'Western',
        ],
    },
    {
        name_es: 'Georgia',
        name_en: 'Georgia',
        name_pt: 'Geórgia',
        states: ['Georgia'],
    },
    {
        name_es: 'Ghana',
        name_en: 'Ghana',
        name_pt: 'Gana',
        states: [
            'Ashanti',
            'Brong-Ahafo',
            'Central',
            'Eastern',
            'Greater Accra',
            'Northern',
            'Upper East',
            'Upper West',
            'Volta',
            'Western',
        ],
    },
    {
        name_es: 'Granada',
        name_en: 'Grenada',
        name_pt: 'Granada',
        states: [
            'Carriacou and Petit Martinique',
            'Saint Andrew',
            'Saint David',
            'Saint George',
            'Saint John',
            'Saint Mark',
            'Saint Patrick',
        ],
    },
    {
        name_es: 'Grecia',
        name_en: 'Greece',
        name_pt: 'Grécia',
        states: [
            'Agion Oros',
            'Achaia',
            'Aitolia kai Akarmania',
            'Argolis',
            'Arkadia',
            'Arta',
            'Attiki',
            'Chalkidiki',
            'Chanion',
            'Chios',
            'Dodekanisos',
            'Drama',
            'Evros',
            'Evrytania',
            'Evvoia',
            'Florina',
            'Fokidos',
            'Fthiotis',
            'Grevena',
            'Ileia',
            'Imathia',
            'Ioannina',
            'Irakleion',
            'Karditsa',
            'Kastoria',
            'Kavala',
            'Kefallinia',
            'Kerkyra',
            'Kilkis',
            'Korinthia',
            'Kozani',
            'Kyklades',
            'Lakonia',
            'Larisa',
            'Lasithi',
            'Lefkas',
            'Lesvos',
            'Magnisia',
            'Messinia',
            'Pella',
            'Pieria',
            'Preveza',
            'Rethynnis',
            'Rodopi',
            'Samos',
            'Serrai',
            'Thesprotia',
            'Thessaloniki',
            'Trikala',
            'Voiotia',
            'Xanthi',
            'Zakynthos',
        ],
    },
    {
        name_es: 'Groenlandia',
        name_en: 'Greenland',
        name_pt: 'Groenlândia',
        states: [
            'Avannaa (Nordgronland)',
            'Tunu (Ostgronland)',
            'Kitaa (Vestgronland)',
        ],
    },
    {
        name_es: 'Guatemala',
        name_en: 'Guatemala',
        name_pt: 'Guatemala',
        states: [
            'Alta Verapaz',
            'Baja Verapaz',
            'Chimaltenango',
            'Chiquimula',
            'El Progreso',
            'Escuintla',
            'Guatemala',
            'Huehuetenango',
            'Izabal',
            'Jalapa',
            'Jutiapa',
            'Peten',
            'Quetzaltenango',
            'Quiche',
            'Retalhuleu',
            'Sacatepequez',
            'San Marcos',
            'Santa Rosa',
            'Solola',
            'Suchitepequez',
            'Totonicapan',
            'Zacapa',
        ],
    },
    {
        name_es: 'Guinea',
        name_en: 'Guinea',
        name_pt: 'Guiné',
        states: [
            'Beyla',
            'Boffa',
            'Boke',
            'Conakry',
            'Coyah',
            'Dabola',
            'Dalaba',
            'Dinguiraye',
            'Dubreka',
            'Faranah',
            'Forecariah',
            'Fria',
            'Gaoual',
            'Gueckedou',
            'Kankan',
            'Kerouane',
            'Kindia',
            'Kissidougou',
            'Koubia',
            'Koundara',
            'Kouroussa',
            'Labe',
            'Lelouma',
            'Lola',
            'Macenta',
            'Mali',
            'Mamou',
            'Mandiana',
            'Nzerekore',
            'Pita',
            'Siguiri',
            'Telimele',
            'Tougue',
            'Yomou',
        ],
    },
    {
        name_es: 'Guinea Ecuatorial',
        name_en: 'Equatorial Guinea',
        name_pt: 'Guiné Equatorial',
        states: [
            'Annobon',
            'Bioko Norte',
            'Bioko Sur',
            'Centro Sur',
            'Kie-Ntem',
            'Litoral',
            'Wele-Nzas',
        ],
    },
    {
        name_es: 'Guinea-Bisau',
        name_en: 'Guinea-Bissau',
        name_pt: 'Guiné-Bissau',
        states: [
            'Bafata',
            'Biombo',
            'Bissau',
            'Bolama',
            'Cacheu',
            'Gabu',
            'Oio',
            'Quinara',
            'Tombali',
        ],
    },
    {
        name_es: 'Guyana',
        name_en: 'Guyana',
        name_pt: 'Guiana',
        states: [
            'Barima-Waini',
            'Cuyuni-Mazaruni',
            'Demerara-Mahaica',
            'East Berbice-Corentyne',
            'Essequibo Islands-West Demerara',
            'Mahaica-Berbice',
            'Pomeroon-Supenaam',
            'Potaro-Siparuni',
            'Upper Demerara-Berbice',
            'Upper Takutu-Upper Essequibo',
        ],
    },
    {
        name_es: 'Haití',
        name_en: 'Haiti',
        name_pt: 'Haiti',
        states: [
            'Artibonite',
            'Centre',
            "Grand 'Anse",
            'Nord',
            'Nord-Est',
            'Nord-Ouest',
            'Ouest',
            'Sud',
            'Sud-Est',
        ],
    },
    {
        name_es: 'Holanda',
        name_en: 'Netherlands',
        name_pt: 'Holanda',
        states: [
            'Drenthe',
            'Flevoland',
            'Friesland',
            'Gelderland',
            'Groningen',
            'Limburg',
            'Noord-Brabant',
            'Noord-Holland',
            'Overijssel',
            'Utrecht',
            'Zeeland',
            'Zuid-Holland',
        ],
    },
    {
        name_es: 'Honduras',
        name_en: 'Honduras',
        name_pt: 'Honduras',
        states: [
            'Atlantida',
            'Choluteca',
            'Colon',
            'Comayagua',
            'Copan',
            'Cortes',
            'El Paraiso',
            'Francisco Morazan',
            'Gracias a Dios',
            'Intibuca',
            'Islas de la Bahia',
            'La Paz',
            'Lempira',
            'Ocotepeque',
            'Olancho',
            'Santa Barbara',
            'Valle',
            'Yoro',
        ],
    },
    {
        name_es: 'Hong Kong',
        name_en: 'Hong Kong',
        name_pt: 'Hong Kong',
        states: ['Hong Kong'],
    },
    {
        name_es: 'Hungría',
        name_en: 'Hungary',
        name_pt: 'Hungria',
        states: [
            'Bacs-Kiskun',
            'Baranya',
            'Bekes',
            'Borsod-Abauj-Zemplen',
            'Csongrad',
            'Fejer',
            'Gyor-Moson-Sopron',
            'Hajdu-Bihar',
            'Heves',
            'Jasz-Nagykun-Szolnok',
            'Komarom-Esztergom',
            'Nograd',
            'Pest',
            'Somogy',
            'Szabolcs-Szatmar-Bereg',
            'Tolna',
            'Vas',
            'Veszprem',
            'Zala',
            'Bekescsaba',
            'Debrecen',
            'Dunaujvaros',
            'Eger',
            'Gyor',
            'Hodmezovasarhely',
            'Kaposvar',
            'Kecskemet',
            'Miskolc',
            'Nagykanizsa',
            'Nyiregyhaza',
            'Pecs',
            'Sopron',
            'Szeged',
            'Szekesfehervar',
            'Szolnok',
            'Szombathely',
            'Tatabanya',
            'Veszprem',
            'Zalaegerszeg',
        ],
    },
    {
        name_es: 'India',
        name_en: 'India',
        name_pt: 'Índia',
        states: [
            'Andaman and Nicobar Islands',
            'Andhra Pradesh',
            'Arunachal Pradesh',
            'Assam',
            'Bihar',
            'Chandigarh',
            'Chhattisgarh',
            'Dadra and Nagar Haveli',
            'Daman and Diu',
            'Delhi',
            'Goa',
            'Gujarat',
            'Haryana',
            'Himachal Pradesh',
            'Jammu and Kashmir',
            'Jharkhand',
            'Karnataka',
            'Kerala',
            'Lakshadweep',
            'Madhya Pradesh',
            'Maharashtra',
            'Manipur',
            'Meghalaya',
            'Mizoram',
            'Nagaland',
            'Orissa',
            'Pondicherry',
            'Punjab',
            'Rajasthan',
            'Sikkim',
            'Tamil Nadu',
            'Tripura',
            'Uttaranchal',
            'Uttar Pradesh',
            'West Bengal',
        ],
    },
    {
        name_es: 'Indonesia',
        name_en: 'Indonesia',
        name_pt: 'Indonésia',
        states: [
            'Aceh',
            'Bali',
            'Banten',
            'Bengkulu',
            'Gorontalo',
            'Irian Jaya Barat',
            'Jakarta Raya',
            'Jambi',
            'Jawa Barat',
            'Jawa Tengah',
            'Jawa Timur',
            'Kalimantan Barat',
            'Kalimantan Selatan',
            'Kalimantan Tengah',
            'Kalimantan Timur',
            'Kepulauan Bangka Belitung',
            'Kepulauan Riau',
            'Lampung',
            'Maluku',
            'Maluku Utara',
            'Nusa Tenggara Barat',
            'Nusa Tenggara Timur',
            'Papua',
            'Riau',
            'Sulawesi Barat',
            'Sulawesi Selatan',
            'Sulawesi Tengah',
            'Sulawesi Tenggara',
            'Sulawesi Utara',
            'Sumatera Barat',
            'Sumatera Selatan',
            'Sumatera Utara',
            'Yogyakarta',
        ],
    },
    {
        name_es: 'Iraq',
        name_en: 'Iraq',
        name_pt: 'Iraque',
        states: [
            'Al Anbar',
            'Al Basrah',
            'Al Muthanna',
            'Al Qadisiyah',
            'An Najaf',
            'Arbil',
            'As Sulaymaniyah',
            "At Ta'mim",
            'Babil',
            'Baghdad',
            'Dahuk',
            'Dhi Qar',
            'Diyala',
            "Karbala'",
            'Maysan',
            'Ninawa',
            'Salah ad Din',
            'Wasit',
        ],
    },
    {
        name_es: 'Irlanda',
        name_en: 'Ireland',
        name_pt: 'Irlanda',
        states: [
            'Carlow',
            'Cavan',
            'Clare',
            'Cork',
            'Donegal',
            'Dublin',
            'Galway',
            'Kerry',
            'Kildare',
            'Kilkenny',
            'Laois',
            'Leitrim',
            'Limerick',
            'Longford',
            'Louth',
            'Mayo',
            'Meath',
            'Monaghan',
            'Offaly',
            'Roscommon',
            'Sligo',
            'Tipperary',
            'Waterford',
            'Westmeath',
            'Wexford',
            'Wicklow',
        ],
    },
    {
        name_es: 'Islandia',
        name_en: 'Iceland',
        name_pt: 'Islândia',
        states: [
            'Austurland',
            'Hofudhborgarsvaedhi',
            'Nordhurland Eystra',
            'Nordhurland Vestra',
            'Sudhurland',
            'Sudhurnes',
            'Vestfirdhir',
            'Vesturland',
        ],
    },
    {
        name_es: 'Islas Marshall',
        name_en: 'Marshall Islands',
        name_pt: 'Ilhas Marshall',
        states: ['Marshall Islands'],
    },
    {
        name_es: 'Islas Salomón',
        name_en: 'Solomon Islands',
        name_pt: 'Ilhas Salomão',
        states: [
            'Central',
            'Choiseul',
            'Guadalcanal',
            'Honiara',
            'Isabel',
            'Makira',
            'Malaita',
            'Rennell and Bellona',
            'Temotu',
            'Western',
        ],
    },
    {
        name_es: 'Israel',
        name_en: 'Israel',
        name_pt: 'Israel',
        states: [
            'Central',
            'Haifa',
            'Jerusalem',
            'Northern',
            'Southern',
            'Tel Aviv',
        ],
    },
    {
        name_es: 'Italia',
        name_en: 'Italy',
        name_pt: 'Itália',
        states: [
            'Abruzzo',
            'Basilicata',
            'Calabria',
            'Campania',
            'Emilia-Romagna',
            'Friuli-Venezia Giulia',
            'Lazio',
            'Liguria',
            'Lombardia',
            'Marche',
            'Molise',
            'Piemonte',
            'Puglia',
            'Sardegna',
            'Sicilia',
            'Toscana',
            'Trentino-Alto Adige',
            'Umbria',
            "Valle d'Aosta",
            'Veneto',
        ],
    },
    {
        name_es: 'Jamaica',
        name_en: 'Jamaica',
        name_pt: 'Jamaica',
        states: [
            'Clarendon',
            'Hanover',
            'Kingston',
            'Manchester',
            'Portland',
            'Saint Andrew',
            'Saint Ann',
            'Saint Catherine',
            'Saint Elizabeth',
            'Saint James',
            'Saint Mary',
            'Saint Thomas',
            'Trelawny',
            'Westmoreland',
        ],
    },
    {
        name_es: 'Japón',
        name_en: 'Japan',
        name_pt: 'Japão',
        states: [
            'Aichi',
            'Akita',
            'Aomori',
            'Chiba',
            'Ehime',
            'Fukui',
            'Fukuoka',
            'Fukushima',
            'Gifu',
            'Gumma',
            'Hiroshima',
            'Hokkaido',
            'Hyogo',
            'Ibaraki',
            'Ishikawa',
            'Iwate',
            'Kagawa',
            'Kagoshima',
            'Kanagawa',
            'Kochi',
            'Kumamoto',
            'Kyoto',
            'Mie',
            'Miyagi',
            'Miyazaki',
            'Nagano',
            'Nagasaki',
            'Nara',
            'Niigata',
            'Oita',
            'Okayama',
            'Okinawa',
            'Osaka',
            'Saga',
            'Saitama',
            'Shiga',
            'Shimane',
            'Shizuoka',
            'Tochigi',
            'Tokushima',
            'Tokyo',
            'Tottori',
            'Toyama',
            'Wakayama',
            'Yamagata',
            'Yamaguchi',
            'Yamanashi',
        ],
    },
    {
        name_es: 'Jordania',
        name_en: 'Jordan',
        name_pt: 'Jordânia',
        states: [
            'Ajlun',
            "Al 'Aqabah",
            "Al Balqa'",
            'Al Karak',
            'Al Mafraq',
            "'Amman",
            'At Tafilah',
            "Az Zarqa'",
            'Irbid',
            'Jarash',
            "Ma'an",
            'Madaba',
        ],
    },
    {
        name_es: 'Kazajistán',
        name_en: 'Kazakhstan',
        name_pt: 'Cazaquistão',
        states: [
            'Almaty Oblysy',
            'Almaty Qalasy',
            'Aqmola Oblysy',
            'Aqtobe Oblysy',
            'Astana Qalasy',
            'Atyrau Oblysy',
            'Batys Qazaqstan Oblysy',
            'Bayqongyr Qalasy',
            'Mangghystau Oblysy',
            'Ongtustik Qazaqstan Oblysy',
            'Pavlodar Oblysy',
            'Qaraghandy Oblysy',
            'Qostanay Oblysy',
            'Qyzylorda Oblysy',
            'Shyghys Qazaqstan Oblysy',
            'Soltustik Qazaqstan Oblysy',
            'Zhambyl Oblysy',
        ],
    },
    {
        name_es: 'Kenia',
        name_en: 'Kenya',
        name_pt: 'Quênia',
        states: [
            'Central',
            'Coast',
            'Eastern',
            'Nairobi Area',
            'North Eastern',
            'Nyanza',
            'Rift Valley',
            'Western',
        ],
    },
    {
        name_es: 'Kirguistán',
        name_en: 'Kyrgyzstan',
        name_pt: 'Quirguistão',
        states: [
            'Batken Oblasty',
            'Bishkek Shaary',
            'Chuy Oblasty',
            'Jalal-Abad Oblasty',
            'Naryn Oblasty',
            'Osh Oblasty',
            'Talas Oblasty',
            'Ysyk-Kol Oblasty',
        ],
    },
    {
        name_es: 'Kiribati',
        name_en: 'Kiribati',
        name_pt: 'Kiribati',
        states: ['Kiribati'],
    },
    {
        name_es: 'Kuwait',
        name_en: 'Kuwait',
        name_pt: 'Kuwait',
        states: [
            'Al Ahmadi',
            'Al Farwaniyah',
            'Al Asimah',
            'Al Jahra',
            'Hawalli',
            'Mubarak Al-Kabeer',
        ],
    },
    {
        name_es: 'Lesoto',
        name_en: 'Lesotho',
        name_pt: 'Lesoto',
        states: [
            'Berea',
            'Butha-Buthe',
            'Leribe',
            'Mafeteng',
            'Maseru',
            "Mohale's Hoek",
            'Mokhotlong',
            "Qacha's Nek",
            'Quthing',
            'Thaba-Tseka',
        ],
    },
    {
        name_es: 'Letonia',
        name_en: 'Latvia',
        name_pt: 'Letônia',
        states: [
            'Aizkraukles Rajons',
            'Aluksnes Rajons',
            'Balvu Rajons',
            'Bauskas Rajons',
            'Cesu Rajons',
            'Daugavpils',
            'Daugavpils Rajons',
            'Dobeles Rajons',
            'Gulbenes Rajons',
            'Jekabpils Rajons',
            'Jelgava',
            'Jelgavas Rajons',
            'Jurmala',
            'Kraslavas Rajons',
            'Kuldigas Rajons',
            'Liepaja',
            'Liepajas Rajons',
            'Limbazu Rajons',
            'Ludzas Rajons',
            'Madonas Rajons',
            'Ogres Rajons',
            'Preilu Rajons',
            'Rezekne',
            'Rezeknes Rajons',
            'Riga',
            'Rigas Rajons',
            'Saldus Rajons',
            'Talsu Rajons',
            'Tukuma Rajons',
            'Valkas Rajons',
            'Valmieras Rajons',
            'Ventspils',
            'Ventspils Rajons',
        ],
    },
    {
        name_es: 'Liberia',
        name_en: 'Liberia',
        name_pt: 'Libéria',
        states: [
            'Bomi',
            'Bong',
            'Gbarpolu',
            'Grand Bassa',
            'Grand Cape Mount',
            'Grand Gedeh',
            'Grand Kru',
            'Lofa',
            'Margibi',
            'Maryland',
            'Montserrado',
            'Nimba',
            'River Cess',
            'River Gee',
            'Sinoe',
        ],
    },
    {
        name_es: 'Liechtenstein',
        name_en: 'Liechtenstein',
        name_pt: 'Liechtenstein',
        states: [
            'Balzers',
            'Eschen',
            'Gamprin',
            'Mauren',
            'Planken',
            'Ruggell',
            'Schaan',
            'Schellenberg',
            'Triesen',
            'Triesenberg',
            'Vaduz',
        ],
    },
    {
        name_es: 'Lituania',
        name_en: 'Lithuania',
        name_pt: 'Lituânia',
        states: [
            'Alytaus',
            'Kauno',
            'Klaipedos',
            'Marijampoles',
            'Panevezio',
            'Siauliu',
            'Taurages',
            'Telsiu',
            'Utenos',
            'Vilniaus',
        ],
    },
    {
        name_es: 'Luxemburgo',
        name_en: 'Luxembourg',
        name_pt: 'Luxemburgo',
        states: ['Diekirch', 'Grevenmacher', 'Luxembourg'],
    },
    {
        name_es: 'Líbano',
        name_en: 'Lebanon',
        name_pt: 'Líbano',
        states: [
            'Beyrouth',
            'Beqaa',
            'Liban-Nord',
            'Liban-Sud',
            'Mont-Liban',
            'Nabatiye',
        ],
    },
    {
        name_es: 'Madagascar',
        name_en: 'Madagascar',
        name_pt: 'Madagascar',
        states: [
            'Antananarivo',
            'Antsiranana',
            'Fianarantsoa',
            'Mahajanga',
            'Toamasina',
            'Toliara',
        ],
    },
    {
        name_es: 'Malasia',
        name_en: 'Malaysia',
        name_pt: 'Malásia',
        states: [
            'Johor',
            'Kedah',
            'Kelantan',
            'Kuala Lumpur',
            'Labuan',
            'Malacca',
            'Negeri Sembilan',
            'Pahang',
            'Perak',
            'Perlis',
            'Penang',
            'Sabah',
            'Sarawak',
            'Selangor',
            'Terengganu',
        ],
    },
    {
        name_es: 'Malaui',
        name_en: 'Malawi',
        name_pt: 'Malaui',
        states: [
            'Balaka',
            'Blantyre',
            'Chikwawa',
            'Chiradzulu',
            'Chitipa',
            'Dedza',
            'Dowa',
            'Karonga',
            'Kasungu',
            'Likoma',
            'Lilongwe',
            'Machinga',
            'Mangochi',
            'Mchinji',
            'Mulanje',
            'Mwanza',
            'Mzimba',
            'Ntcheu',
            'Nkhata Bay',
            'Nkhotakota',
            'Nsanje',
            'Ntchisi',
            'Phalombe',
            'Rumphi',
            'Salima',
            'Thyolo',
            'Zomba',
        ],
    },
    {
        name_es: 'Maldivas',
        name_en: 'Maldives',
        name_pt: 'Maldivas',
        states: [
            'Alifu',
            'Baa',
            'Dhaalu',
            'Faafu',
            'Gaafu Alifu',
            'Gaafu Dhaalu',
            'Gnaviyani',
            'Haa Alifu',
            'Haa Dhaalu',
            'Kaafu',
            'Laamu',
            'Lhaviyani',
            'Maale',
            'Meemu',
            'Noonu',
            'Raa',
            'Seenu',
            'Shaviyani',
            'Thaa',
            'Vaavu',
        ],
    },
    {
        name_es: 'Malta',
        name_en: 'Malta',
        name_pt: 'Malta',
        states: ['Malta'],
    },
    {
        name_es: 'Malí',
        name_en: 'Mali',
        name_pt: 'Mali',
        states: [
            'Bamako (Capital)',
            'Gao',
            'Kayes',
            'Kidal',
            'Koulikoro',
            'Mopti',
            'Segou',
            'Sikasso',
            'Tombouctou',
        ],
    },
    {
        name_es: 'Marruecos',
        name_en: 'Morocco',
        name_pt: 'Marrocos',
        states: [
            'Agadir',
            'Al Hoceima',
            'Azilal',
            'Beni Mellal',
            'Ben Slimane',
            'Boulemane',
            'Casablanca',
            'Chaouen',
            'El Jadida',
            'El Kelaa des Sraghna',
            'Er Rachidia',
            'Essaouira',
            'Fes',
            'Figuig',
            'Guelmim',
            'Ifrane',
            'Kenitra',
            'Khemisset',
            'Khenifra',
            'Khouribga',
            'Laayoune',
            'Larache',
            'Marrakech',
            'Meknes',
            'Nador',
            'Ouarzazate',
            'Oujda',
            'Rabat-Sale',
            'Safi',
            'Settat',
            'Sidi Kacem',
            'Tangier',
            'Tan-Tan',
            'Taounate',
            'Taroudannt',
            'Tata',
            'Taza',
            'Tetouan',
            'Tiznit',
        ],
    },
    {
        name_es: 'Mauricio',
        name_en: 'Mauritius',
        name_pt: 'Maurício',
        states: [
            'Agalega Islands',
            'Black River',
            'Cargados Carajos Shoals',
            'Flacq',
            'Grand Port',
            'Moka',
            'Pamplemousses',
            'Plaines Wilhems',
            'Port Louis',
            'Riviere du Rempart',
            'Rodrigues',
            'Savanne',
        ],
    },
    {
        name_es: 'Mauritania',
        name_en: 'Mauritania',
        name_pt: 'Mauritânia',
        states: [
            'Adrar',
            'Assaba',
            'Brakna',
            'Dakhlet Nouadhibou',
            'Gorgol',
            'Guidimaka',
            'Hodh Ech Chargui',
            'Hodh El Gharbi',
            'Inchiri',
            'Nouakchott',
            'Tagant',
            'Tiris Zemmour',
            'Trarza',
        ],
    },
    {
        name_es: 'Monaco',
        name_en: 'Monaco',
        name_pt: 'Mônaco',
        states: ['Monaco'],
    },
    {
        name_es: 'Mongolia',
        name_en: 'Mongolia',
        name_pt: 'Mongólia',
        states: [
            'Arhangay',
            'Bayanhongor',
            'Bayan-Olgiy',
            'Bulgan',
            'Darhan Uul',
            'Dornod',
            'Dornogovi',
            'Dundgovi',
            'Dzavhan',
            'Govi-Altay',
            'Govi-Sumber',
            'Hentiy',
            'Hovd',
            'Hovsgol',
            'Omnogovi',
            'Orhon',
            'Ovorhangay',
            'Selenge',
            'Suhbaatar',
            'Tov',
            'Ulaanbaatar',
            'Uvs',
        ],
    },
    {
        name_es: 'Mozambique',
        name_en: 'Mozambique',
        name_pt: 'Moçambique',
        states: [
            'Cabo Delgado',
            'Gaza',
            'Inhambane',
            'Manica',
            'Maputo',
            'Cidade de Maputo',
            'Nampula',
            'Niassa',
            'Sofala',
            'Tete',
            'Zambezia',
        ],
    },
    {
        name_es: 'México',
        name_en: 'Mexico',
        name_pt: 'México',
        states: [
            'Aguascalientes',
            'Baja California',
            'Baja California Sur',
            'Campeche',
            'Chiapas',
            'Chihuahua',
            'Coahuila de Zaragoza',
            'Colima',
            'Distrito Federal',
            'Durango',
            'Guanajuato',
            'Guerrero',
            'Hidalgo',
            'Jalisco',
            'Mexico',
            'Michoacan de Ocampo',
            'Morelos',
            'Nayarit',
            'Nuevo Leon',
            'Oaxaca',
            'Puebla',
            'Queretaro de Arteaga',
            'Quintana Roo',
            'San Luis Potosi',
            'Sinaloa',
            'Sonora',
            'Tabasco',
            'Tamaulipas',
            'Tlaxcala',
            'Veracruz-Llave',
            'Yucatan',
            'Zacatecas',
        ],
    },
    {
        name_es: 'Namibia',
        name_en: 'Namibia',
        name_pt: 'Namíbia',
        states: [
            'Caprivi',
            'Erongo',
            'Hardap',
            'Karas',
            'Khomas',
            'Kunene',
            'Ohangwena',
            'Okavango',
            'Omaheke',
            'Omusati',
            'Oshana',
            'Oshikoto',
            'Otjozondjupa',
        ],
    },
    {
        name_es: 'Nauru',
        name_en: 'Nauru',
        name_pt: 'Nauru',
        states: ['Nauru'],
    },
    {
        name_es: 'Nepal',
        name_en: 'Nepal',
        name_pt: 'Nepal',
        states: [
            'Bagmati',
            'Bheri',
            'Dhawalagiri',
            'Gandaki',
            'Janakpur',
            'Karnali',
            'Kosi',
            'Lumbini',
            'Mahakali',
            'Mechi',
            'Narayani',
            'Rapti',
            'Sagarmatha',
            'Seti',
        ],
    },
    {
        name_es: 'Nicaragua',
        name_en: 'Nicaragua',
        name_pt: 'Nicarágua',
        states: [
            'Atlantico Norte',
            'Atlantico Sur',
            'Boaco',
            'Carazo',
            'Chinandega',
            'Chontales',
            'Esteli',
            'Granada',
            'Jinotega',
            'Leon',
            'Madriz',
            'Managua',
            'Masaya',
            'Matagalpa',
            'Nueva Segovia',
            'Rio San Juan',
            'Rivas',
        ],
    },
    {
        name_es: 'Niger',
        name_en: 'Niger',
        name_pt: 'Níger',
        states: [
            'Agadez',
            'Diffa',
            'Dosso',
            'Maradi',
            'Niamey',
            'Tahoua',
            'Tillaberi',
            'Zinder',
        ],
    },
    {
        name_es: 'Nigeria',
        name_en: 'Nigeria',
        name_pt: 'Nigéria',
        states: [
            'Abia',
            'Abuja Federal Capital',
            'Adamawa',
            'Akwa Ibom',
            'Anambra',
            'Bauchi',
            'Bayelsa',
            'Benue',
            'Borno',
            'Cross River',
            'Delta',
            'Ebonyi',
            'Edo',
            'Ekiti',
            'Enugu',
            'Gombe',
            'Imo',
            'Jigawa',
            'Kaduna',
            'Kano',
            'Katsina',
            'Kebbi',
            'Kogi',
            'Kwara',
            'Lagos',
            'Nassarawa',
            'Niger',
            'Ogun',
            'Ondo',
            'Osun',
            'Oyo',
            'Plateau',
            'Rivers',
            'Sokoto',
            'Taraba',
            'Yobe',
            'Zamfara',
        ],
    },
    {
        name_es: 'Noruega',
        name_en: 'Norway',
        name_pt: 'Noruega',
        states: [
            'Akershus',
            'Aust-Agder',
            'Buskerud',
            'Finnmark',
            'Hedmark',
            'Hordaland',
            'More og Romsdal',
            'Nordland',
            'Nord-Trondelag',
            'Oppland',
            'Oslo',
            'Ostfold',
            'Rogaland',
            'Sogn og Fjordane',
            'Sor-Trondelag',
            'Telemark',
            'Troms',
            'Vest-Agder',
            'Vestfold',
        ],
    },
    {
        name_es: 'Nueva Zelanda',
        name_en: 'New Zealand',
        name_pt: 'Nova Zelândia',
        states: [
            'Auckland',
            'Bay of Plenty',
            'Canterbury',
            'Chatham Islands',
            'Gisborne',
            "Hawke's Bay",
            'Manawatu-Wanganui',
            'Marlborough',
            'Nelson',
            'Northland',
            'Otago',
            'Southland',
            'Taranaki',
            'Tasman',
            'Waikato',
            'Wellington',
            'West Coast',
        ],
    },
    {
        name_es: 'Omán',
        name_en: 'Oman',
        name_pt: 'Omã',
        states: [
            'Ad Dakhiliyah',
            'Al Batinah',
            'Al Wusta',
            'Ash Sharqiyah',
            'Az Zahirah',
            'Masqat',
            'Musandam',
            'Dhofar',
        ],
    },
    {
        name_es: 'Pakistán',
        name_en: 'Pakistan',
        name_pt: 'Paquistão',
        states: [
            'Balochistan',
            'North-West Frontier Province',
            'Punjab',
            'Sindh',
            'Islamabad Capital Territory',
            'Federally Administered Tribal Areas',
        ],
    },
    {
        name_es: 'Panamá',
        name_en: 'Panama',
        name_pt: 'Panamá',
        states: [
            'Bocas del Toro',
            'Chiriqui',
            'Cocle',
            'Colon',
            'Darien',
            'Herrera',
            'Los Santos',
            'Panama',
            'San Blas',
            'Veraguas',
        ],
    },
    {
        name_es: 'Papúa Nueva Guinea',
        name_en: 'Papua New Guinea',
        name_pt: 'Papua Nova Guiné',
        states: [
            'Bougainville',
            'Central',
            'Chimbu',
            'Eastern Highlands',
            'East New Britain',
            'East Sepik',
            'Enga',
            'Gulf',
            'Madang',
            'Manus',
            'Milne Bay',
            'Morobe',
            'National Capital',
            'New Ireland',
            'Northern',
            'Sandaun',
            'Southern Highlands',
            'Western',
            'Western Highlands',
            'West New Britain',
        ],
    },
    {
        name_es: 'Paraguay',
        name_en: 'Paraguay',
        name_pt: 'Paraguai',
        states: [
            'Alto Paraguay',
            'Alto Parana',
            'Amambay',
            'Asuncion',
            'Boqueron',
            'Caaguazu',
            'Caazapa',
            'Canindeyu',
            'Central',
            'Concepcion',
            'Cordillera',
            'Guaira',
            'Itapua',
            'Misiones',
            'Neembucu',
            'Paraguari',
            'Presidente Hayes',
            'San Pedro',
        ],
    },
    {
        name_es: 'Perú',
        name_en: 'Peru',
        name_pt: 'Peru',
        states: [
            'Amazonas',
            'Ancash',
            'Apurimac',
            'Arequipa',
            'Ayacucho',
            'Cajamarca',
            'Callao',
            'Cusco',
            'Huancavelica',
            'Huanuco',
            'Ica',
            'Junin',
            'La Libertad',
            'Lambayeque',
            'Lima',
            'Loreto',
            'Madre de Dios',
            'Moquegua',
            'Pasco',
            'Piura',
            'Puno',
            'San Martin',
            'Tacna',
            'Tumbes',
            'Ucayali',
        ],
    },
    {
        name_es: 'Polonia',
        name_en: 'Poland',
        name_pt: 'Polônia',
        states: [
            'Greater Poland (Wielkopolskie)',
            'Kuyavian-Pomeranian (Kujawsko-Pomorskie)',
            'Lesser Poland (Malopolskie)',
            'Lodz (Lodzkie)',
            'Lower Silesian (Dolnoslaskie)',
            'Lublin (Lubelskie)',
            'Lubusz (Lubuskie)',
            'Masovian (Mazowieckie)',
            'Opole (Opolskie)',
            'Podlasie (Podlaskie)',
            'Pomeranian (Pomorskie)',
            'Silesian (Slaskie)',
            'Subcarpathian (Podkarpackie)',
            'Swietokrzyskie (Swietokrzyskie)',
            'Warmian-Masurian (Warminsko-Mazurskie)',
            'West Pomeranian (Zachodniopomorskie)',
        ],
    },
    {
        name_es: 'Portugal',
        name_en: 'Portugal',
        name_pt: 'Portugal',
        states: [
            'Aveiro',
            'Acores',
            'Beja',
            'Braga',
            'Bragança',
            'Castelo Branco',
            'Coimbra',
            'Evora',
            'Faro',
            'Guarda',
            'Leiria',
            'Lisbon',
            'Madeira',
            'Portalegre',
            'Porto',
            'Santarem',
            'Setubal',
            'Viana do Castelo',
            'Vila Real',
            'Viseu',
        ],
    },
    {
        name_es: 'Qatar',
        name_en: 'Qatar',
        name_pt: 'Catar',
        states: [
            'Ad Dawhah',
            'Al Ghuwayriyah',
            'Al Jumayliyah',
            'Al Khawr',
            'Al Wakrah',
            'Ar Rayyan',
            'Jarayan al Batinah',
            'Madinat ash Shamal',
            "Umm Sa'id",
            'Umm Salal',
        ],
    },
    {
        name_es: 'Reino Unido',
        name_en: 'United Kingdom',
        name_pt: 'Reino Unido',
        states: ['United Kingdom'],
    },
    {
        name_es: 'República Centroafricana',
        name_en: 'Central African Republic',
        name_pt: 'República Centro-Africana',
        states: [
            'Bamingui-Bangoran',
            'Bangui',
            'Basse-Kotto',
            'Haute-Kotto',
            'Haut-Mbomou',
            'Kemo',
            'Lobaye',
            'Mambere-Kadei',
            'Mbomou',
            'Nana-Grebizi',
            'Nana-Mambere',
            'Ombella-Mpoko',
            'Ouaka',
            'Ouham',
            'Ouham-Pende',
            'Sangha-Mbaere',
            'Vakaga',
        ],
    },
    {
        name_es: 'República Dominicana',
        name_en: 'Dominican Republic',
        name_pt: 'República Dominicana',
        states: [
            'Azua',
            'Baoruco',
            'Barahona',
            'Dajabon',
            'Distrito Nacional',
            'Duarte',
            'Elias Pina',
            'El Seibo',
            'Espaillat',
            'Hato Mayor',
            'Independencia',
            'La Altagracia',
            'La Romana',
            'La Vega',
            'Maria Trinidad Sanchez',
            'Monsenor Nouel',
            'Monte Cristi',
            'Monte Plata',
            'Pedernales',
            'Peravia',
            'Puerto Plata',
            'Salcedo',
            'Samana',
            'Sanchez Ramirez',
            'San Cristobal',
            'San Jose de Ocoa',
            'San Juan',
            'San Pedro de Macoris',
            'Santiago',
            'Santiago Rodriguez',
            'Santo Domingo',
            'Valverde',
        ],
    },
    {
        name_es: 'Ruanda',
        name_en: 'Rwanda',
        name_pt: 'Ruanda',
        states: [
            'Butare',
            'Byumba',
            'Cyangugu',
            'Gikongoro',
            'Gisenyi',
            'Gitarama',
            'Kibungo',
            'Kibuye',
            'Kigali Rurale',
            'Kigali-ville',
            'Umutara',
            'Ruhengeri',
        ],
    },
    {
        name_es: 'Rumania',
        name_en: 'Romania',
        name_pt: 'Romênia',
        states: [
            'Alba',
            'Arad',
            'Arges',
            'Bacau',
            'Bihor',
            'Bistrita-Nasaud',
            'Botosani',
            'Braila',
            'Brasov',
            'Bucuresti',
            'Buzau',
            'Calarasi',
            'Caras-Severin',
            'Cluj',
            'Constanta',
            'Covasna',
            'Dimbovita',
            'Dolj',
            'Galati',
            'Gorj',
            'Giurgiu',
            'Harghita',
            'Hunedoara',
            'Ialomita',
            'Iasi',
            'Ilfov',
            'Maramures',
            'Mehedinti',
            'Mures',
            'Neamt',
            'Olt',
            'Prahova',
            'Salaj',
            'Satu Mare',
            'Sibiu',
            'Suceava',
            'Teleorman',
            'Timis',
            'Tulcea',
            'Vaslui',
            'Vilcea',
            'Vrancea',
        ],
    },
    {
        name_es: 'Rusia',
        name_en: 'Russia',
        name_pt: 'Rússia',
        states: [
            'Amur',
            "Arkhangel'sk",
            "Astrakhan'",
            'Belgorod',
            'Bryansk',
            'Chelyabinsk',
            'Chita',
            'Irkutsk',
            'Ivanovo',
            'Kaliningrad',
            'Kaluga',
            'Kamchatka',
            'Kemerovo',
            'Kirov',
            'Kostroma',
            'Kurgan',
            'Kursk',
            'Leningrad',
            'Lipetsk',
            'Magadan',
            'Moscow',
            'Murmansk',
            'Nizhniy Novgorod',
            'Novgorod',
            'Novosibirsk',
            'Omsk',
            'Orenburg',
            'Orel',
            'Penza',
            "Perm'",
            'Pskov',
            'Rostov',
            "Ryazan'",
            'Sakhalin',
            'Samara',
            'Saratov',
            'Smolensk',
            'Sverdlovsk',
            'Tambov',
            'Tomsk',
            'Tula',
            "Tver'",
            "Tyumen'",
            "Ul'yanovsk",
            'Vladimir',
            'Volgograd',
            'Vologda',
            'Voronezh',
            "Yaroslavl'",
            'Adygeya',
            'Altay',
            'Bashkortostan',
            'Buryatiya',
            'Chechnya',
            'Chuvashiya',
            'Dagestan',
            'Ingushetiya',
            'Kabardino-Balkariya',
            'Kalmykiya',
            'Karachayevo-Cherkesiya',
            'Kareliya',
            'Khakasiya',
            'Komi',
            'Mariy-El',
            'Mordoviya',
            'Sakha',
            'North Ossetia',
            'Tatarstan',
            'Tyva',
            'Udmurtiya',
            'Aga Buryat',
            'Chukotka',
            'Evenk',
            'Khanty-Mansi',
            'Komi-Permyak',
            'Koryak',
            'Nenets',
            'Taymyr',
            "Ust'-Orda Buryat",
            'Yamalo-Nenets',
            'Altay',
            'Khabarovsk',
            'Krasnodar',
            'Krasnoyarsk',
            'Primorskiy',
            "Stavropol'",
            'Moscow',
            'St. Petersburg',
            'Yevrey',
        ],
    },
    {
        name_es: 'Samoa',
        name_en: 'Samoa',
        name_pt: 'Samoa',
        states: [
            "A'ana",
            'Aiga-i-le-Tai',
            'Atua',
            "Fa'asaleleaga",
            "Gaga'emauga",
            'Gagaifomauga',
            'Palauli',
            "Satupa'itea",
            'Tuamasaga',
            "Va'a-o-Fonoti",
            'Vaisigano',
        ],
    },
    {
        name_es: 'San Marino',
        name_en: 'San Marino',
        name_pt: 'San Marino',
        states: [
            'Acquaviva',
            'Borgo Maggiore',
            'Chiesanuova',
            'Domagnano',
            'Faetano',
            'Fiorentino',
            'Montegiardino',
            'San Marino Citta',
            'Serravalle',
        ],
    },
    {
        name_es: 'Senegal',
        name_en: 'Senegal',
        name_pt: 'Senegal',
        states: [
            'Dakar',
            'Diourbel',
            'Fatick',
            'Kaolack',
            'Kolda',
            'Louga',
            'Matam',
            'Saint-Louis',
            'Tambacounda',
            'Thies',
            'Ziguinchor',
        ],
    },
    {
        name_es: 'Seychelles',
        name_en: 'Seychelles',
        name_pt: 'Seicheles',
        states: [
            'Anse aux Pins',
            'Anse Boileau',
            'Anse Etoile',
            'Anse Louis',
            'Anse Royale',
            'Baie Lazare',
            'Baie Sainte Anne',
            'Beau Vallon',
            'Bel Air',
            'Bel Ombre',
            'Cascade',
            'Glacis',
            "Grand' Anse",
            "Grand' Anse",
            'La Digue',
            'La Riviere Anglaise',
            'Mont Buxton',
            'Mont Fleuri',
            'Plaisance',
            'Pointe La Rue',
            'Port Glaud',
            'Saint Louis',
            'Takamaka',
        ],
    },
    {
        name_es: 'Sierra Leona',
        name_en: 'Sierra Leone',
        name_pt: 'Serra Leoa',
        states: ['Sierra Leone'],
    },
    {
        name_es: 'Singapur',
        name_en: 'Singapore',
        name_pt: 'Cingapura',
        states: ['Singapore'],
    },
    {
        name_es: 'Somalia',
        name_en: 'Somalia',
        name_pt: 'Somália',
        states: [
            'Awdal',
            'Bakool',
            'Banaadir',
            'Bari',
            'Bay',
            'Galguduud',
            'Gedo',
            'Hiiraan',
            'Jubbada Dhexe',
            'Jubbada Hoose',
            'Mudug',
            'Nugaal',
            'Sanaag',
            'Shabeellaha Dhexe',
            'Shabeellaha Hoose',
            'Sool',
            'Togdheer',
            'Woqooyi Galbeed',
        ],
    },
    {
        name_es: 'Sri Lanka',
        name_en: 'Sri Lanka',
        name_pt: 'Sri Lanka',
        states: [
            'Central',
            'North Central',
            'North Eastern',
            'North Western',
            'Sabaragamuwa',
            'Southern',
            'Uva',
            'Western',
        ],
    },
    {
        name_es: 'Suazilandia',
        name_en: 'Swaziland',
        name_pt: 'Suazilândia',
        states: ['Hhohho', 'Lubombo', 'Manzini', 'Shiselweni'],
    },
    {
        name_es: 'Sudáfrica',
        name_en: 'South Africa',
        name_pt: 'África do Sul',
        states: [
            'Eastern Cape',
            'Free State',
            'Gauteng',
            'KwaZulu-Natal',
            'Limpopo',
            'Mpumalanga',
            'North-West',
            'Northern Cape',
            'Western Cape',
        ],
    },
    {
        name_es: 'Sudán',
        name_en: 'Sudan',
        name_pt: 'Sudão',
        states: [
            "A'ali an Nil",
            'Al Bahr al Ahmar',
            'Al Buhayrat',
            'Al Jazirah',
            'Al Khartum',
            'Al Qadarif',
            'Al Wahdah',
            'An Nil al Abyad',
            'An Nil al Azraq',
            'Ash Shamaliyah',
            'Bahr al Jabal',
            "Gharb al Istiwa'iyah",
            'Gharb Bahr al Ghazal',
            'Gharb Darfur',
            'Gharb Kurdufan',
            'Janub Darfur',
            'Janub Kurdufan',
            'Junqali',
            'Kassala',
            'Nahr an Nil',
            'Shamal Bahr al Ghazal',
            'Shamal Darfur',
            'Shamal Kurdufan',
            "Sharq al Istiwa'iyah",
            'Sinnar',
            'Warab',
        ],
    },
    {
        name_es: 'Suecia',
        name_en: 'Sweden',
        name_pt: 'Suécia',
        states: [
            'Blekinge',
            'Dalarnas',
            'Gavleborgs',
            'Gotlands',
            'Hallands',
            'Jamtlands',
            'Jonkopings',
            'Kalmar',
            'Kronobergs',
            'Norrbottens',
            'Orebro',
            'Ostergotlands',
            'Skane',
            'Sodermanlands',
            'Stockholms',
            'Uppsala',
            'Varmlands',
            'Vasterbottens',
            'Vasternorrlands',
            'Vastmanlands',
            'Vastra Gotalands',
        ],
    },
    {
        name_es: 'Suiza',
        name_en: 'Switzerland',
        name_pt: 'Suíça',
        states: [
            'Aargau',
            'Appenzell Ausser-Rhoden',
            'Appenzell Inner-Rhoden',
            'Basel-Landschaft',
            'Basel-Stadt',
            'Bern',
            'Fribourg',
            'Geneve',
            'Glarus',
            'Graubunden',
            'Jura',
            'Luzern',
            'Neuchatel',
            'Nidwalden',
            'Obwalden',
            'Sankt Gallen',
            'Schaffhausen',
            'Schwyz',
            'Solothurn',
            'Thurgau',
            'Ticino',
            'Uri',
            'Valais',
            'Vaud',
            'Zug',
            'Zurich',
        ],
    },
    {
        name_es: 'Surinam',
        name_en: 'Suriname',
        name_pt: 'Surinome',
        states: [
            'Brokopondo',
            'Commewijne',
            'Coronie',
            'Marowijne',
            'Nickerie',
            'Para',
            'Paramaribo',
            'Saramacca',
            'Sipaliwini',
            'Wanica',
        ],
    },
    {
        name_es: 'Tailandia',
        name_en: 'Thailand',
        name_pt: 'Tailândia',
        states: [
            'Amnat Charoen',
            'Ang Thong',
            'Buriram',
            'Chachoengsao',
            'Chai Nat',
            'Chaiyaphum',
            'Chanthaburi',
            'Chiang Mai',
            'Chiang Rai',
            'Chon Buri',
            'Chumphon',
            'Kalasin',
            'Kamphaeng Phet',
            'Kanchanaburi',
            'Khon Kaen',
            'Krabi',
            'Krung Thep Mahanakhon',
            'Lampang',
            'Lamphun',
            'Loei',
            'Lop Buri',
            'Mae Hong Son',
            'Maha Sarakham',
            'Mukdahan',
            'Nakhon Nayok',
            'Nakhon Pathom',
            'Nakhon Phanom',
            'Nakhon Ratchasima',
            'Nakhon Sawan',
            'Nakhon Si Thammarat',
            'Nan',
            'Narathiwat',
            'Nong Bua Lamphu',
            'Nong Khai',
            'Nonthaburi',
            'Pathum Thani',
            'Pattani',
            'Phangnga',
            'Phatthalung',
            'Phayao',
            'Phetchabun',
            'Phetchaburi',
            'Phichit',
            'Phitsanulok',
            'Phra Nakhon Si Ayutthaya',
            'Phrae',
            'Phuket',
            'Prachin Buri',
            'Prachuap Khiri Khan',
            'Ranong',
            'Ratchaburi',
            'Rayong',
            'Roi Et',
            'Sa Kaeo',
            'Sakon Nakhon',
            'Samut Prakan',
            'Samut Sakhon',
            'Samut Songkhram',
            'Sara Buri',
            'Satun',
            'Sing Buri',
            'Sisaket',
            'Songkhla',
            'Sukhothai',
            'Suphan Buri',
            'Surat Thani',
            'Surin',
            'Tak',
            'Trang',
            'Trat',
            'Ubon Ratchathani',
            'Udon Thani',
            'Uthai Thani',
            'Uttaradit',
            'Yala',
            'Yasothon',
        ],
    },
    {
        name_es: 'Togo',
        name_en: 'Togo',
        name_pt: 'Togo',
        states: ['Kara', 'Plateaux', 'Savanes', 'Centrale', 'Maritime'],
    },
    {
        name_es: 'Tonga',
        name_en: 'Tonga',
        name_pt: 'Tonga',
        states: ['Tonga'],
    },
    {
        name_es: 'Trinidad y Tobago',
        name_en: 'Trinidad and Tobago',
        name_pt: 'Trindade e Tobago',
        states: [
            'Couva',
            'Diego Martin',
            'Mayaro',
            'Penal',
            'Princes Town',
            'Sangre Grande',
            'San Juan',
            'Siparia',
            'Tunapuna',
            'Port-of-Spain',
            'San Fernando',
            'Arima',
            'Point Fortin',
            'Chaguanas',
            'Tobago',
        ],
    },
    {
        name_es: 'Turkmenistán',
        name_en: 'Turkmenistan',
        name_pt: 'Turcomenistão',
        states: [
            'Ahal Welayaty (Ashgabat)',
            'Balkan Welayaty (Balkanabat)',
            'Dashoguz Welayaty',
            'Lebap Welayaty (Turkmenabat)',
            'Mary Welayaty',
        ],
    },
    {
        name_es: 'Turquía',
        name_en: 'Turkey',
        name_pt: 'Turquia',
        states: [
            'Adana',
            'Adiyaman',
            'Afyonkarahisar',
            'Agri',
            'Aksaray',
            'Amasya',
            'Ankara',
            'Antalya',
            'Ardahan',
            'Artvin',
            'Aydin',
            'Balikesir',
            'Bartin',
            'Batman',
            'Bayburt',
            'Bilecik',
            'Bingol',
            'Bitlis',
            'Bolu',
            'Burdur',
            'Bursa',
            'Canakkale',
            'Cankiri',
            'Corum',
            'Denizli',
            'Diyarbakir',
            'Duzce',
            'Edirne',
            'Elazig',
            'Erzincan',
            'Erzurum',
            'Eskisehir',
            'Gaziantep',
            'Giresun',
            'Gumushane',
            'Hakkari',
            'Hatay',
            'Igdir',
            'Isparta',
            'Istanbul',
            'Izmir',
            'Kahramanmaras',
            'Karabuk',
            'Karaman',
            'Kars',
            'Kastamonu',
            'Kayseri',
            'Kilis',
            'Kirikkale',
            'Kirklareli',
            'Kirsehir',
            'Kocaeli',
            'Konya',
            'Kutahya',
            'Malatya',
            'Manisa',
            'Mardin',
            'Mersin',
            'Mugla',
            'Mus',
            'Nevsehir',
            'Nigde',
            'Ordu',
            'Osmaniye',
            'Rize',
            'Sakarya',
            'Samsun',
            'Sanliurfa',
            'Siirt',
            'Sinop',
            'Sirnak',
            'Sivas',
            'Tekirdag',
            'Tokat',
            'Trabzon',
            'Tunceli',
            'Usak',
            'Van',
            'Yalova',
            'Yozgat',
            'Zonguldak',
        ],
    },
    {
        name_es: 'Túnez',
        name_en: 'Tunisia',
        name_pt: 'Tunísia',
        states: [
            'Ariana (Aryanah)',
            'Beja (Bajah)',
            "Ben Arous (Bin 'Arus)",
            'Bizerte (Banzart)',
            'Gabes (Qabis)',
            'Gafsa (Qafsah)',
            'Jendouba (Jundubah)',
            'Kairouan (Al Qayrawan)',
            'Kasserine (Al Qasrayn)',
            'Kebili (Qibili)',
            'Kef (Al Kaf)',
            'Mahdia (Al Mahdiyah)',
            'Manouba (Manubah)',
            'Medenine (Madanin)',
            'Monastir (Al Munastir)',
            'Nabeul (Nabul)',
            'Sfax (Safaqis)',
            'Sidi Bou Zid (Sidi Bu Zayd)',
            'Siliana (Silyanah)',
            'Sousse (Susah)',
            'Tataouine (Tatawin)',
            'Tozeur (Tawzar)',
            'Tunis',
            'Zaghouan (Zaghwan)',
        ],
    },
    {
        name_es: 'Ucrania',
        name_en: 'Ukraine',
        name_pt: 'Ucrânia',
        states: [
            'Cherkasy',
            'Chernihiv',
            'Chernivtsi',
            'Crimea',
            "Dnipropetrovs'k",
            "Donets'k",
            "Ivano-Frankivs'k",
            'Kharkiv',
            'Kherson',
            "Khmel'nyts'kyy",
            'Kirovohrad',
            'Kiev',
            'Kyyiv',
            "Luhans'k",
            "L'viv",
            'Mykolayiv',
            'Odesa',
            'Poltava',
            'Rivne',
            "Sevastopol'",
            'Sumy',
            "Ternopil'",
            'Vinnytsya',
            "Volyn'",
            'Zakarpattya',
            'Zaporizhzhya',
            'Zhytomyr',
        ],
    },
    {
        name_es: 'Uganda',
        name_en: 'Uganda',
        name_pt: 'Uganda',
        states: [
            'Adjumani',
            'Apac',
            'Arua',
            'Bugiri',
            'Bundibugyo',
            'Bushenyi',
            'Busia',
            'Gulu',
            'Hoima',
            'Iganga',
            'Jinja',
            'Kabale',
            'Kabarole',
            'Kaberamaido',
            'Kalangala',
            'Kampala',
            'Kamuli',
            'Kamwenge',
            'Kanungu',
            'Kapchorwa',
            'Kasese',
            'Katakwi',
            'Kayunga',
            'Kibale',
            'Kiboga',
            'Kisoro',
            'Kitgum',
            'Kotido',
            'Kumi',
            'Kyenjojo',
            'Lira',
            'Luwero',
            'Masaka',
            'Masindi',
            'Mayuge',
            'Mbale',
            'Mbarara',
            'Moroto',
            'Moyo',
            'Mpigi',
            'Mubende',
            'Mukono',
            'Nakapiripirit',
            'Nakasongola',
            'Nebbi',
            'Ntungamo',
            'Pader',
            'Pallisa',
            'Rakai',
            'Rukungiri',
            'Sembabule',
            'Sironko',
            'Soroti',
            'Tororo',
            'Wakiso',
            'Yumbe',
        ],
    },
    {
        name_es: 'Uruguay',
        name_en: 'Uruguay',
        name_pt: 'Uruguai',
        states: [
            'Artigas',
            'Canelones',
            'Cerro Largo',
            'Colonia',
            'Durazno',
            'Flores',
            'Florida',
            'Lavalleja',
            'Maldonado',
            'Montevideo',
            'Paysandu',
            'Rio Negro',
            'Rivera',
            'Rocha',
            'Salto',
            'San Jose',
            'Soriano',
            'Tacuarembo',
            'Treinta y Tres',
        ],
    },
    {
        name_es: 'Uzbekistán',
        name_en: 'Uzbekistan',
        name_pt: 'Uzbequistão',
        states: [
            'Andijon Viloyati',
            'Buxoro Viloyati',
            "Farg'ona Viloyati",
            'Jizzax Viloyati',
            'Namangan Viloyati',
            'Navoiy Viloyati',
            'Qashqadaryo Viloyati',
            "Qaraqalpog'iston Respublikasi",
            'Samarqand Viloyati',
            'Sirdaryo Viloyati',
            'Surxondaryo Viloyati',
            'Toshkent Shahri',
            'Toshkent Viloyati',
            'Xorazm Viloyati',
        ],
    },
    {
        name_es: 'Vanuatu',
        name_en: 'Vanuatu',
        name_pt: 'Vanuatu',
        states: ['Malampa', 'Penama', 'Sanma', 'Shefa', 'Tafea', 'Torba'],
    },
    {
        name_es: 'Vietnam',
        name_en: 'Vietnam',
        name_pt: 'Vietnã',
        states: [
            'An Giang',
            'Bac Giang',
            'Bac Kan',
            'Bac Lieu',
            'Bac Ninh',
            'Ba Ria-Vung Tau',
            'Ben Tre',
            'Binh Dinh',
            'Binh Duong',
            'Binh Phuoc',
            'Binh Thuan',
            'Ca Mau',
            'Cao Bang',
            'Dac Lak',
            'Dac Nong',
            'Dien Bien',
            'Dong Nai',
            'Dong Thap',
            'Gia Lai',
            'Ha Giang',
            'Hai Duong',
            'Ha Nam',
            'Ha Tay',
            'Ha Tinh',
            'Hau Giang',
            'Hoa Binh',
            'Hung Yen',
            'Khanh Hoa',
            'Kien Giang',
            'Kon Tum',
            'Lai Chau',
            'Lam Dong',
            'Lang Son',
            'Lao Cai',
            'Long An',
            'Nam Dinh',
            'Nghe An',
            'Ninh Binh',
            'Ninh Thuan',
            'Phu Tho',
            'Phu Yen',
            'Quang Binh',
            'Quang Nam',
            'Quang Ngai',
            'Quang Ninh',
            'Quang Tri',
            'Soc Trang',
            'Son La',
            'Tay Ninh',
            'Thai Binh',
            'Thai Nguyen',
            'Thanh Hoa',
            'Thua Thien-Hue',
            'Tien Giang',
            'Tra Vinh',
            'Tuyen Quang',
            'Vinh Long',
            'Vinh Phuc',
            'Yen Bai',
            'Can Tho',
            'Da Nang',
            'Hai Phong',
            'Hanoi',
            'Ho Chi Minh',
        ],
    },
    {
        name_es: 'Yemen',
        name_en: 'Yemen',
        name_pt: 'Iêmen',
        states: [
            'Abyan',
            "'Adan",
            "Ad Dali'",
            "Al Bayda'",
            'Al Hudaydah',
            'Al Jawf',
            'Al Mahrah',
            'Al Mahwit',
            "'Amran",
            'Dhamar',
            'Hadramawt',
            'Hajjah',
            'Ibb',
            'Lahij',
            "Ma'rib",
            "Sa'dah",
            "San'a'",
            'Shabwah',
            "Ta'izz",
        ],
    },
    {
        name_es: 'Yibuti',
        name_en: 'Djibouti',
        name_pt: 'Djibuti',
        states: ['Ali Sabih', 'Dikhil', 'Djibouti', 'Obock', 'Tadjoura'],
    },
    {
        name_es: 'Zambia',
        name_en: 'Zambia',
        name_pt: 'Zâmbia',
        states: [
            'Central',
            'Copperbelt',
            'Eastern',
            'Luapula',
            'Lusaka',
            'Northern',
            'North-Western',
            'Southern',
            'Western',
        ],
    },
    {
        name_es: 'Zimbabue',
        name_en: 'Zimbabwe',
        name_pt: 'Zimbábue',
        states: [
            'Bulawayo',
            'Harare',
            'Manicaland',
            'Mashonaland Central',
            'Mashonaland East',
            'Mashonaland West',
            'Masvingo',
            'Matabeleland North',
            'Matabeleland South',
            'Midlands',
        ],
    },
]

export const yearMonths = [
    { value: '01', en: 'January', es: 'Enero' },
    { value: '02', en: 'February', es: 'Febrero' },
    { value: '03', en: 'March', es: 'Marzo' },
    { value: '04', en: 'April', es: 'Abril' },
    { value: '05', en: 'May', es: 'Mayo' },
    { value: '06', en: 'June', es: 'Junio' },
    { value: '07', en: 'July', es: 'Julio' },
    { value: '08', en: 'August', es: 'Agosto' },
    { value: '09', en: 'September', es: 'Septiembre' },
    { value: '10', en: 'October', es: 'Octubre' },
    { value: '11', en: 'November', es: 'Noviembre' },
    { value: '12', en: 'December', es: 'Diciembre' },
]

export const domains = [
    {
        value: 'marketing',
        text: 'welcome.marketing',
    },
    {
        value: 'ai',
        text: 'welcome.machineLearning',
    },
    { value: 'autotech', text: 'welcome.autotech' },
    {
        value: 'consumer',
        text: 'welcome.consumerGoods',
    },
    { value: 'consulting', text: 'welcome.consulting' },
    {
        value: 'datascience',
        text: 'welcome.dataScience',
    },
    { value: 'design', text: 'welcome.design' },
    {
        value: 'retail',
        text: 'welcome.retail',
    },
    { value: 'education', text: 'welcome.education' },
    { value: 'energy', text: 'welcome.energy' },
    {
        value: 'software',
        text: 'welcome.software',
    },
    {
        value: 'enterntaimentmedia',
        text: 'welcome.entertainment',
    },
    {
        value: 'eventmanagement',
        text: 'welcome.eventManagement',
    },
    {
        value: 'financial',
        text: 'welcome.financial',
    },
    { value: 'gamingvrar', text: 'welcome.gaming' },
    { value: 'government', text: 'welcome.government' },
    { value: 'hardwareiot', text: 'welcome.hardware' },
    { value: 'healthcare', text: 'welcome.healthcare' },
    {
        value: 'hrrecruitment',
        text: 'welcome.recruitment',
    },
    {
        value: 'legalservices',
        text: 'welcome.legalServices',
    },
    { value: 'security', text: 'welcome.security' },
    { value: 'sports', text: 'welcome.sports' },
    {
        value: 'sustainability',
        text: 'welcome.sustainability',
    },
    {
        value: 'telecommunications',
        text: 'welcome.telecommunications',
    },
    { value: 'travel', text: 'welcome.travel' },
    { value: 'web3', text: 'welcome.web3' },
]

export const gaColors = {
    // Neutral
    neutral100: '#ffffff',
    neutral200: '#f2f2f2',
    neutral300: '#e4e4e4',
    neutral400: '#c6c6c8',
    neutral500: '#a9a9ac',
    neutral600: '#8c8c90',
    neutral700: '#707074',
    neutral800: '#545458',
    neutral900: '#39393c',
    neutral1000: '#1e1e20',

    // Primary
    primary100: '#f2f2ff',
    primary200: '#DEDEFB',
    primary300: '#CCCBF8',
    primary400: '#A5A4E8',
    primary500: '#8281D7',
    primary600: '#6361C7',
    primary700: '#4745B7',
    primary800: '#2F2D8B',
    primary900: '#1B1A5F',
    primary1000: '#0C0B33',

    // Secondary
    secondary100: '#E8FEFF',
    secondary200: '#BBF2F4',
    secondary300: '#8EE6E9',
    secondary400: '#54CCD0',
    secondary500: '#25B2B7',
    secondary600: '#00999E',
    secondary700: '#067A7E',
    secondary800: '#095C5E',
    secondary900: '#083D3E',
    secondary1000: '#061E1F',

    // Tertiary
    tertiary100: '#FFF2F6',
    tertiary200: '#FFD4E1',
    tertiary300: '#FFB6CD',
    tertiary400: '#F486A7',
    tertiary500: '#E85A84',
    tertiary600: '#DC3164',
    tertiary700: '#B22851',
    tertiary800: '#891E3E',
    tertiary900: '#5F152B',
    tertiary1000: '#360C18',

    // Success
    success100: '#D9FADF',
    success200: '#80CA8D',
    success300: '#3D9A4E',
    success400: '#186927',
    success500: '#04380E',

    // Alert
    alert100: '#FFDEDF',
    alert200: '#EE888C',
    alert300: '#DC3D43',
    alert400: '#8D1A1E',
    alert500: '#3D0608',

    // Warning
    warning100: '#FFF6CA',
    warning200: '#FBE263',
    warning300: '#F7CE00',
    warning400: '#B99B08',
    warning500: '#7A680B',

    // Info
    info100: '#E4F3FF',
    info200: '#6FB8F8',
    info300: '#0081F1',
    info400: '#07549A',
    info500: '#062542',

    // Skeletons
    skeletonLightBG: '#e0e0e0',
    skeletonDarkBG: '#c6c6c6d1',

    // Grey
    grey600: '#757575',
}

// --------------------------------------------------
// Font Family
// --------------------------------------------------
export const gaFonts = {
    // Neutral
    base: 'Poppins',
    custom: 'Ubuntu',
    simple: 'Work Sans',
    default: 'Poppins',
}
