import { t } from 'i18next'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from './gatacaLicensesModal.module.scss'
import cx from 'classnames'
import { licensesSelectors } from '../../../../../common/licenses'
import {
    gatacaLicensesList,
    licensesInfoToggles,
} from '../../../../../common/licenses/views/changeSubscription/featuresModal/licensesTableData'
import CardListSkeleton from '../../../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
import SwitchButton from '../../../../../../Components/atoms/buttons/SwitchButton/SwicthButton'
import {
    gatacaOnboardingSwitchPeriodButtonOptions,
    onboardingSwitchPeriodButtonOptions,
} from '../../../../../common/licenses/licenses.constants'
import LightButton from '../../../../../../Components/atoms/buttons/LightButton/LightButton'
import { ReactComponent as ChevronUp } from '../../../../../../assets/iconsLibrary/simple/chevronUp/chevronUpIcon.svg'
import { ReactComponent as ChevronDown } from '../../../../../../assets/iconsLibrary/simple/chevronDown/chevronDownIcon.svg'
import { ActionStatusTypes } from '../../../../../../assets/globalConstants'
import RequestSubscriptionModal from '../../../../../../Components/molecules/modals/RequestSubscription/RequestSubscriptionModal'
import {
    requestSubscriptionAction,
    resetRequestSubscriptionStatusAction,
} from '../../../../../common/licenses/licensesActions'
import { createOrgToRequestSubAction } from '../../../../../common/organization/organizationActions'
import { DataRequestModel } from '../../../../../../interfaces/interfaces'
import {
    ButtonSizes,
    noPaymentMethod,
    onPremise,
} from '../../../../../../data/globalVar'
import { emptyOrg } from '../../../../../common/organization/organizationReducers'
import { orgServicesEndpoints } from '../../../../../common/Welcome/welcomeConstants'
import {
    creatingOrgLoading,
    getOrg,
} from '../../../../../common/organization/organizationSelectors'
import { setSession } from '../../../../../common/session/sessionActions'
import {
    getIfProductHasTrialDays,
    getTrialDaysNumber,
    getSiwtchIntervalPeriodOptions,
    getIfTierHasInterval,
    getGatacaTiersLicenses,
    isEnterpriseTier,
} from '../../../../../../utils/licensesUtil'
import GatacaOnboardingLicenseCard from '../../../../../../Components/organisms/Cards/OnboardingLicenseCard/gatacaOnboardingLicenseCard/GatacaOnboardingLicenseCard'
import GatacaLicensesTable from '../../../../../common/licenses/views/changeSubscription/featuresModal/licensesTable/gatacaLicensesTable/GatacaLicensesTable'
import GatacaLicensesTableMobile from '../../../../../common/licenses/views/changeSubscription/featuresModal/licensesTableMobile/gatacaLicensesTableMobile/GatacaLicensesTableMobile'

type ILicensesModalProps = {
    show: boolean
    switchPeriodValue: string
    selectPeriod: (x) => void
    setPeriodChecked: (x) => void
    navigateChangeSubscription: () => void
    selectStarter: (x) => void
    selectLicense: (x) => void
    setShowSubcriptionModal: (x) => void
}

const GatacaLicensesModal: React.FC<ILicensesModalProps> = (props) => {
    const {
        show,
        switchPeriodValue,
        selectPeriod,
        selectStarter,
        selectLicense,
        setPeriodChecked,
        navigateChangeSubscription,
        setShowSubcriptionModal,
    } = props

    const dispatch = useDispatch()

    const newLicensePeriod = useSelector(licensesSelectors.getNewLicensePeriod)
    const tiers = useSelector(licensesSelectors.getProviderTiersAndFeatures)
    const selectedPeriod = useSelector(licensesSelectors.getSelectedPeriod)
    const currentOrg = useSelector(getOrg)
    const tierLicenses = getGatacaTiersLicenses(tiers, gatacaLicensesList)

    const requestSubStatus = useSelector(
        licensesSelectors.getRequestSubscritptionStatus
    )

    const [showAllFeatures, setShowAllFeatures] = React.useState(false)
    const [selectedLicense, setSelectedLicense] = React.useState(0)

    const [showRequestSubModal, setShowRequestSubModal] = React.useState(false)
    const [showSuccessModal, setShowSuccessModal] = React.useState(false)
    const [loadingReq, setLoadingReq] = React.useState(false)

    const [selectedLicenseForReq, setSelectedLicenseForReq] = React.useState('')

    const getExtraTranslationParamsTrial = (tier) => {
        return !!getIfProductHasTrialDays(tier, selectedPeriod)
            ? {
                  days: getTrialDaysNumber(tier, selectedPeriod)?.toString(),
              }
            : undefined
    }

    useEffect(() => {}, [selectedPeriod])

    useEffect(() => {
        if (requestSubStatus === ActionStatusTypes?.success) {
            setShowRequestSubModal(true)
            setShowSuccessModal(true)
            setLoadingReq(false)
        }
        if (requestSubStatus === ActionStatusTypes?.loading) {
            setShowSuccessModal(false)
            setLoadingReq(true)
        }
        if (requestSubStatus === ActionStatusTypes?.failed) {
            setShowRequestSubModal(false)
            setShowSuccessModal(false)
            setLoadingReq(false)
        }
    }, [requestSubStatus])

    function requestSubscription(data: DataRequestModel) {
        const org = {
            providerId: '',
            orgInfo: {
                ...emptyOrg?.orgInfo,
                name: data?.orgId,
                services: orgServicesEndpoints,
                domainName: data.domainName,
            },
            priceId: data?.priceId,
        }
        const { priceId, orgId, message } = data
        setLoadingReq(true)
        !onPremise &&
            (!currentOrg?.id
                ? dispatch(createOrgToRequestSubAction(org, data))
                : dispatch(requestSubscriptionAction(priceId, orgId, message)))
    }

    const resetRequestSubscriptionStatus = () =>
        dispatch(resetRequestSubscriptionStatusAction())

    const getFirstButtonProps = (tier, index: number) => {
        return {
            label: !!getIfProductHasTrialDays(tier, selectedPeriod)
                ? 'subscription.startTrial'
                : isEnterpriseTier(tier)
                ? 'subscription.contactUs'
                : 'subscription.startNow',
            function: () => {
                getIfProductHasTrialDays(tier, selectedPeriod)
                    ? selectStarter(tier)
                    : !noPaymentMethod
                    ? selectLicense(tier)
                    : (setShowRequestSubModal(true),
                      setSelectedLicenseForReq(tier))
            },
        }
    }

    const getSwitchPeriodOptions = getSiwtchIntervalPeriodOptions(
        onboardingSwitchPeriodButtonOptions,
        tiers
    )

    return (
        <div
            className={`${styles.backgroundLayer} ${
                show ? styles.visibleModal : styles.hiddenModal
            }`}
        >
            <div className={`${styles.modal} `}>
                <div className={styles.modal__header}>
                    <div className={styles.modal__header__title}>
                        <h3 className={cx('heading4 neutral1000')}>
                            {t('subscription.subscriptionPlans')}
                        </h3>

                        <p
                            className={cx(
                                'bodyRegularLG neutral1000 marginTop12'
                            )}
                        >
                            {t('subscription.chooseTheRightPlan')}
                        </p>
                    </div>
                </div>

                <div className={styles.tableContainer}>
                    {!!(getSwitchPeriodOptions?.length > 1) ? (
                        <SwitchButton
                            className={styles.onboardingSwicthButton}
                            checkedValue={switchPeriodValue || ''}
                            options={gatacaOnboardingSwitchPeriodButtonOptions}
                            onChangeSwitchSelect={selectPeriod}
                        />
                    ) : null}
                    {tierLicenses?.length ? (
                        <>
                            <div
                                className={cx(
                                    styles.licenses,
                                    switchPeriodValue === 'month' &&
                                        styles.marginBottomLarge
                                )}
                            >
                                {tierLicenses?.map((tier, index) => {
                                    return getIfTierHasInterval(
                                        tier,
                                        getSwitchPeriodOptions,
                                        switchPeriodValue
                                    ) || !tier?.prices?.length ? (
                                        <GatacaOnboardingLicenseCard
                                            key={'onboardingCard__' + index}
                                            newLicensePeriod={
                                                newLicensePeriod || ''
                                            }
                                            // TODO. Check prod id
                                            isEnterpriseTier={isEnterpriseTier(
                                                tier
                                            )}
                                            license={tier}
                                            firstButton={getFirstButtonProps(
                                                tier,
                                                index
                                            )}
                                            buttonExtraTranslationParams={getExtraTranslationParamsTrial(
                                                tier
                                            )}
                                            showAllDetails
                                            period={
                                                getSwitchPeriodOptions?.length >
                                                1
                                                    ? switchPeriodValue
                                                    : getSwitchPeriodOptions[0]
                                                          ?.value || 'month'
                                            }
                                            isNewLicense={false}
                                        />
                                    ) : null
                                })}
                            </div>
                        </>
                    ) : (
                        <div className={styles.licenses}>
                            <CardListSkeleton cardsNumber={5} />
                        </div>
                    )}
                    {showAllFeatures ? (
                        <div className={styles.tablesContainer}>
                            <GatacaLicensesTable
                                tiers={tierLicenses || []}
                                switchPeriodValue={switchPeriodValue}
                                infoToggles={licensesInfoToggles}
                                setPeriodChecked={setPeriodChecked}
                            />
                            <GatacaLicensesTableMobile
                                tiers={tierLicenses || []}
                                licenseIndex={selectedLicense}
                                license={
                                    tierLicenses &&
                                    tierLicenses[selectedLicense]
                                }
                                switchPeriodValue={
                                    getSwitchPeriodOptions?.length > 1
                                        ? switchPeriodValue
                                        : getSwitchPeriodOptions[0]?.value ||
                                          'month'
                                }
                                infoToggles={licensesInfoToggles}
                                selectLicense={setSelectedLicense}
                                setPeriodChecked={setPeriodChecked}
                            />
                        </div>
                    ) : null}

                    <LightButton
                        className={styles.showTableButton}
                        text={
                            showAllFeatures
                                ? 'subscription.hideAllFeatures'
                                : 'subscription.seeAllFeatures'
                        }
                        LeftSvgIcon={showAllFeatures ? ChevronUp : ChevronDown}
                        disabled={false}
                        functionality={() => {
                            setShowAllFeatures(!showAllFeatures),
                                setSelectedLicense(0)
                        }}
                        size={ButtonSizes.MD}
                    />
                </div>
            </div>
            {showRequestSubModal || (loadingReq && noPaymentMethod) ? (
                <RequestSubscriptionModal
                    licenses={tiers}
                    show={showRequestSubModal}
                    hideModal={() => (
                        setShowRequestSubModal(false),
                        resetRequestSubscriptionStatus(),
                        setLoadingReq(false)
                    )}
                    hideLicensesModal={() => (
                        setShowSubcriptionModal(false), dispatch(setSession())
                    )}
                    selectedLicense={selectedLicenseForReq}
                    selectedPeriodChecked={switchPeriodValue}
                    setPeriodChecked={setPeriodChecked}
                    submitData={(x?) => requestSubscription(x)}
                    loading={loadingReq && !showSuccessModal}
                />
            ) : null}
        </div>
    )
}

export default GatacaLicensesModal
